import { object, string, boolean, number } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { isDateBigger } from '~/utils/date';

const validationSchema = object().shape({
  nameId: number().nullable().required('Field is required'),
  groupId: string().max(100, 'Max length - 100 symbols'),
  policyNumber: string().max(100, 'Max length - 100 symbols'),
  type: string().required('Field is required'),
  plan: string().nullable(),
  planType: string().nullable(),
  expiresAt: string().required('Field is required'),
  isAuthorizationRequired: boolean(),
  authorizationStartDate: string()
    .nullable()
    .when('isAuthorizationRequired', {
      is: true,
      then: string().nullable().required('Field is required'),
    }),
  authorizationEndDate: string()
    .nullable()
    .when('isAuthorizationRequired', {
      is: true,
      then: string()
        .nullable()
        .required('Field is required')
        .test({
          name: 'autorizationDateCompare',
          message: '`Authorization Valid Through` should be bigger then `Authorization From`',
          test: (value, context) => {
            const { authorizationStartDate } = context.parent;

            return !authorizationStartDate || !value || isDateBigger(authorizationStartDate, value);
          },
        }),
    }),
});

export default yupResolver(validationSchema);
