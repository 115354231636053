import { action } from 'easy-peasy';
import icd10CodeTypeOrderings from '~/constants/icd10CodeTypeOrderings';
import { IActionUpdateCarePlanIcd10Code } from '../types';

const updateCarePlanIcd10Code: IActionUpdateCarePlanIcd10Code = action((state, payload) => {
  state.items = state.items
    .map(item => {
      if (item.id !== payload.id) return item;
      return {
        ...item,
        ...payload,
      };
    })
    .sort(
      (a, b) =>
        icd10CodeTypeOrderings.indexOf(a.typeId) - icd10CodeTypeOrderings.indexOf(b.typeId) ||
        a.icd10CodeName.localeCompare(b.icd10CodeName),
    );
});

export default updateCarePlanIcd10Code;
