import { useEffect, useState, ReactElement, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';

import api from '~/services/api';
import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import { initialValues, validate } from './form';
import Modal from '~/ui/components/common/Modal';
import Button from '~/ui/components/common/Button';
import SelectVirtualized from '~/ui/components/inputs/SelectVirtualized';

import { IFormValues } from './types';
import styles from './ActivatePatient.module.scss';
import { deviceTypeOptions } from './constans';
import { IDeviceSelector } from '~/services/api/devices/types';

interface IProps {
  patientId: number;
  isProcessing: boolean;
  onSubmit: (deviceId: number) => void;
  onClose: () => void;
}

const ActivatePatient = ({ patientId, isProcessing, onSubmit, onClose }: IProps): ReactElement => {
  const [devices, setDevices] = useState<IDeviceSelector[]>([]);

  const showError = useStoreActions(actions => actions.snackbar.showError);

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<IFormValues>({
    resolver: validate,
    defaultValues: initialValues,
  });

  const typeId = watch('typeId');

  const deviceOptions = useMemo(
    () =>
      devices
        .filter(item => typeId === item.typeId)
        .map(item => ({
          value: item.id,
          label: item.name,
        })),
    [devices.length, typeId],
  );

  const onSubmitForm = (values: IFormValues) => {
    onSubmit(values.deviceId);
  };

  const onMount = async () => {
    try {
      const selectors = await api.patients.getSelectorsById(patientId).then(res => res.data);
      setDevices(selectors.devices);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  return (
    <Modal open title="Activate Patient" className={styles.modal} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className={styles.title}>Device</div>
            <SelectVirtualized
              name="typeId"
              control={control}
              errors={errors}
              options={deviceTypeOptions}
              label="Device Type"
            />
          </Grid>
          <Grid item xs={12}>
            <SelectVirtualized
              name="deviceId"
              control={control}
              errors={errors}
              options={deviceOptions}
              label="Device"
            />
          </Grid>
        </Grid>
        <div className={styles.btnWrapper}>
          <Button
            type="submit"
            size="large"
            variant="contained"
            color="primary"
            isLoading={isProcessing}
            disabled={isProcessing}
          >
            Activate
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ActivatePatient;
