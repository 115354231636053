import { useState, useEffect, ReactElement } from 'react';
import Button from '@material-ui/core/Button';

import api from '~/services/api';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';

import {
  getCounterDurationSec,
  resetCounterDuration,
} from '~/ui/components/reusable/ActivityCounter/helper';
import createFormInitialValues from './form/create/initialValues';
import getEditFormInitialValues from './form/edit/getInitialValues';
import Modal from '~/ui/components/common/Modal';
import Loader from '~/ui/components/common/Loader';
import NoteHeader from '~/ui/components/reusable/Notes/Header';
import NoteForm from '~/ui/components/reusable/Notes/NoteForm';
import ActivityCounter from '~/ui/components/reusable/ActivityCounter';
import NotesList from './NotesList';

import { IPatientInfo } from '~/services/api/patients/types';
import { View } from './types';
import { IFormValues } from '~/ui/components/reusable/Notes/NoteForm/types';
import styles from './NotesModal.module.scss';
import { ActivityLogMode, NoteType } from '~/services/api/enums';
import { INote } from '~/services/api/notes/types';
import { IActivityLog } from '~/services/api/activityLogs/types';
import { UserRole } from '~/types';
import { isoToFormat } from '~/utils/date';

interface IProps {
  patientId: number;
  onClose: () => void;
}

const NotesModal = ({ patientId, onClose }: IProps): ReactElement => {
  const [isLoading, setLoading] = useState(true);
  const [isAdding, setIsAdding] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [view, setView] = useState(View.NotesList);
  const [patient, setPatient] = useState<IPatientInfo>({} as IPatientInfo);
  const [editNote, setEditNote] = useState<INote>(null);

  const { currentUser } = useStoreState(store => store.auth);

  const { showNotify, showError } = useStoreActions(actions => actions.snackbar);

  const handleAddNote = async (formValues: IFormValues) => {
    const { showReminder, reminderDate, ...restFormValues } = formValues;

    const payload = {
      ...restFormValues,
      reminderDate: showReminder ? isoToFormat(reminderDate, 'y-MM-dd') : null,
      typeId: NoteType.General,
    } as INote;

    try {
      setIsAdding(true);
      await api.notes.create(patientId, payload);
      setView(View.NotesList);
      showNotify('Note successfully created');
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsAdding(false);
    }
  };

  const handleUpdateNote = async (formValues: IFormValues) => {
    const { showReminder, reminderDate, ...restFormValues } = formValues;

    const payload = {
      ...editNote,
      ...restFormValues,
      reminderDate: showReminder ? isoToFormat(reminderDate, 'y-MM-dd') : null,
    } as INote;

    try {
      setIsUpdating(true);
      await api.notes.update(payload);
      setView(View.NotesList);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsUpdating(false);
    }
  };

  const sendActivityLog = async () => {
    const secondsSpent = getCounterDurationSec();

    const data = {
      mode: ActivityLogMode.Online,
      dateTime: new Date().toISOString(),
      secondsSpent,
      note: '',
    };

    try {
      await api.activityLogs.create(patientId, data as IActivityLog);
      resetCounterDuration();
      setView(View.NotesList);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const editNoteHandler = (note: INote) => {
    setEditNote(note);
    setView(View.EditNote);
  };

  const handleCloseEditNote = () => {
    setEditNote(null);
    setView(View.NotesList);
  };

  const handleClose = async () => {
    if (currentUser.roleId !== UserRole.SystemAdmin) {
      await sendActivityLog();
    }
    onClose();
  };

  const onMount = async () => {
    try {
      const patientData = await api.patients.getInfo(patientId).then(res => res.data);

      setPatient(patientData);
      setLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  return (
    <Modal
      open
      closeOnlyByCross
      title="Notes"
      maxWidth="md"
      className={styles.modal}
      onClose={handleClose}
    >
      <div className={styles.container}>
        {isLoading ? <Loader /> : <NoteHeader patient={patient} />}
        {view === View.NotesList && (
          <>
            <div className={styles.btnWrapper}>
              <Button variant="contained" color="primary" onClick={() => setView(View.AddNote)}>
                ADD NOTES
              </Button>
            </div>
            <NotesList patientId={patientId} onEdit={editNoteHandler} />
          </>
        )}
        {view === View.AddNote && (
          <NoteForm
            showBackBtn
            initialValues={createFormInitialValues}
            isProcessing={isAdding}
            onSubmit={handleAddNote}
            onClose={() => setView(View.NotesList)}
          />
        )}
        {view === View.EditNote && editNote && (
          <NoteForm
            mode="edit"
            showBackBtn
            initialValues={getEditFormInitialValues(editNote)}
            isProcessing={isUpdating}
            onSubmit={handleUpdateNote}
            onClose={handleCloseEditNote}
          />
        )}
        {currentUser.roleId !== UserRole.SystemAdmin && (
          <ActivityCounter patient={patient} className={styles.counterContainer} />
        )}
      </div>
    </Modal>
  );
};

export default NotesModal;
