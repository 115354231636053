import { action } from 'easy-peasy';
import { IActionUpdateInsurance } from '../types';

const updateInsurance: IActionUpdateInsurance = action((state, payload) => {
  state.items = state.items
    .map(item => {
      if (item.id !== payload.id) return item;
      return {
        ...item,
        ...payload,
      };
    })
    .sort((a, b) => new Date(b.expiresAt).getTime() - new Date(a.expiresAt).getTime());
});

export default updateInsurance;
