import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';

import validate from './form/validate';
import Button from '~/ui/components/common/Button';
import Textarea from '~/ui/components/inputs/Textarea';

import { IFormValues } from './types';
import styles from './MedicationForm.module.scss';

interface IProps {
  initialValues: IFormValues;
  isProcessing: boolean;
  onSubmit: (data: IFormValues) => void;
}

const MedicationForm = ({ initialValues, isProcessing, onSubmit }: IProps): ReactElement => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormValues>({
    resolver: validate,
    defaultValues: initialValues,
  });

  return (
    <form className={styles.formMedication} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Textarea name="content" register={register} errors={errors} label="Comment *" />
        </Grid>
      </Grid>
      <div className={styles.btnWrapper}>
        <Button
          type="submit"
          size="large"
          variant="contained"
          color="primary"
          isLoading={isProcessing}
          disabled={isProcessing}
        >
          Save
        </Button>
      </div>
    </form>
  );
};

export default MedicationForm;
