import { action } from 'easy-peasy';
import initStore from '../initStore';
import { IActionReset } from '../types';

const reset: IActionReset = action(state => {
  const { personalData, info, overviewFilters } = initStore;

  state.personalData = personalData;
  state.info = info;
  state.overviewFilters = overviewFilters;
});

export default reset;
