import onGetMedications from './onGetMedications';
import onGetMoreMedications from './onGetMoreMedications';
import onCreateMedication from './onCreateMedication';
import onUpdateMedication from './onUpdateMedication';
import onDeleteMedication from './onDeleteMedication';

export default {
  onGetMedications,
  onGetMoreMedications,
  onCreateMedication,
  onUpdateMedication,
  onDeleteMedication,
};
