import { useEffect, useState, ReactElement } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import api from '~/services/api';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import Loader from '~/ui/components/common/Loader';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import List from './List';

import { IProgramList } from '~/services/api/programs/types';
import { UserRole } from '~/types';

const Program = (): ReactElement => {
  const [isLoading, setIsLoading] = useState(true);
  const [deleteProgram, setDeleteProgram] = useState<IProgramList>(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const userRole = useStoreState(state => state.auth.currentUser?.roleId);
  const { items: programs, pagination } = useStoreState(state => state.programs);

  const { showNotify, showError } = useStoreActions(actions => actions.snackbar);
  const { onGetPrograms, onGetMorePrograms } = useStoreActions(actions => actions.programs);

  const permissions = {
    canViewPage: userRole !== UserRole.HealthManager,
    canRemoveProgram: userRole === UserRole.SystemAdmin,
  };

  const getMorePrograms = async () => {
    try {
      await onGetMorePrograms({});
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const getPrograms = async () => {
    try {
      await onGetPrograms({});
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsLoading(false);
    }
  };

  const removeProgram = async () => {
    try {
      setIsDeleting(true);
      await api.programs.remove(deleteProgram.id);
      await getPrograms(); // refresh list after deleting
      setDeleteProgram(null);
      showNotify('Program removed');
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsDeleting(false);
    }
  };

  useEffect(() => {
    getPrograms();
  }, []);

  if (!permissions.canViewPage) return null;

  if (isLoading) return <Loader />;

  return (
    <>
      <InfiniteScroll
        dataLength={programs.length}
        next={getMorePrograms}
        hasMore={pagination.hasMore}
        loader={<Loader />}
      >
        <List programs={programs} permissions={permissions} onRemoveProgram={setDeleteProgram} />
      </InfiniteScroll>
      {deleteProgram && (
        <ConfirmModal
          title="Remove"
          description={`Are you sure you want to remove program ${deleteProgram.name}?`}
          isLoading={isDeleting}
          onClose={() => setDeleteProgram(null)}
          onConfirm={removeProgram}
        />
      )}
    </>
  );
};

export default Program;
