import { ReactElement } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { IPatientDetails } from '~/services/api/patients/types';
import styles from './ActivityDetails.module.scss';

interface IProps {
  patient: IPatientDetails;
}

const ActivityDetails = ({ patient }: IProps): ReactElement => (
  <div className={styles.container}>
    <div className={styles.title}>Activity Details</div>
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Patient id</TableCell>
            <TableCell>Name</TableCell>
            <TableCell width={200} align="center">
              Activity Minutes (Offline)
            </TableCell>
            <TableCell width={200} align="center">
              Activity Minutes (Online)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{patient.subjectId}</TableCell>
            <TableCell>{patient.fullName}</TableCell>
            <TableCell align="center">{patient.activityMinutesOfflineCount}</TableCell>
            <TableCell align="center">{patient.activityMinutesOnlineCount}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </div>
);

export default ActivityDetails;
