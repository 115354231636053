import { Day, DayPart } from '~/types';

export interface IDayPartReading {
  day: Day;
  dayPart: DayPart;
  batteryLevel: number;
  signalLevel: number;
  values: number[];
  additionalValues: number[];
}

export const daysList = [
  Day.Monday,
  Day.Tuesday,
  Day.Wednesday,
  Day.Thursday,
  Day.Friday,
  Day.Saturday,
  Day.Sunday,
];

export const dayPartsList = [DayPart.Morning, DayPart.Noon, DayPart.Evening, DayPart.Night];

export interface IDay {
  day: Day;
  values: number[];
  value: string;
}

export interface IDayPart {
  dayPart: DayPart;
  days: IDay[];
}

export interface IAverageData {
  dayParts: IDayPart[];
}
