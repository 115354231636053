const relationShipOptions = [
  {
    value: 'Father',
    label: 'Father',
  },
  {
    value: 'Mother',
    label: 'Mother',
  },
  {
    value: 'Brother',
    label: 'Brother',
  },
  {
    value: 'Sister',
    label: 'Sister',
  },
  {
    value: 'Friend',
    label: 'Friend',
  },
  {
    value: 'FamilyMember',
    label: 'Family Member',
  },
  {
    value: 'Other',
    label: 'Other',
  },
  {
    value: 'Daughter',
    label: 'Daughter',
  },
  {
    value: 'Son',
    label: 'Son',
  },
  {
    value: 'Spouse',
    label: 'Spouse',
  },
];

export default relationShipOptions;
