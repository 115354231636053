import { action } from 'easy-peasy';
import { IActionSetActivityLogsByPatientId } from '../types';

const setActivityLogsByPatientId: IActionSetActivityLogsByPatientId = action((state, payload) => {
  const hasMore = state.paginationByPatientId.itemsPerPage === payload.length;

  state.itemsByPatientId = payload;
  state.paginationByPatientId.page += 1;
  state.paginationByPatientId.hasMore = hasMore;
});

export default setActivityLogsByPatientId;
