import { ReactElement } from 'react';
import Paper from '@material-ui/core/Paper';

import { useStoreState } from '~/store/hooks';

import styles from './SignIn.module.scss';
import VerifyCode from './VerifyCode';
import EmailLogin from './EmailLogin';

const SignIn = (): ReactElement => {
  const isMfaRequired = useStoreState(store => store.auth.isMfaRequired);

  return (
    <Paper elevation={3} className={styles.signIn}>
      {isMfaRequired ? <VerifyCode /> : <EmailLogin />}
    </Paper>
  );
};

export default SignIn;
