import { ReactElement } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useStoreState } from '~/store/hooks';

/**
 * Render public route
 * Only unlogged users can access to public route
 * Otherwise - redirect user to another allowed page route
 */
const PublicRoute = (props: any): ReactElement => {
  const { authorized, authChecked } = useStoreState(state => state.auth);

  if (!authChecked) return null;

  return authorized ? <Redirect to="/dashboard" /> : <Route {...props} />;
};

export default PublicRoute;
