import { ReactElement } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';

import Input from '~/ui/components/inputs/Input';
import AutoSubmit from '~/ui/components/inputs/AutoSubmit';
import { IStatusPatientsFilters } from '~/services/api/patients/types';

interface IProps {
  onSubmit: (filters: IStatusPatientsFilters) => void;
}

const initialValues = {
  fullName: '',
  subjectId: '',
} as IStatusPatientsFilters;

const Header = ({ onSubmit }: IProps): ReactElement => {
  const formMethods = useForm<IStatusPatientsFilters>({
    defaultValues: initialValues,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const handleOnSubmit = (values: IStatusPatientsFilters) => {
    onSubmit(values);
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div>Name</div>
            <Input name="fullName" register={register} errors={errors} />
          </Grid>
          <Grid item xs={6}>
            <div>Patient ID</div>
            <Input name="SubjectId" register={register} errors={errors} />
          </Grid>
          <AutoSubmit debounce={1000} initialValues={initialValues} onSubmit={handleOnSubmit} />
        </Grid>
      </form>
    </FormProvider>
  );
};

export default Header;
