import { RuleType, RuleCondition } from '~/services/api/enums';

export const typeOptions = [
  {
    value: RuleType.Systolic,
    label: 'Systolic',
  },
  {
    value: RuleType.Diastolic,
    label: 'Diastolic',
  },
  {
    value: RuleType.HeartRate,
    label: 'Heart Rate',
  },
  {
    value: RuleType.Weight,
    label: 'Weight',
  },
  {
    value: RuleType.POSPO2,
    label: 'PO SPO2',
  },
  {
    value: RuleType.POPulserate,
    label: 'PO Pulserate',
  },
  {
    value: RuleType.BloodGlucose,
    label: 'Blood Glucose',
  },
];

export const conditionOptions = [
  {
    value: RuleCondition.Below,
    label: 'Below',
  },
  {
    value: RuleCondition.Above,
    label: 'Above',
  },
];

export const weightConditionOptions = [
  {
    value: RuleCondition.PreviousGain,
    label: 'Previous Gain',
  },
  {
    value: RuleCondition.PreviousLoss,
    label: 'Previous Loss',
  },
  {
    value: RuleCondition.DailyGain,
    label: 'Daily Gain',
  },
  {
    value: RuleCondition.DailyLoss,
    label: 'Daily Loss',
  },
  {
    value: RuleCondition.WeeklyGain,
    label: 'Weekly Gain',
  },
  {
    value: RuleCondition.WeeklyLoss,
    label: 'Weekly Loss',
  },
];

export const ruleTypes = {
  [RuleType.Systolic]: 'Systolic',
  [RuleType.Diastolic]: 'Diastolic',
  [RuleType.HeartRate]: 'Heart Rate',
  [RuleType.Weight]: 'Weight',
  [RuleType.POSPO2]: 'PO SPO2',
  [RuleType.POPulserate]: 'PO Pulserate',
  [RuleType.BloodGlucose]: 'Blood Glucose',
};
