import { INotesStore } from './types';

const initStore: INotesStore = {
  items: [],
  pagination: {
    page: 0,
    itemsPerPage: 20,
    hasMore: false,
  },
};

export default initStore;
