import { Day } from '~/types';
import { DayColor } from '../../types';
import { daysList } from '../types';

export const daysMap = {
  [Day.Monday]: {
    name: Day.Monday,
    number: 0,
    color: DayColor.Monday,
  },
  [Day.Tuesday]: {
    name: Day.Tuesday,
    number: 1,
    color: DayColor.Tuesday,
  },
  [Day.Wednesday]: {
    name: Day.Wednesday,
    number: 2,
    color: DayColor.Wednesday,
  },
  [Day.Thursday]: {
    name: Day.Thursday,
    number: 3,
    color: DayColor.Thursday,
  },
  [Day.Friday]: {
    name: Day.Friday,
    number: 4,
    color: DayColor.Friday,
  },
  [Day.Saturday]: {
    name: Day.Saturday,
    number: 5,
    color: DayColor.Saturday,
  },
  [Day.Sunday]: {
    name: Day.Sunday,
    number: 6,
    color: DayColor.Sunday,
  },
};

export const dayLabelsList = daysList.map(day => daysMap[day]);

export const plotBands = daysList.map((day, index) => ({
  label: {
    text: day,
    verticalAlign: 'bottom' as Highcharts.VerticalAlignValue,
    y: 20,
  },
  color: 'transparent',
  from: index,
  to: index + 1,
}));
