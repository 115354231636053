import { Tooltip } from '@material-ui/core';
import { ReactElement } from 'react';

interface IProps {
  weight: number;
}

const Icon = ({ weight }: IProps): ReactElement => (
  <Tooltip title={`Weight Scale (${weight} Lbs)`}>
    <svg width="30" height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 0C6.71578 0 0 1.56744 0 3.50091V24.7283C0 25.4307 0.628125 26 1.40285 26H28.5971C29.372 26 30 25.4306 30 24.7283V3.50091C30 1.56744 23.2843 0 15 0Z"
        fill="#AFAFD9"
      />
      <path
        opacity="0.1"
        d="M1.42641 24.7283V3.50091C1.42641 1.62321 7.76015 0.0908809 15.7132 0.00398366C15.4768 0.001381 15.2391 0 15 0C6.71578 0 0 1.56739 0 3.50091V24.7283C0 25.4307 0.628125 26 1.40285 26H2.82926C2.05453 26 1.42641 25.4306 1.42641 24.7283Z"
        fill="#131F25"
      />
      <path
        d="M23.9678 2.82131C23.9443 2.81685 23.9214 2.81239 23.8976 2.80793C21.4016 2.34205 18.2417 2.08545 15 2.08545C11.7583 2.08545 8.5984 2.34205 6.10242 2.80793C6.07869 2.81239 6.05578 2.81685 6.03223 2.82131V7.92509H23.9678V2.82131Z"
        fill="white"
      />
      <path
        opacity="0.1"
        d="M9.66844 2.808C11.7037 2.42811 14.1805 2.18755 16.783 2.11165C16.1939 2.09444 15.5984 2.08557 15 2.08557C11.7583 2.08557 8.5984 2.34217 6.10242 2.80805C6.07869 2.81251 6.05578 2.81697 6.03223 2.82144V7.92521H9.59824V2.82133C9.62174 2.81687 9.64465 2.81246 9.66844 2.808Z"
        fill="#131F25"
      />
      <path
        opacity="0.1"
        d="M26.1796 11.2351H3.82037C3.18428 11.2351 2.66865 11.7025 2.66865 12.2791V23.4315C2.66865 24.0081 3.18428 24.4755 3.82037 24.4755H26.1796C26.8157 24.4755 27.3313 24.0081 27.3313 23.4315V12.2792C27.3314 11.7026 26.8157 11.2351 26.1796 11.2351Z"
        fill="#131F25"
      />
      <path
        d="M26.1796 10.3945H3.82037C3.18427 10.3945 2.66865 10.8619 2.66865 11.4386V22.591C2.66865 23.1676 3.18427 23.635 3.82037 23.635H26.1796C26.8157 23.635 27.3313 23.1676 27.3313 22.591V11.4387C27.3314 10.8621 26.8157 10.3945 26.1796 10.3945Z"
        fill="#143062"
      />
      <path
        d="M8.43627 4.60538C8.28463 4.60538 8.13393 4.54727 8.0281 4.43419L6.64734 2.95896C6.45621 2.75478 6.48387 2.44878 6.7091 2.27552C6.93439 2.10225 7.27195 2.12732 7.46303 2.3315L8.84379 3.80673C9.03492 4.01091 9.00727 4.31691 8.78203 4.49017C8.68148 4.56756 8.55855 4.60538 8.43627 4.60538Z"
        fill="#143062"
      />
      <path
        d="M11.5679 4.1619C11.3637 4.1619 11.1687 4.05535 11.0796 3.87555L10.362 2.42698C10.241 2.18265 10.3614 1.89566 10.6309 1.78603C10.9004 1.6763 11.217 1.78545 11.3379 2.02973L12.0555 3.4783C12.1766 3.72263 12.0562 4.00961 11.7867 4.11924C11.7156 4.14814 11.6411 4.1619 11.5679 4.1619Z"
        fill="#143062"
      />
      <path
        d="M21.5638 4.6054C21.4415 4.6054 21.3186 4.56758 21.218 4.49024C20.9927 4.31698 20.9651 4.01098 21.1562 3.8068L22.537 2.33157C22.7281 2.12739 23.0657 2.10232 23.2909 2.27553C23.5162 2.4488 23.5438 2.7548 23.3527 2.95897L21.972 4.43421C21.8661 4.54718 21.7155 4.6054 21.5638 4.6054Z"
        fill="#143062"
      />
      <path
        d="M18.4322 4.16188C18.359 4.16188 18.2846 4.14818 18.2134 4.11923C17.944 4.00949 17.8236 3.72251 17.9446 3.47828L18.6623 2.02971C18.7834 1.78538 19.0998 1.67633 19.3694 1.78602C19.6389 1.89576 19.7592 2.18274 19.6382 2.42697L18.9205 3.87554C18.8314 4.05528 18.6364 4.16188 18.4322 4.16188Z"
        fill="#143062"
      />
      <path
        d="M15 6.64231C14.7046 6.64231 14.4651 6.42522 14.4651 6.15741V3.55862C14.4651 3.29081 14.7046 3.07373 15 3.07373C15.2954 3.07373 15.5349 3.29081 15.5349 3.55862V6.15741C15.5349 6.42522 15.2954 6.64231 15 6.64231Z"
        fill="#FF6465"
      />
      <path
        d="M13.0499 7.92516C13.0499 6.94889 13.923 6.15747 14.9999 6.15747C16.0769 6.15747 16.9499 6.94889 16.9499 7.92516H13.0499Z"
        fill="#143062"
      />
      <text
        x="15"
        y="17.01475"
        fill="white"
        fontSize="9"
        textAnchor="middle"
        fontWeight="bold"
        alignmentBaseline="central"
        letterSpacing="-0.25"
        cursor="default"
      >
        {weight}
      </text>
    </svg>
  </Tooltip>
);

export default Icon;
