import { ITab } from '~/ui/components/reusable/VerticalTabs/types';
import { Tab } from './types';

export const tabs: ITab[] = [
  {
    value: Tab.ReadingLogs,
    label: 'Reading Logs',
    roles: [],
  },
  {
    value: Tab.PatientSummary,
    label: 'Patient Summary',
    roles: [],
  },
];

export default {};
