import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetCarePlanIcd10Codes } from '../types';

const onGetCarePlanIcd10Codes: IThunkGetCarePlanIcd10Codes = thunk(
  // @ts-ignore
  async ({ setCarePlanIcd10Codes }, patientId) => {
    const { data } = await api.carePlanIcd10Codes.getList(patientId);
    setCarePlanIcd10Codes(data);
  },
);

export default onGetCarePlanIcd10Codes;
