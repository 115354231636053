import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Icon from '@material-ui/core/Icon';

import { isoToFormat } from '~/utils/date';

import { EVENT_ICONS } from '../../constants';
import { IDeviceHistoryLog } from '~/services/api/devices/types';
import styles from './Item.module.scss';

interface IProps {
  item: IDeviceHistoryLog;
}

const Item = ({ item }: IProps) => (
  <TableRow>
    <TableCell>{isoToFormat(item.createdAt, 'yyyy/MM/dd HH:mm:ss')}</TableCell>
    <TableCell className={styles.cell}>{item.userFullName}</TableCell>
    <TableCell>
      <div className={styles.event}>
        <Icon color="primary">{EVENT_ICONS[item.eventId]}</Icon>
        <span className={styles.eventName}>{item.eventName}</span>
      </div>
    </TableCell>
    <TableCell>{item.programName}</TableCell>
    <TableCell>{item.patientFullName}</TableCell>
  </TableRow>
);

export default Item;
