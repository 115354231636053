import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkUpdateActivityLog } from '../types';

const onUpdateActivityLog: IThunkUpdateActivityLog = thunk(
  // @ts-ignore
  async ({ updateActivityLog }, payload) => {
    const insurance = await api.activityLogs.update(payload).then(res => res.data);
    updateActivityLog(insurance);
  },
);

export default onUpdateActivityLog;
