import { useEffect, useMemo, useRef, ReactElement } from 'react';
import { MenuListComponentProps } from 'react-select';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';
import get from 'lodash/get';

import { IOption } from '../types';
import styles from './MenuList.module.scss';

type IsMulti = boolean;

interface IMenuListProps extends MenuListComponentProps<IOption, IsMulti> {
  focusedOption?: IOption;
}

const optionRowHeight = 30;

const Item = ({ data, index, style }: ListChildComponentProps) => {
  const title = get(data, [index, 'props', 'data', 'label'], '');

  if (Array.isArray(data))
    return (
      <li style={style} key={index} className={styles.listItem} title={title}>
        {data[index]}
      </li>
    );

  return (
    <li key={index} className={styles.listItem} title={title}>
      {data}
    </li>
  );
};

const MenuList = ({
  options,
  children,
  maxHeight,
  focusedOption,
}: IMenuListProps): ReactElement => {
  const listRef = useRef(null);

  const focusedOptionIndex = options.indexOf(focusedOption);
  const itemCount = Array.isArray(children) ? children.length : 0;

  const height = useMemo(() => {
    const optionsHeight = optionRowHeight * itemCount;
    if (optionsHeight < maxHeight) {
      return optionsHeight;
    }
    return maxHeight;
  }, [itemCount]);

  useEffect(() => {
    if (!focusedOptionIndex) return;
    listRef.current?.scrollToItem(focusedOptionIndex);
  }, [listRef.current]);

  if (!itemCount) return <div className={styles.noOptions}>No options</div>;

  return (
    <List
      className="List"
      width="100%"
      height={height}
      itemCount={itemCount}
      itemSize={optionRowHeight}
      itemData={children}
      ref={listRef}
    >
      {Item}
    </List>
  );
};

export default MenuList;
