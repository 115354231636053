import axios from 'axios';
import { IResponse } from '~/services/api/types';
import { IDashboardCounters } from './types';

export const getCounters = (): IResponse<IDashboardCounters> =>
  axios({
    method: 'get',
    url: 'dashboard/counters',
  });

export default {};
