import { useEffect, useState, ReactElement } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';

import { useStoreState, useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import Loader from '~/ui/components/common/Loader';
import NoDataFound from '~/ui/components/reusable/NoDataFound';
import Item from './Item';

import { UserRole } from '~/types';

const ProgramAdmin = (): ReactElement => {
  const [isLoading, setIsLoading] = useState(true);

  const userRole = useStoreState(state => state.auth.currentUser?.roleId);
  const { items: users, itemsPagination: pagination } = useStoreState(state => state.users);
  const { onGetUsers, onGetMoreUsers } = useStoreActions(actions => actions.users);
  const showError = useStoreActions(actions => actions.snackbar.showError);

  const permissions = {
    canRemoveUser: userRole === UserRole.SystemAdmin,
  };

  const payload = { roleId: UserRole.ProgramAdmin };

  const onMount = async () => {
    try {
      await onGetUsers(payload);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsLoading(false);
    }
  };

  const onLoadMore = async () => {
    try {
      await onGetMoreUsers(payload);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  return (
    <InfiniteScroll
      dataLength={users.length}
      next={onLoadMore}
      hasMore={pagination.hasMore}
      loader={<Loader />}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Login Email</TableCell>
                  <TableCell>Login Date</TableCell>
                  <TableCell>Program Name</TableCell>
                  <TableCell />
                  <TableCell>Edit</TableCell>
                  {permissions.canRemoveUser && <TableCell align="center">Remove</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {users.length ? (
                  users.map(item => <Item key={item.id} user={item} permissions={permissions} />)
                ) : (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <NoDataFound />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </InfiniteScroll>
  );
};

export default ProgramAdmin;
