const stateOptions = [
  {
    value: 'Alabama',
    label: 'Alabama',
  },
  {
    value: 'Alaska',
    label: 'Alaska',
  },
  {
    value: 'Arizona',
    label: 'Arizona',
  },
  {
    value: 'Arkansas',
    label: 'Arkansas',
  },
  {
    value: 'California',
    label: 'California',
  },
  {
    value: 'Colorado',
    label: 'Colorado',
  },
  {
    value: 'Connecticut',
    label: 'Connecticut',
  },
  {
    value: 'Delaware',
    label: 'Delaware',
  },
  {
    value: 'DistrictOfColumbia',
    label: 'District Of Columbia',
  },
  {
    value: 'Florida',
    label: 'Florida',
  },
  {
    value: 'Georgia',
    label: 'Georgia',
  },
  {
    value: 'Hawaii',
    label: 'Hawaii',
  },
  {
    value: 'Idaho',
    label: 'Idaho',
  },
  {
    value: 'Illinois',
    label: 'Illinois',
  },
  {
    value: 'Indiana',
    label: 'Indiana',
  },
  {
    value: 'Iowa',
    label: 'Iowa',
  },
  {
    value: 'Island',
    label: 'Island',
  },
  {
    value: 'Kansas',
    label: 'Kansas',
  },
  {
    value: 'Kentucky',
    label: 'Kentucky',
  },
  {
    value: 'Louisiana',
    label: 'Louisiana',
  },
  {
    value: 'Maine',
    label: 'Maine',
  },
  {
    value: 'Maryland',
    label: 'Maryland',
  },
  {
    value: 'Massachusetts',
    label: 'Massachusetts',
  },
  {
    value: 'Michigan',
    label: 'Michigan',
  },
  {
    value: 'Minnesota',
    label: 'Minnesota',
  },
  {
    value: 'Mississippi',
    label: 'Mississippi',
  },
  {
    value: 'Missouri',
    label: 'Missouri',
  },
  {
    value: 'Montana',
    label: 'Montana',
  },
  {
    value: 'Nebraska',
    label: 'Nebraska',
  },
  {
    value: 'Nevada',
    label: 'Nevada',
  },
  {
    value: 'NewHampshire',
    label: 'New Hampshire',
  },
  {
    value: 'NewJersey',
    label: 'New Jersey',
  },
  {
    value: 'NewMexico',
    label: 'New Mexico',
  },
  {
    value: 'NewYork',
    label: 'New York',
  },
  {
    value: 'NorthCarolina',
    label: 'North Carolina',
  },
  {
    value: 'NorthDakota',
    label: 'North Dakota',
  },
  {
    value: 'Ohio',
    label: 'Ohio',
  },
  {
    value: 'Oklahoma',
    label: 'Oklahoma',
  },
  {
    value: 'Oregon',
    label: 'Oregon',
  },
  {
    value: 'Pennsylvania',
    label: 'Pennsylvania',
  },
  {
    value: 'Rhode',
    label: 'Rhode',
  },
  {
    value: 'SouthCarolina',
    label: 'South Carolina',
  },
  {
    value: 'SouthDakota',
    label: 'South Dakota',
  },
  {
    value: 'Tennessee',
    label: 'Tennessee',
  },
  {
    value: 'Texas',
    label: 'Texas',
  },
  {
    value: 'Utah',
    label: 'Utah',
  },
  {
    value: 'Vermont',
    label: 'Vermont',
  },
  {
    value: 'Virginia',
    label: 'Virginia',
  },
  {
    value: 'Washington',
    label: 'Washington',
  },
  {
    value: 'WestVirginia',
    label: 'West Virginia',
  },
  {
    value: 'Wisconsin',
    label: 'Wisconsin',
  },
  {
    value: 'Wyoming',
    label: 'Wyoming',
  },
];

export default stateOptions;
