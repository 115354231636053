import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetDashboardCounters } from '../types';

const onGetDashboardCounters: IThunkGetDashboardCounters = thunk(
  // @ts-ignore
  async ({ setCounters }) => {
    const data = await api.dashboard.getCounters().then(res => res.data);

    setCounters(data);
  },
);

export default onGetDashboardCounters;
