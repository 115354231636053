import { IAuthStore } from './types';

const initStore: IAuthStore = {
  currentUser: null,
  authorized: false,
  authChecked: false,
  isMfaRequired: false,
  parsedAccessToken: null,
  parsedRefreshToken: null,
};

export default initStore;
