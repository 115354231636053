import initStore from './initStore';
import actions from './actions';
import thunks from './thunks';
import { IAuthModel } from './types';

const auth: IAuthModel = {
  ...initStore,
  ...actions,
  ...thunks,
};

export default auth;
