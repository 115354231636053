import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkUpdateCarePlanMedication } from '../types';

const onUpdateCarePlanMedication: IThunkUpdateCarePlanMedication = thunk(
  // @ts-ignore
  async ({ updateCarePlanMedication }, payload) => {
    const { data } = await api.carePlanMedications.update(payload);
    updateCarePlanMedication(data);
  },
);

export default onUpdateCarePlanMedication;
