import { useEffect, useState, ReactElement } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';

import api from '~/services/api';
import { useStoreState, useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import createFormInitialValues from './form/create/initialValues';
import getEditFormInitialValues from './form/edit/getInitialValues';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import Header from './components/Header';
import List from './components/List';
import NoteModal from './components/popups/NoteModal';

import { UserRole } from '~/types';
import { IFormValues } from '~/ui/components/reusable/Notes/NoteForm/types';
import { IPatientParams } from './types';
import styles from './Notes.module.scss';
import { INote, INotesListFilters } from '~/services/api/notes/types';
import { NoteType } from '~/services/api/enums';
import { isoToFormat } from '~/utils/date';

const PatientNotes = (): ReactElement => {
  const location = useLocation();
  const routerParams = useParams<IPatientParams>();
  const patientId = Number(routerParams.id);

  const queryParams = new URLSearchParams(location.search);

  const [isLoading, setIsLoading] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [createNote, setCreateNote] = useState<boolean>(false);
  const [editNote, setEditNote] = useState<INote>(null);
  const [deleteNoteId, setDeleteNoteId] = useState<number>(null);

  const currentUser = useStoreState(state => state.auth.currentUser);
  const patientInfo = useStoreState(state => state.patient.info);
  const {
    items: notes,
    pagination: { hasMore },
  } = useStoreState(state => state.notes);

  const { onGetNotes, onGetMoreNotes, onGetNote } = useStoreActions(actions => actions.notes);
  const { showNotify, showError } = useStoreActions(actions => actions.snackbar);

  const userRole = currentUser?.roleId;
  const userId = currentUser?.id;

  const permissions = {
    canManage: [UserRole.SystemAdmin, UserRole.SuperAdmin].includes(userRole),
  };

  const onMount = async () => {
    try {
      setIsLoading(true);
      await onGetNotes({ patientId });

      const noteId = Number(queryParams.get('id'));

      if (noteId) {
        const note = await onGetNote(noteId);

        setEditNote(note);
      }
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsLoading(false);
    }
  };

  const onLoadMore = () => {
    onGetMoreNotes({ patientId });
  };

  const onSubmitSearch = async (values: INotesListFilters) => {
    try {
      setIsLoading(true);
      const payload = { patientId, ...values };
      await onGetNotes(payload);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddNote = async (formValues: IFormValues) => {
    const { showReminder, reminderDate, ...restFormValues } = formValues;

    const payload = {
      ...restFormValues,
      reminderDate: showReminder ? isoToFormat(reminderDate, 'y-MM-dd') : null,
      typeId: NoteType.General,
    } as INote;

    try {
      setIsAdding(true);
      await api.notes.create(patientInfo.id, payload);
      await onMount();
      setCreateNote(false);
      showNotify('Note successfully created');
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsAdding(false);
    }
  };

  const handleUpdateNote = async (formValues: IFormValues) => {
    const { showReminder, reminderDate, ...restFormValues } = formValues;

    const payload = {
      ...editNote,
      ...restFormValues,
      reminderDate: showReminder ? isoToFormat(reminderDate, 'y-MM-dd') : null,
    } as INote;

    try {
      setIsUpdating(true);
      await api.notes.update(payload);
      await onMount();
      setEditNote(null);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsUpdating(false);
    }
  };

  const handleDeleteNote = async () => {
    setIsDeleting(true);

    try {
      await api.notes.remove(deleteNoteId);
      await onMount();
      setDeleteNoteId(null);
      showNotify('Note removed');
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsDeleting(false);
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  return (
    <Paper className={styles.notes}>
      <Header
        patientInfo={patientInfo}
        onSubmit={onSubmitSearch}
        showModal={() => setCreateNote(true)}
      />
      <List
        notes={notes}
        hasMore={hasMore}
        isLoading={isLoading}
        userId={userId}
        permissions={permissions}
        onLoadMore={onLoadMore}
        onUpdateNote={setEditNote}
        onDeleteNote={setDeleteNoteId}
      />
      {createNote && (
        <NoteModal
          patient={patientInfo}
          initialValues={createFormInitialValues}
          isProcessing={isAdding}
          onSubmit={handleAddNote}
          onClose={() => setCreateNote(false)}
        />
      )}
      {editNote && (
        <NoteModal
          mode="edit"
          patient={patientInfo}
          initialValues={getEditFormInitialValues(editNote)}
          isProcessing={isUpdating}
          onSubmit={handleUpdateNote}
          onClose={() => setEditNote(null)}
        />
      )}
      {deleteNoteId && (
        <ConfirmModal
          title="Remove"
          description="Are you sure you want to remove current note?"
          isLoading={isDeleting}
          onConfirm={handleDeleteNote}
          onClose={() => setDeleteNoteId(null)}
        />
      )}
    </Paper>
  );
};

export default PatientNotes;
