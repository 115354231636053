import { useState, ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import Button from '@material-ui/core/Button';

import lockIcon from 'icons/lock.svg';
import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import { initialValues, validate } from './form';
import Loader from '~/ui/components/common/Loader';
import Input from '~/ui/components/inputs/Input';
import { IVerifyCode } from '~/services/api/auth/types';

import styles from './VerifyCode.module.scss';

const SignInForm = (): ReactElement => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IVerifyCode>({
    resolver: validate,
    defaultValues: initialValues,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState('');

  const { onResendCode, onVerifyCode } = useStoreActions(actions => actions.auth);

  const resendCode = async () => {
    setIsLoading(true);

    try {
      await onResendCode();
    } catch (e) {
      setServerError(extractErrorMessage(e));
    }

    setIsLoading(false);
  };

  const onSubmit = async (values: IVerifyCode) => {
    setIsLoading(true);

    try {
      await onVerifyCode(values);
    } catch (e) {
      setServerError(extractErrorMessage(e));
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <img src={lockIcon} className={styles.lockIcon} alt="Lock" />
      <div>
        You have received an email with contains an authentication code. If you haven&apos;t it,
        press&nbsp;
        <span
          onClick={resendCode}
          onKeyPress={resendCode}
          className={styles.resendCode}
          role="button"
          tabIndex={0}
        >
          Resend code
        </span>
      </div>
      <div className={styles.row}>
        <Input
          name="code"
          register={register}
          errors={errors}
          size="medium"
          label="Authentication code"
          placeholder="Enter code"
        />
      </div>
      <div className={styles.row}>
        <Button
          type="submit"
          size="large"
          variant="contained"
          color="primary"
          className={styles.btn}
          disabled={isLoading}
        >
          Confirm
        </Button>
      </div>
      {isLoading && <Loader />}
      {serverError && <div className={styles.serverError}>{serverError}</div>}
    </form>
  );
};

export default SignInForm;
