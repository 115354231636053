import { ReactElement, useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';

import { useStoreState, useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import Header from './Header';
import List from './List';
import AddMedication from '../popups/AddMedication';

import { IPatient } from '~/services/api/patients/types';
import styles from './Medication.module.scss';
import { IMedicationsListFilters } from '~/services/api/medications/types';

interface IProps {
  patient: IPatient;
}

const Medication = ({ patient }: IProps): ReactElement => {
  const [isLoading, setIsLoading] = useState(true);
  const [showCreatePopup, setShowCreatePopup] = useState(false);

  const medications = useStoreState(state => state.medications.items);

  const onGetMedications = useStoreActions(actions => actions.medications.onGetMedications);
  const { showError } = useStoreActions(actions => actions.snackbar);

  const onSearch = async (params: IMedicationsListFilters) => {
    try {
      setIsLoading(true);
      await onGetMedications({ patientId: patient.id, ...params });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsLoading(false);
    }
  };

  const onMount = async () => {
    try {
      setIsLoading(true);
      await onGetMedications({ patientId: patient.id });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  return (
    <Paper elevation={3} className={styles.medication}>
      <Header
        patient={patient}
        onShowCreatePopup={() => setShowCreatePopup(true)}
        onSubmit={onSearch}
      />
      <List isLoading={isLoading} medications={medications} />
      {showCreatePopup && (
        <AddMedication patientId={patient.id} onClose={() => setShowCreatePopup(false)} />
      )}
    </Paper>
  );
};

export default Medication;
