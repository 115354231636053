import * as Highcharts from 'highcharts';

import { daysMap, plotBands } from './constants';
import { dayPartsList, daysList, IAverageData } from '../types';
import { DayPartOffset } from './types';
import { DeviceType } from '~/services/api/enums';
import { analysisCategories } from '../../types';

interface IData {
  charts: IChartData[];
}

interface IChartData {
  label: string;
  options: Highcharts.Options;
}

export const getSeries = (
  averageData: IAverageData,
  valuesIndex: number,
): Highcharts.SeriesScatterOptions[] =>
  daysList.flatMap(day => ({
    type: 'scatter' as const,
    name: day,
    color: daysMap[day].color,
    data: dayPartsList
      .map(dayPart => ({
        x: DayPartOffset[dayPart] + daysMap[day].number,
        y:
          averageData.dayParts.find(x => x.dayPart === dayPart).days.find(x => x.day === day)
            .values[valuesIndex] ?? null,
        name: dayPart,
      }))
      .filter(x => x.y !== null),
  }));

export const getChartsData = (averageData: IAverageData, deviceTypeId: DeviceType): IData => ({
  charts: analysisCategories[deviceTypeId].map((x, i) => ({
    label: x,
    options: {
      chart: {
        type: 'line',
        marginTop: 25,
      },
      title: {
        text: null,
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        title: {
          text: '',
        },
        labels: {
          enabled: false,
        },
        gridLineWidth: 1,
        min: 0,
        max: 7,
        tickmarkPlacement: 'on',
        tickInterval: 1,
        plotBands,
      },
      yAxis: {
        title: {
          text: '',
        },
        gridLineWidth: 0,
      },
      plotOptions: {
        series: {
          pointPlacement: 'on',
        },
      },
      tooltip: Highcharts.defaultOptions.tooltip,
      series: getSeries(averageData, i),
    },
  })),
});

export default {};
