import { ReactElement } from 'react';

import { useStoreState } from '~/store/hooks';
import QuickNotes from './QuickNotes';

import styles from './PatientPanel.module.scss';

const PatientPanel = (): ReactElement => {
  const patient = useStoreState(state => state.patient.info);

  if (!patient) return null;

  return (
    <div className={styles.patientPanel}>
      <div>
        <span className={styles.title}>Program:</span> {patient.programName}
      </div>
      <div className={styles.divider} />
      <QuickNotes patientId={patient.id} />
      <div>
        <span className={styles.title}>Patient:</span> {patient.fullName}
      </div>
    </div>
  );
};

export default PatientPanel;
