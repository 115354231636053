import { ReactElement } from 'react';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';

import NoDataFound from '~/ui/components/reusable/NoDataFound';
import Item from './Item';

import { IPagePermissions } from '../../types';
import { IActivityLog } from '~/services/api/activityLogs/types';

interface IProps {
  activityLogs: IActivityLog[];
  permissions: IPagePermissions;
  userId: number;
  onRemoveActivityLog: (activityLogId: number) => void;
}

const TableComponent = ({
  activityLogs,
  permissions,
  userId,
  onRemoveActivityLog,
}: IProps): ReactElement => (
  <TableContainer>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>Date</TableCell>
          <TableCell>Author</TableCell>
          <TableCell>Mode</TableCell>
          <TableCell align="center">Activity Minutes</TableCell>
          <TableCell>Note</TableCell>
          <TableCell>Edit</TableCell>
          {permissions.canManage && <TableCell>Remove</TableCell>}
        </TableRow>
      </TableHead>
      <TableBody>
        {activityLogs.length ? (
          activityLogs.map(item => (
            <Item
              key={item.id}
              activityLog={item}
              permissions={permissions}
              userId={userId}
              onRemove={onRemoveActivityLog}
            />
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={7}>
              <NoDataFound />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
);

export default TableComponent;
