import axios from 'axios';
import { IResponse } from '~/services/api/types';
import { IProgram, IProgramList, IProgramsListFilters } from './types';

const apiUrl = 'programs';

export const getList = (params: IProgramsListFilters): IResponse<IProgramList[]> =>
  axios({
    method: 'get',
    url: apiUrl,
    params,
  });

export const get = (id: number): IResponse<IProgram> =>
  axios({
    method: 'get',
    url: `${apiUrl}/${id}`,
  });

export const create = (data: IProgram): IResponse<IProgram> =>
  axios({
    method: 'post',
    url: apiUrl,
    data,
  });

export const update = (data: IProgram): IResponse<IProgram> =>
  axios({
    method: 'put',
    url: apiUrl,
    data,
  });

export const remove = (id: number): IResponse<void> =>
  axios({
    method: 'delete',
    url: `${apiUrl}/${id}`,
  });
