import { IPagedFilters } from '~/services/api/types';

export interface IPagination {
  page: number;
  itemsPerPage: number;
  hasMore: boolean;
}

export const getPaginationPayload = ({ page, itemsPerPage }: IPagination): IPagedFilters => {
  if (page === null || itemsPerPage === null) {
    return {};
  }

  const start = page * itemsPerPage;
  const end = start + itemsPerPage;

  return {
    start,
    end,
  };
};

export default {};
