import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { getPaginationPayload } from '~/utils/pagination';
import { IStatusPatientsStore, IThunkGetMoreStatusPatients } from '../types';

const onGetMoreStatusPatients: IThunkGetMoreStatusPatients = thunk(
  // @ts-ignore
  async ({ setMoreStatusPatients }, payload, { getState }) => {
    const localState = getState() as IStatusPatientsStore;

    const paginationPayload = getPaginationPayload(localState.pagination);

    const { data } = await api.patients.getByStatus({
      ...payload,
      ...paginationPayload,
    });

    setMoreStatusPatients(data);
  },
);

export default onGetMoreStatusPatients;
