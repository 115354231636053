import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { getPaginationPayload } from '~/utils/pagination';
import { IActivityLogsStore, IThunkGetMoreActivityLogsByPatientId } from '../types';

const onGetMoreActivityLogsByPatientId: IThunkGetMoreActivityLogsByPatientId = thunk(
  // @ts-ignore
  async ({ setMoreActivityLogsByPatientId }, { patientId, params }, { getState }) => {
    const localState = getState() as IActivityLogsStore;

    const paginationPayload = getPaginationPayload(localState.paginationByPatientId);

    const { data } = await api.activityLogs.getByPatientId(patientId, {
      ...params,
      ...paginationPayload,
    });

    setMoreActivityLogsByPatientId(data);
  },
);

export default onGetMoreActivityLogsByPatientId;
