import { ReactElement } from 'react';
import { isoToFormat } from '~/utils/date';

import styles from './InfoPanel.module.scss';

interface IProps {
  startDate: string;
  endDate: string;
  label: string;
}

const InfoPanel = ({ startDate, endDate, label }: IProps): ReactElement => {
  const start = isoToFormat(startDate, 'dd-MMM-y');
  const end = isoToFormat(endDate, 'dd-MMM-y');

  return (
    <div className={styles.container}>
      <div>{`${label} (From ${start} To ${end})`}</div>
    </div>
  );
};

export default InfoPanel;
