import { ReactElement } from 'react';
import { Controller, Control, FieldErrors } from 'react-hook-form';
import get from 'lodash/get';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import styles from './CheckboxGroup.module.scss';

type IFieldValue = string[];

interface IOption {
  value: string;
  label: string;
}

interface IProps {
  control: Control<any>;
  errors: FieldErrors;
  size?: 'medium' | 'small';
  name: string;
  label?: string;
  checkboxOptions: IOption[];
}

const CheckboxGroup = ({
  control,
  errors,
  size = 'medium',
  name,
  label,
  checkboxOptions = [],
}: IProps): ReactElement => {
  const error = get(errors, `${name}.message`, '');

  const handleChange = (fieldValue: IFieldValue, checkboxEvent: any) => {
    let res;
    const val = checkboxEvent.target.value;

    const exist = fieldValue.some(item => item === val);

    if (exist) {
      res = fieldValue.filter(item => item !== val);
    } else {
      res = [...fieldValue, val];
    }

    return res;
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => {
        if (!Array.isArray(value)) return <div>Checkbox group value must be array</div>;

        return (
          <FormControl component="fieldset">
            {label && <div className={styles.label}>{label}</div>}
            <ul className={styles.list}>
              {checkboxOptions.map(({ value: checkboxValue, label: checkboxLabel }) => (
                <li key={checkboxValue} className={styles.item}>
                  <div className={styles.checkboxContainer}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={checkboxValue}
                          checked={value.includes(checkboxValue)}
                          value={checkboxValue}
                          onChange={option => onChange(handleChange(value, option))}
                          color="primary"
                          size={size}
                        />
                      }
                      label={checkboxLabel}
                      classes={{ root: styles.controlLabelRoot, label: styles.controlLabel }}
                    />
                  </div>
                </li>
              ))}
            </ul>
            {error && <FormHelperText error>{error}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
};

export default CheckboxGroup;
