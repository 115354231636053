import { ReactElement } from 'react';
import ErrorIcon from '@mui/icons-material/Error';

import styles from './AccessDeniedPage.module.scss';

const AccessDenied = (): ReactElement => (
  <div className={styles.accessDenied}>
    <ErrorIcon className={styles.icon} />
    <span className={styles.title}>You have no access to this page</span>
  </div>
);

export default AccessDenied;
