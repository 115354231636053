import { ReactElement, useEffect, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import debounceFunc from 'lodash/debounce';

interface IProps {
  debounce?: number;
  initialValues: any;
  onSubmit: (values: any) => void;
}

const AutoSubmit = ({ debounce = 0, initialValues, onSubmit }: IProps): ReactElement => {
  const {
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useFormContext();

  const handleSubmitForm = useCallback(
    debounceFunc(() => {
      handleSubmit(onSubmit)();
    }, debounce),
    [],
  );

  useEffect(() => {
    const subscription = watch(values => {
      if (values === initialValues || isSubmitting) return;
      handleSubmitForm();
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  return null;
};

export default AutoSubmit;
