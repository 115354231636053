import { ReactElement, useState, useEffect, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import api from '~/services/api';
import { useStoreState, useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import downloadFile from '~/utils/downloadFile';
import { getFileNameFromContentDisposition } from '~/utils/headers';
import { getDevicesActions } from './helper';
import Loader from '~/ui/components/common/Loader';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import Header from './Header';
import Table from './Table';
import AssignDevicesModal from './popups/AssignDevices';
import ModalDescription from './ModalDescription';

import { INITIAL_ACTION } from './constants';
import { IIdName } from '~/services/api/types';
import { IDevicesListFilters } from '~/services/api/devices/types';
import { DeviceAction, IAction } from './types';
import styles from './Inventory.module.scss';

const Inventory = (): ReactElement => {
  const [isLoading, setIsLoading] = useState(true);
  const [programs, setPrograms] = useState<IIdName[]>([]);
  const [filters, setFilters] = useState<IDevicesListFilters>(null);
  const [checkedDevicesIds, setCheckedDevicesIds] = useState([]);
  const [action, setAction] = useState<IAction>(INITIAL_ACTION);
  const [isCsvAdding, setIsCsvAdding] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { items: devices, pagination } = useStoreState(state => state.devices);
  const { onGetDevices, onGetMoreDevices } = useStoreActions(actions => actions.devices);
  const { showNotify, showError } = useStoreActions(actions => actions.snackbar);

  const devicesActions = useMemo(
    () => getDevicesActions(devices, checkedDevicesIds),
    [checkedDevicesIds.length],
  );

  const onAction = (actionType: DeviceAction, devicesIds: number[]) => {
    setAction({ type: actionType, devicesIds });
  };

  const resetAction = () => {
    setAction(INITIAL_ACTION);
  };

  const getDevices = async () => {
    setIsLoading(true);
    setCheckedDevicesIds([]);

    try {
      await onGetDevices(filters);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsLoading(false);
    }
  };

  const onLoadMoreDevices = async () => {
    try {
      await onGetMoreDevices(filters);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const onToggleCheckDevice = (deviceId: number) => {
    setCheckedDevicesIds(prev =>
      prev.includes(deviceId) ? prev.filter(id => id !== deviceId) : [...prev, deviceId],
    );
  };

  /* Add DevicesCSV */

  const handleAddDeviceCsv = async (formData: FormData) => {
    try {
      setIsCsvAdding(true);
      await api.devices.uploadDevices(formData);
      getDevices();
      showNotify('Devices successfully added');
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsCsvAdding(false);
    }
  };

  /* Export Devices to xlsx */

  const handleExportDevices = async () => {
    try {
      setIsExporting(true);
      const { data, headers } = await api.devices.getDevicesReport(filters);
      const fileName = getFileNameFromContentDisposition(headers['content-disposition']);
      downloadFile(data, fileName);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsExporting(false);
    }
  };

  /* Assign to program  */

  const handleAssignDevices = async (programId: number) => {
    try {
      await api.devices.assignDevices(programId, action.devicesIds);
      getDevices();
      showNotify('Devices successfully assigned');
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      resetAction();
      setCheckedDevicesIds([]);
    }
  };

  /* Unassign from program  */

  const handleUnAssignDevices = async () => {
    try {
      await api.devices.unAssignDevices(action.devicesIds);
      getDevices();
      showNotify('Devices successfully unassigned');
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      resetAction();
      setCheckedDevicesIds([]);
    }
  };

  /* Unregister from patient */

  const handleUnRegisterDevices = async () => {
    try {
      await api.devices.unRegisterDevices(action.devicesIds);
      getDevices();
      showNotify('Devices successfully unregistered');
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      resetAction();
      setCheckedDevicesIds([]);
    }
  };

  /* Mark as Returned */

  const handleMarkAsReturnedDevices = async () => {
    try {
      await api.devices.markAsReturnedDevices(action.devicesIds);
      getDevices();
      showNotify('Devices successfully marked as Returned');
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      resetAction();
      setCheckedDevicesIds([]);
    }
  };

  /* Mark as Lost */

  const handleMarkAsLostDevices = async () => {
    try {
      await api.devices.markAsLostDevices(action.devicesIds);
      getDevices();
      showNotify('Devices successfully marked as Lost');
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      resetAction();
      setCheckedDevicesIds([]);
    }
  };

  /* Remove devices */

  const handleRemoveDevices = async () => {
    try {
      setIsDeleting(true);
      await Promise.all(action.devicesIds.map(deviceId => api.devices.deleteDevice(deviceId)));
      await getDevices();
      showNotify('Device removed');
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      resetAction();
      setCheckedDevicesIds([]);
      setIsDeleting(true);
    }
  };

  const onMount = async () => {
    setIsLoading(true);

    try {
      const selectors = await api.devices.getSelectors().then(res => res.data);
      setPrograms(selectors.programs);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDevices();
  }, [filters]);

  useEffect(() => {
    onMount();
  }, []);

  return (
    <div className={styles.container}>
      <Header
        programs={programs}
        actions={devicesActions}
        checkedDevicesCount={checkedDevicesIds.length}
        isCsvAdding={isCsvAdding}
        isExporting={isExporting}
        onAddDeviceCsv={handleAddDeviceCsv}
        onExportDevices={handleExportDevices}
        onAction={onAction}
        onSubmit={setFilters}
      />
      <InfiniteScroll
        dataLength={devices.length}
        next={onLoadMoreDevices}
        hasMore={pagination.hasMore}
        loader={<Loader />}
      >
        <Table
          devices={devices}
          checkedDevicesIds={checkedDevicesIds}
          isLoading={isLoading}
          onToggleCheckDevice={onToggleCheckDevice}
          onAction={onAction}
        />
      </InfiniteScroll>
      {action.type === DeviceAction.Assign && (
        <AssignDevicesModal
          programs={programs}
          description={
            <ModalDescription
              title="Selected devices:"
              devices={devices}
              devicesIds={action.devicesIds}
            />
          }
          onClose={resetAction}
          onConfirm={handleAssignDevices}
        />
      )}
      {action.type === DeviceAction.Unassign && (
        <ConfirmModal
          title="Unassign devices"
          description={
            <ModalDescription
              title="Are you sure you want to unassign selected devices?"
              devices={devices}
              devicesIds={action.devicesIds}
            />
          }
          onClose={resetAction}
          onConfirm={handleUnAssignDevices}
        />
      )}
      {action.type === DeviceAction.Unregister && (
        <ConfirmModal
          title="Unregister devices"
          description={
            <ModalDescription
              title="Are you sure you want to unregister selected devices?"
              devices={devices}
              devicesIds={action.devicesIds}
            />
          }
          onClose={resetAction}
          onConfirm={handleUnRegisterDevices}
        />
      )}
      {action.type === DeviceAction.MarkAsReturned && (
        <ConfirmModal
          title="Mark devices as Returned"
          description={
            <ModalDescription
              title="Are you sure you want to mark selected devices as Returned?"
              devices={devices}
              devicesIds={action.devicesIds}
            />
          }
          onClose={resetAction}
          onConfirm={handleMarkAsReturnedDevices}
        />
      )}
      {action.type === DeviceAction.MarkAsLost && (
        <ConfirmModal
          title="Mark devices as Lost"
          description={
            <ModalDescription
              title="Are you sure you want to mark selected devices as Lost?"
              devices={devices}
              devicesIds={action.devicesIds}
            />
          }
          onClose={resetAction}
          onConfirm={handleMarkAsLostDevices}
        />
      )}
      {action.type === DeviceAction.Delete && (
        <ConfirmModal
          title="Delete"
          description={
            <ModalDescription
              title="Are you sure you want to delete next devices?"
              devices={devices}
              devicesIds={action.devicesIds}
            />
          }
          isLoading={isDeleting}
          onClose={resetAction}
          onConfirm={handleRemoveDevices}
        />
      )}
    </div>
  );
};

export default Inventory;
