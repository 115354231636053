import setCarePlanIcd10Codes from './setCarePlanIcd10Codes';
import addCarePlanIcd10Code from './addCarePlanIcd10Code';
import updateCarePlanIcd10Code from './updateCarePlanIcd10Code';
import removeCarePlanIcd10Code from './removeCarePlanIcd10Code';

export default {
  setCarePlanIcd10Codes,
  addCarePlanIcd10Code,
  updateCarePlanIcd10Code,
  removeCarePlanIcd10Code,
};
