import { ReactElement } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';

import NoDataFound from '~/ui/components/reusable/NoDataFound';
import Item from './Item';

import { IPagePermissions } from '../types';
import { IProgramList } from '~/services/api/programs/types';

interface IProps {
  programs: IProgramList[];
  permissions: IPagePermissions;
  onRemoveProgram: (program: IProgramList) => void;
}

const List = ({ programs, permissions, onRemoveProgram }: IProps): ReactElement => (
  <Paper>
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Program Name</TableCell>
            <TableCell width={70} align="center">
              Edit
            </TableCell>
            {permissions.canRemoveProgram && <TableCell align="center">Remove</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {programs.length ? (
            programs.map(item => (
              <Item
                key={item.id}
                program={item}
                permissions={permissions}
                onRemove={onRemoveProgram}
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={3}>
                <NoDataFound />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  </Paper>
);

export default List;
