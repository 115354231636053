import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkCreateCarePlanMedication } from '../types';

const onCreateCarePlanMedication: IThunkCreateCarePlanMedication = thunk(
  // @ts-ignore
  async ({ addCarePlanMedication }, payload) => {
    const { patientId, data } = payload;
    const medication = await api.carePlanMedications.create(patientId, data).then(res => res.data);
    addCarePlanMedication(medication);
  },
);

export default onCreateCarePlanMedication;
