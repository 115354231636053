import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkDeleteMedication } from '../types';

// @ts-ignore
const onDeleteMedication: IThunkDeleteMedication = thunk(async ({ deleteMedication }, id) => {
  await api.medications.remove(id).then(res => res.data);
  deleteMedication(id);
});

export default onDeleteMedication;
