import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetOverview } from '../types';

const onGetOverview: IThunkGetOverview = thunk(
  // @ts-ignore
  async ({ setOverviewFilters }, payload) => {
    const { patientId, params, filters } = payload;

    setOverviewFilters(filters);

    const patientOverview = await api.patients.getOverview(patientId, params).then(res => res.data);

    return patientOverview;
  },
);

export default onGetOverview;
