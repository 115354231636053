import { ReactElement } from 'react';
import classnames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './Loader.module.scss';

interface IProps {
  size?: number;
  fullScreen?: boolean;
}

const Loader = ({ size = 30, fullScreen = false }: IProps): ReactElement => (
  <div className={classnames(styles.loader, { [styles.fullScreen]: fullScreen })}>
    <CircularProgress color="primary" size={size} />
  </div>
);

export default Loader;
