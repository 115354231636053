import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetPatientNotificationRules } from '../types';

const onGetPatientNotificationRules: IThunkGetPatientNotificationRules = thunk(
  // @ts-ignore
  async ({ setPatientNotificationRules, reset }, filters) => {
    reset();

    const { data } = await api.patientNotificationRules.getList({
      ...filters,
    });

    setPatientNotificationRules(data);
  },
);

export default onGetPatientNotificationRules;
