import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkDischargePatient } from '../types';

const onDischargePatient: IThunkDischargePatient = thunk(
  // @ts-ignore
  async ({ dischargePatient }, patientId) => {
    await api.patients.discharge(patientId);
    dischargePatient();
  },
);

export default onDischargePatient;
