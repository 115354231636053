import { ReactElement } from 'react';

import { Tooltip } from '@material-ui/core';
import { getMeasurementData } from '../helper';
import { isoToFormat } from '~/utils/date';

import { Timezone } from '~/types';
import { INotificationRule } from '~/services/api/notificationRules/types';
import { IBloodGlucoseReading } from '~/services/api/readings/types';

import styles from './BloodGlucose.module.scss';
import Icon from './BloodGlucose.svg';
import AfterMealIcon from './AfterMeal.svg';
import BeforeMealIcon from './BeforeMeal.svg';
import { RuleType } from '~/services/api/enums';

interface IProps {
  reading: IBloodGlucoseReading;
  rules: INotificationRule[];
  timezone: Timezone;
}

const BloodGlucose = ({ reading, rules, timezone }: IProps): ReactElement => {
  const bloodGlucoseData = getMeasurementData({
    typeId: RuleType.BloodGlucose,
    value: reading.bloodGlucoseMgdl,
    rules,
  });

  const measurementDate = `${isoToFormat(reading.measuredAt, 'dd MMM y HH:mm')} ${timezone}`;

  const receivedBySystem = `Received by system: ${isoToFormat(
    reading.createdAt,
    'dd MMM y HH:mm',
  )} ${timezone}`;

  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <Tooltip title="Blood Glucose">
          <img src={Icon} alt="" />
        </Tooltip>
      </div>
      <div className={styles.info}>
        <div className={styles.measurements}>
          <div className={bloodGlucoseData.className}>
            <span className={styles.value}>{bloodGlucoseData.value}</span>
            <span className={styles.unit}>mg/dl</span>
          </div>
        </div>
        <div className={styles.date} title={receivedBySystem}>
          {measurementDate}
        </div>
      </div>
      {reading.beforeMeal !== null && (
        <div className={styles.statusIcon}>
          {reading.beforeMeal ? (
            <Tooltip title="Before meal">
              <img src={BeforeMealIcon} alt="" />
            </Tooltip>
          ) : (
            <Tooltip title="After meal">
              <img src={AfterMealIcon} alt="" />
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );
};

export default BloodGlucose;
