import { Tabs } from '@material-ui/core';
import MuiTab from '@material-ui/core/Tab';
import { ReactElement } from 'react';
import { Link, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { IRoutableTab } from './types';
import styles from './RoutableTabs.module.scss';

interface IProps {
  tabs: IRoutableTab[];
}

const RoutableTabs = ({ tabs }: IProps): ReactElement => {
  const routeMatch = useRouteMatch(tabs.map(x => x.path));
  const currentTab = routeMatch?.path ?? tabs[0].path;

  return (
    <>
      <Tabs value={currentTab} TabIndicatorProps={{ children: <span /> }}>
        {tabs.map(tab => (
          <MuiTab
            key={tab.path}
            label={tab.label}
            value={tab.path}
            to={tab.route}
            component={Link}
            disableRipple
          />
        ))}
      </Tabs>
      <div className={styles.container}>
        <Switch>
          {tabs.map(tab => (
            <Route key={tab.path} exact path={tab.path}>
              {tab.component}
            </Route>
          ))}
          <Redirect to={tabs[0].route} />
        </Switch>
      </div>
    </>
  );
};

export default RoutableTabs;
