import { useState, ReactElement } from 'react';
import Modal from '~/ui/components/common/Modal';

import api from '~/services/api';
import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import initialValues from './form/initialValues';
import PatientForm from '../PatientForm';

import styles from './AddPatient.module.scss';
import { IPatient } from '~/services/api/patients/types';

interface IProps {
  onClose: () => void;
}

const AddPatient = ({ onClose }: IProps): ReactElement => {
  const [isPatientAdding, setIsPatientAdding] = useState(false);

  const onGetDashboardCounters = useStoreActions(
    actions => actions.dashboard.onGetDashboardCounters,
  );
  const { showNotify, showError } = useStoreActions(actions => actions.snackbar);

  const addPatient = async (programId: number, data: IPatient, deviceIds: number[]) => {
    setIsPatientAdding(true);

    try {
      await api.patients.create(programId, data, deviceIds);
      onGetDashboardCounters(); // update patients counter

      showNotify('Patient successfully enrolled');
      onClose();
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsPatientAdding(false);
    }
  };

  const handleClose = () => {
    // prevent closing popup when patient is adding
    if (isPatientAdding) return;
    onClose();
  };

  return (
    <Modal open title="Patient Enrollment" className={styles.modal} onClose={handleClose}>
      <PatientForm
        initialValues={initialValues}
        isProcessing={isPatientAdding}
        onSubmit={addPatient}
      />
    </Modal>
  );
};

export default AddPatient;
