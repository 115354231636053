import axios from 'axios';
import { IPagedFilters, IResponse } from '~/services/api/types';
import { IDevice, IDevicesListFilters, IDevicesSelectors, IDeviceHistoryLog } from './types';

const apiUrl = 'devices';

export const getSelectors = (): IResponse<IDevicesSelectors> =>
  axios({
    method: 'get',
    url: `${apiUrl}/selectors`,
  });

export const getDevices = (params?: IDevicesListFilters): IResponse<IDevice[]> =>
  axios({
    method: 'get',
    url: apiUrl,
    params,
  });

export const deleteDevice = (id: number): IResponse<void> =>
  axios({
    method: 'delete',
    url: `${apiUrl}/${id}`,
    data: [],
  });

// assign devices for selected program
export const assignDevices = (programId: number, data: number[]): IResponse<void> =>
  axios({
    method: 'post',
    url: `${apiUrl}/assign`,
    params: {
      programId,
    },
    data,
  });

// unassign devices from their program
export const unAssignDevices = (data: number[]): IResponse<void> =>
  axios({
    method: 'post',
    url: `${apiUrl}/unassign`,
    data,
  });

export const registerDevices = (patientId: number, deviceList: number[]): IResponse<void> =>
  axios({
    method: 'post',
    url: `${apiUrl}/register`,
    params: {
      patientId,
    },
    data: deviceList,
  });

// unregister devices from their patients
export const unRegisterDevices = (data: number[]): IResponse<void> =>
  axios({
    method: 'post',
    url: `${apiUrl}/unregister`,
    data,
  });

export const markAsReturnedDevices = (data: number[]): IResponse<void> =>
  axios({
    method: 'put',
    url: `${apiUrl}/mark-as-returned`,
    data,
  });

export const markAsLostDevices = (data: number[]): IResponse<void> =>
  axios({
    method: 'put',
    url: `${apiUrl}/mark-as-lost`,
    data,
  });

export const uploadDevices = (data: FormData): IResponse<IDevice[]> =>
  axios({
    method: 'post',
    url: `${apiUrl}/upload`,
    data,
  });

export const getDevicesReport = (params?: IDevicesListFilters): IResponse<Blob> =>
  axios({
    method: 'get',
    url: `${apiUrl}/report`,
    params,
    responseType: 'blob',
  });

export const getDeviceHistory = (
  deviceId: number,
  params: IPagedFilters,
): IResponse<IDeviceHistoryLog[]> =>
  axios({
    method: 'get',
    url: `${apiUrl}/${deviceId}/history`,
    params,
  });
