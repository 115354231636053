import { InsuranceType } from '~/services/api/enums';
import { IFormValues } from '~/ui/components/reusable/InsuranceForm/types';

const initialValues: IFormValues = {
  nameId: null,
  groupId: '',
  policyNumber: '',
  type: InsuranceType.Primary,
  plan: null,
  planType: null,
  expiresAt: '',
  isAuthorizationRequired: false,
  authorizationStartDate: null,
  authorizationEndDate: null,
};

export default initialValues;
