import { action } from 'easy-peasy';
import { IActionSetMoreActivityLogsByPatientId } from '../types';

const setMoreActivityLogsByPatientId: IActionSetMoreActivityLogsByPatientId = action(
  (state, payload) => {
    const hasMore = state.paginationByPatientId.itemsPerPage === payload.length;

    state.itemsByPatientId = [...state.itemsByPatientId, ...payload];
    state.paginationByPatientId.page += 1;
    state.paginationByPatientId.hasMore = hasMore;
  },
);

export default setMoreActivityLogsByPatientId;
