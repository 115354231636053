import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { getPaginationPayload } from '~/utils/pagination';
import { INotificationsStore, IThunkGetMoreNotifications } from '../types';

const onGetMoreNotifications: IThunkGetMoreNotifications = thunk(
  // @ts-ignore
  async ({ setMoreNotifications }, payload, { getState }) => {
    const localState = getState() as INotificationsStore;
    const paginationPayload = getPaginationPayload(localState.pagination);

    const notifications = await api.notifications.getList(paginationPayload).then(res => res.data);

    setMoreNotifications(notifications);
  },
);

export default onGetMoreNotifications;
