import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkCreateMedication } from '../types';

// @ts-ignore
const onCreateMedication: IThunkCreateMedication = thunk(async ({ addMedication }, payload) => {
  const { patientId, data } = payload;
  const medication = await api.medications.create(patientId, data).then(res => res.data);
  addMedication(medication);
});

export default onCreateMedication;
