import axios from 'axios';
import { IResponse } from '~/services/api/types';
import {
  IActivityLog,
  IActivityLogsCounters,
  IActivityLogsListFilters,
  IActivityLogsSelectors,
} from './types';

const apiUrl = 'activity-logs';

export const getCounters = (params: IActivityLogsListFilters): IResponse<IActivityLogsCounters> =>
  axios({
    method: 'get',
    url: `${apiUrl}/counters`,
    params,
  });

export const getSelectors = (): IResponse<IActivityLogsSelectors> =>
  axios({
    method: 'get',
    url: `${apiUrl}/selectors`,
  });

export const getList = (params: IActivityLogsListFilters): IResponse<IActivityLog[]> =>
  axios({
    method: 'get',
    url: apiUrl,
    params,
  });

export const getByPatientId = (
  patientId: number,
  params: IActivityLogsListFilters,
): IResponse<IActivityLog[]> =>
  axios({
    method: 'get',
    url: `${apiUrl}/by-patient-id/${patientId}`,
    params,
  });

export const create = (patientId: number, data: IActivityLog): IResponse<IActivityLog> =>
  axios({
    method: 'post',
    url: apiUrl,
    data,
    params: {
      patientId,
    },
  });

export const update = (data: IActivityLog): IResponse<IActivityLog> =>
  axios({
    method: 'put',
    url: apiUrl,
    data,
  });

export const remove = (id: number): IResponse<void> =>
  axios({
    method: 'delete',
    url: `${apiUrl}/${id}`,
  });

export default {};
