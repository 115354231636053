import { useRef } from 'react';
import PublishIcon from '@mui/icons-material/Publish';

import Button from '~/ui/components/common/Button';
import styles from './AddDeviceCsv.module.scss';

interface IProps {
  isCsvAdding: boolean;
  onAddDeviceCsv: (formData: FormData) => void;
}

const AddDeviceCsv = ({ isCsvAdding, onAddDeviceCsv }: IProps) => {
  const fileInputRef = useRef(null);

  const onChange = (file: File) => {
    fileInputRef.current.value = ''; // reset input file

    const formData = new FormData();
    formData.append('file', file);

    onAddDeviceCsv(formData);
  };

  const onCsvClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div>
      <input
        ref={fileInputRef}
        type="file"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        className={styles.hidden}
        onChange={event => {
          const { files } = event.target;
          onChange(files[0]);
        }}
      />
      <Button
        variant="contained"
        color="primary"
        disabled={isCsvAdding}
        isLoading={isCsvAdding}
        startIcon={<PublishIcon />}
        onClick={onCsvClick}
      >
        Add Device CSV
      </Button>
    </div>
  );
};

export default AddDeviceCsv;
