import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkRemoveMobileUser } from '../types';

// @ts-ignore
const onRemoveMobileUser: IThunkRemoveMobileUser = thunk(async ({ removeMobileUser }, id) => {
  await api.mobileUsers.removeMobileUser(id).then(() => {
    removeMobileUser(id);
  });
});

export default onRemoveMobileUser;
