import { useEffect, useState, useRef, useMemo, ReactElement } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';

import api from '~/services/api';
import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import { isoToFormat } from '~/utils/date';
import validate from './form/validate';
import Button from '~/ui/components/common/Button';
import Input from '~/ui/components/inputs/Input';
import Textarea from '~/ui/components/inputs/Textarea';
import Select from '~/ui/components/inputs/Select';
import SelectVirtualized from '~/ui/components/inputs/SelectVirtualized';
import DatePicker from '~/ui/components/inputs/DatePicker';
import RadioGroup from '~/ui/components/inputs/RadioGroup';

import countryOptions from '~/ui/constants/countryOptions';
import stateOptions from '~/ui/constants/stateOptions';
import genderOptions from '~/ui/constants/genderOptions';
import ethnicityOptions from '~/ui/constants/ethnicityOptions';
import homeHealthCareOptions from '~/ui/constants/homeHealthCareOptions';
import { IIdName } from '~/services/api/types';
import { DeviceType, PatientStatus } from '~/services/api/enums';
import styles from './PatientForm.module.scss';
import { IPatient } from '~/services/api/patients/types';
import { IPatientFormValues } from '~/ui/pages/Patient/Dashboard/components/PersonalData/PatientProfile/types';
import { IDeviceSelector } from '~/services/api/devices/types';
import PhoneInputsForm from './PhoneInputsForm';
import PhoneFlagInput from '~/ui/components/inputs/PhoneFlag';

interface IProps {
  initialValues: IPatientFormValues;
  isProcessing: boolean;
  onSubmit: (programId: number, data: IPatient, deviceIds: number[]) => void;
}

const PatientForm = ({ initialValues, isProcessing, onSubmit }: IProps): ReactElement => {
  const isMounted = useRef(false);
  const [programs, setPrograms] = useState<IIdName[]>([]);
  const [managers, setManagers] = useState<IIdName[]>([]);
  const [devices, setDevices] = useState<IDeviceSelector[]>([]);

  const showError = useStoreActions(actions => actions.snackbar.showError);

  const {
    register,
    trigger,
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<IPatientFormValues>({
    resolver: validate,
    defaultValues: initialValues,
  });

  const {
    programId,
    bloodPressureDeviceId,
    weightScaleDeviceId,
    pulseOximeterDeviceId,
    bloodGlucoseDeviceId,
  } = watch();

  const programOptions = useMemo(
    () =>
      programs.map(item => ({
        value: item.id,
        label: item.name,
      })),
    [programs.length],
  );

  const managerOptions = managers.map(item => ({
    value: item.id,
    label: item.name,
  }));

  const defaultOption = {
    value: null as number,
    label: 'Not Selected',
  };
  // TODO:forEach
  const bloodPressureDeviceOptions = devices
    .filter(item => DeviceType.BloodPressure === item.typeId)
    .map(item => ({
      value: item.id,
      label: item.name,
    }));

  const weightScaleDeviceOptions = devices
    .filter(item => DeviceType.WeightScale === item.typeId)
    .map(item => ({
      value: item.id,
      label: item.name,
    }));

  const pulseOximeterDeviceOptions = devices
    .filter(item => DeviceType.PulseOximeter === item.typeId)
    .map(item => ({
      value: item.id,
      label: item.name,
    }));

  const bloodGlucoseDeviceOptions = devices
    .filter(item => DeviceType.BloodGlucose === item.typeId)
    .map(item => ({
      value: item.id,
      label: item.name,
    }));

  const getDropdownOptions = async () => {
    if (!programId) {
      setManagers([]);
      setDevices([]);
      return;
    }

    const selectors = await api.patients.getSelectorsById(0, programId).then(res => res.data);

    setManagers(selectors.users);
    setDevices(selectors.devices);
  };

  const onSubmitForm = (values: IPatientFormValues) => {
    const deviceIds = [
      values.bloodPressureDeviceId,
      values.weightScaleDeviceId,
      values.pulseOximeterDeviceId,
      values.bloodGlucoseDeviceId,
    ].filter(deviceId => !!deviceId);

    const data = {
      id: 0,
      status: PatientStatus.Approved,
      timezone: null,
      ...values,
      dateOfBirth: values.dateOfBirth ? isoToFormat(values.dateOfBirth, 'y-MM-dd') : null,
      healthCareManagers: managers.filter(x => values.healthCareManagerIds.includes(x.id)),
      isHomeHealthCare: Boolean(Number(values.isHomeHealthCare)),
      shippingAddressSameAsHome: Boolean(Number(values.shippingAddressSameAsHome)),
    } as IPatient;

    onSubmit(values.programId, data, deviceIds);
  };

  const onMount = async () => {
    try {
      const selectors = await api.patients.getSelectorsById(0).then(res => res.data);
      setPrograms(selectors.programs);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    getDropdownOptions();
    setValue('healthCareManagerIds', []);
  }, [programId]);

  useEffect(() => {
    onMount();
  }, []);

  const isHomeHealthCare = watch('isHomeHealthCare');

  const homeHealthcareFields = useFieldArray<IPatientFormValues, 'homeHealthcarePeople', 'id'>({
    control,
    name: 'homeHealthcarePeople',
  });

  useEffect(() => {
    if (Number(isHomeHealthCare) === 0) {
      homeHealthcareFields.remove();
    }
  }, [isHomeHealthCare]);

  useEffect(() => {
    // avoid running `validation trigger` on initial render
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    trigger([
      'bloodPressureDeviceId',
      'weightScaleDeviceId',
      'pulseOximeterDeviceId',
      'bloodGlucoseDeviceId',
    ]);
  }, [bloodPressureDeviceId, weightScaleDeviceId, pulseOximeterDeviceId, bloodGlucoseDeviceId]);

  return (
    <div className={styles.patientEnrollment}>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <div className={styles.title}>GENERAL INFO</div>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <SelectVirtualized
              name="programId"
              control={control}
              errors={errors}
              options={programOptions}
              label="Program *"
            />
          </Grid>
          <Grid item xs={4}>
            <SelectVirtualized
              name="healthCareManagerIds"
              control={control}
              errors={errors}
              options={managerOptions}
              isMulti
              label="Healthcare manager *"
            />
          </Grid>
          <Grid item xs={4}>
            <Input name="subjectId" register={register} errors={errors} label="Patient ID *" />
          </Grid>
          <Grid item xs={4}>
            <Input name="firstName" register={register} errors={errors} label="First Name *" />
          </Grid>
          <Grid item xs={4}>
            <Input name="middleName" register={register} errors={errors} label="Middle Name" />
          </Grid>
          <Grid item xs={4}>
            <Input name="lastName" register={register} errors={errors} label="Last Name *" />
          </Grid>
          <Grid item xs={4}>
            <PhoneFlagInput name="phoneNumber" control={control} errors={errors} label="Phone *" />
          </Grid>
          <Grid item xs={4}>
            <DatePicker
              name="dateOfBirth"
              maxDate={new Date()}
              control={control}
              errors={errors}
              label="DOB"
            />
          </Grid>
          <Grid item xs={4}>
            <Select
              name="ethnicity"
              control={control}
              errors={errors}
              options={ethnicityOptions}
              label="Ethnicity"
            />
          </Grid>
          <Grid item xs={4}>
            <RadioGroup
              name="gender"
              control={control}
              errors={errors}
              options={genderOptions}
              label="Gender"
            />
          </Grid>
        </Grid>

        <div className={styles.divider} />
        <div className={styles.title}>LOCATION</div>

        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Select
              name="country"
              control={control}
              errors={errors}
              options={countryOptions}
              label="Country *"
            />
          </Grid>
          <Grid item xs={4}>
            <Select
              name="state"
              control={control}
              errors={errors}
              options={stateOptions}
              label="State *"
            />
          </Grid>
          <Grid item xs={4}>
            <Input name="city" register={register} errors={errors} label="City *" />
          </Grid>
          <Grid item xs={4}>
            <Input name="address" register={register} errors={errors} label="Address *" />
          </Grid>
          <Grid item xs={4}>
            <Input name="zip" register={register} errors={errors} label="Zip *" />
          </Grid>
        </Grid>
        <div className={styles.divider} />
        <div className={styles.title}>DEVICES</div>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <SelectVirtualized
              name="bloodPressureDeviceId"
              control={control}
              errors={errors}
              options={[defaultOption, ...bloodPressureDeviceOptions]}
              label="Blood Pressure *"
            />
          </Grid>
          <Grid item xs={4}>
            <SelectVirtualized
              name="weightScaleDeviceId"
              control={control}
              errors={errors}
              options={[defaultOption, ...weightScaleDeviceOptions]}
              label="Weight Scale *"
            />
          </Grid>
          <Grid item xs={4}>
            <SelectVirtualized
              name="pulseOximeterDeviceId"
              control={control}
              errors={errors}
              options={[defaultOption, ...pulseOximeterDeviceOptions]}
              label="Pulse Oximeter *"
            />
          </Grid>
          <Grid item xs={4}>
            <SelectVirtualized
              name="bloodGlucoseDeviceId"
              control={control}
              errors={errors}
              options={[defaultOption, ...bloodGlucoseDeviceOptions]}
              label="Blood Glucose *"
            />
          </Grid>
        </Grid>

        <div className={styles.divider} />
        <div className={styles.title}>HOME HEALTHCARE</div>

        <div className={styles.isHomeHealthCareRadio}>
          <RadioGroup
            name="isHomeHealthCare"
            control={control}
            errors={errors}
            options={homeHealthCareOptions}
          />
        </div>

        {Number(isHomeHealthCare) === 1 && (
          <PhoneInputsForm
            control={control}
            name="homeHealthcarePeople"
            errors={errors}
            register={register}
            setValue={setValue}
          />
        )}

        <div className={styles.divider} />
        <div className={styles.title}>CARETAKER</div>

        <PhoneInputsForm
          control={control}
          name="caretakers"
          errors={errors}
          register={register}
          setValue={setValue}
        />

        <div className={styles.divider} />
        <div className={styles.title}>COMMENT</div>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Textarea name="comment" register={register} errors={errors} label="Comment" />
          </Grid>
        </Grid>
        <div className={styles.saveBtnWrapper}>
          <Button
            type="submit"
            size="large"
            variant="contained"
            color="primary"
            isLoading={isProcessing}
            disabled={isProcessing}
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

export default PatientForm;
