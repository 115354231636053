import { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@material-ui/core/Typography';

export interface DialogTitleProps {
  children: React.ReactNode;
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    backgroundColor: '#EFF6F9',
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
}));

const ModalTitle = ({ children, onClose }: DialogTitleProps): ReactElement => {
  const classes = useStyles();

  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      <IconButton aria-label="close" onClick={onClose} className={classes.closeButton}>
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
};

export default ModalTitle;
