import { object, string, number, array, boolean, ref } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { password, phoneNumber } from '~/ui/constants/validation';
import { UserRole } from '~/types';

const validationSchema = object().shape({
  roleId: string().nullable().required('Field is required'),
  firstName: string().max(100, 'Max length - 100 symbols').required('Field is required'),
  lastName: string().max(100, 'Max length - 100 symbols').required('Field is required'),
  email: string()
    .max(100, 'Max length - 100 symbols')
    .email('Email must be valid')
    .required('Field is required'),
  phone: string().when('isMobileEnabled', {
    is: true,
    then: phoneNumber.required('Field is required'),
  }),
  shouldEditPassword: boolean(),
  password: string().when('shouldEditPassword', {
    is: true,
    then: password,
  }),
  passwordConfirm: string().when('shouldEditPassword', {
    is: true,
    then: string()
      .oneOf([ref('password'), null], 'The passwords must match')
      .max(100, 'Max - 100 characters'),
  }),
  programIds: array()
    .of(number())
    .when('roleId', {
      is: (val: UserRole) => val !== UserRole.SuperAdmin,
      then: array().of(number()).min(1, 'Field is required'),
    }),
  permissions: array().of(string()),
});

export default yupResolver(validationSchema);
