import { useState, ReactElement } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';

import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import { isoToFormat } from '~/utils/date';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import EditUser from '../../popups/EditUser';

import { IUserList } from '~/services/api/users/types';
import styles from './Item.module.scss';

interface IProps {
  user: IUserList;
}

const Item = ({ user }: IProps): ReactElement => {
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  const onRemoveUser = useStoreActions(actions => actions.users.onRemoveUser);
  const { showNotify, showError } = useStoreActions(actions => actions.snackbar);

  const removeUser = async () => {
    setIsRemoving(true);

    try {
      await onRemoveUser(user.id);
      showNotify('User terminated');
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsRemoving(false);
    }
  };

  return (
    <>
      <TableRow>
        <TableCell className={styles.cell}>{user.fullName}</TableCell>
        <TableCell>{user.email}</TableCell>
        <TableCell>
          {user.lastLoginAt ? isoToFormat(user.lastLoginAt, 'dd MMM y H:mm') : '-'}
        </TableCell>
        <TableCell width={80}>
          <IconButton className={styles.icon} title="Edit" onClick={() => setShowEditPopup(true)}>
            <CreateIcon />
          </IconButton>
        </TableCell>
        <TableCell align="center" width={120} className={styles.removeCell}>
          <IconButton
            className={styles.icon}
            title="Remove"
            onClick={() => setShowRemoveConfirm(true)}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      {showEditPopup && <EditUser userId={user.id} onClose={() => setShowEditPopup(false)} />}
      {showRemoveConfirm && (
        <ConfirmModal
          title="Remove"
          description={`Are you sure you want to terminate user ${user.fullName}?`}
          isLoading={isRemoving}
          onClose={() => setShowRemoveConfirm(false)}
          onConfirm={removeUser}
        />
      )}
    </>
  );
};

export default Item;
