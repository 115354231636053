import { ReactElement, useState, useEffect } from 'react';

import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import Header from './Header';
import Table from './Table';
import Modal from '~/ui/components/common/Modal';
import Loader from '~/ui/components/common/Loader';

import { Order } from '~/types';
import { IPopupType } from './types';
import { IStatusPatientsFilters } from '~/services/api/patients/types';
import { IPagePermissions } from '../../../types';
import styles from './PatientsPopup.module.scss';
import { PatientStatus } from '~/services/api/enums';

interface IProps {
  type?: IPopupType;
  permissions: IPagePermissions;
  status: PatientStatus;
  sort?: string;
  order?: Order;
  subTitle: string;
  title: string;
  onClose: () => void;
}

const PatientsPopup = ({
  type,
  permissions,
  status,
  sort,
  order,
  title,
  subTitle,
  onClose,
}: IProps): ReactElement => {
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<IStatusPatientsFilters>(null);

  const { pagination, items } = useStoreState(state => state.statusPatients);
  const { onGetStatusPatients, onGetMoreStatusPatients } = useStoreActions(
    actions => actions.statusPatients,
  );
  const showError = useStoreActions(actions => actions.snackbar.showError);

  const payload = { status, orderBy: sort, isDescending: order === Order.Desc, ...filters };

  const onMount = async () => {
    setLoading(true);
    try {
      await onGetStatusPatients(payload);
      setLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const onLoadMore = () => {
    onGetMoreStatusPatients(payload);
  };

  useEffect(() => {
    onMount();
  }, [filters]);

  return (
    <Modal open onClose={onClose} className={styles.modal} maxWidth="lg" title={title}>
      <div className={styles.container}>
        <Header onSubmit={setFilters} />
        <h3 className={styles.subTitle}>{subTitle}</h3>
        {loading ? (
          <Loader />
        ) : (
          <Table
            type={type}
            patients={items}
            permissions={permissions}
            onLoadMore={onLoadMore}
            pagination={pagination}
          />
        )}
      </div>
    </Modal>
  );
};

export default PatientsPopup;
