import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkDeleteInsurance } from '../types';

// @ts-ignore
const onDeleteInsurance: IThunkDeleteInsurance = thunk(async ({ deleteInsurance }, id) => {
  await api.insurances.remove(id).then(res => res.data);
  deleteInsurance(id);
});

export default onDeleteInsurance;
