export const isTelephoneConversationOptions = [
  {
    value: '',
    label: 'All',
  },
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
  },
];

export default {};
