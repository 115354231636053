import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { getClientId, setTokens } from '~/services/auth';
import { IThunkVerifyCode } from '../types';

const onVerifyCode: IThunkVerifyCode = thunk(
  // @ts-ignore
  async ({ setAuthorized, setIsMfaRequired }, payload) => {
    const clientId = getClientId();

    const data = await api.auth
      .verifyCode({
        ...payload,
        clientId,
      })
      .then(response => response.data);

    setTokens(data);

    setAuthorized();
    setIsMfaRequired(false);
  },
);

export default onVerifyCode;
