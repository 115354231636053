import axios from 'axios';
import { IResponse } from '~/services/api/types';
import { IMedication, IMedicationsListFilters } from './types';

const apiUrl = 'medications';

export const getList = (params: IMedicationsListFilters): IResponse<IMedication[]> =>
  axios({
    method: 'get',
    url: apiUrl,
    params,
  });

export const create = (patientId: number, data: IMedication): IResponse<IMedication> =>
  axios({
    method: 'post',
    url: apiUrl,
    data,
    params: {
      patientId,
    },
  });

export const update = (data: IMedication): IResponse<IMedication> =>
  axios({
    method: 'put',
    url: apiUrl,
    data,
  });

export const remove = (id: number): IResponse<void> =>
  axios({
    method: 'delete',
    url: `${apiUrl}/${id}`,
  });
