import { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';

import Input from '~/ui/components/inputs/Input';
import Textarea from '~/ui/components/inputs/Textarea';
import Select from '~/ui/components/inputs/Select';
import Divider from '../Divider';

import { relationshipOptions } from '~/ui/constants';
import PhoneFlagInput from '~/ui/components/inputs/PhoneFlag';

const EmergencyContact = (): ReactElement => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Input
            name="emergencyContactFirstName"
            register={register}
            errors={errors}
            label="First Name"
          />
        </Grid>
        <Grid item xs={4}>
          <Input
            name="emergencyContactLastName"
            register={register}
            errors={errors}
            label="Last Name"
          />
        </Grid>
        <Grid item xs={4}>
          <PhoneFlagInput
            name="emergencyContactPhone"
            control={control}
            errors={errors}
            label="Phone"
          />
        </Grid>
        <Grid item xs={4}>
          <Input name="emergencyContactEmail" register={register} errors={errors} label="Email" />
        </Grid>
        <Grid item xs={4}>
          <Select
            name="emergencyContactRelation"
            control={control}
            errors={errors}
            options={relationshipOptions}
            label="Relationship"
          />
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Textarea name="comment" register={register} errors={errors} label="Comment" />
        </Grid>
      </Grid>
    </>
  );
};

export default EmergencyContact;
