import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { getPaginationPayload } from '~/utils/pagination';
import { IThunkGetMobileUsers, IMobileUserTypesStore } from '../types';

const onGetMobileUsers: IThunkGetMobileUsers = thunk(
  // @ts-ignore
  async ({ setMobileUsers, reset }, payload, { getState }) => {
    reset();
    const localState = getState() as IMobileUserTypesStore;
    const paginationPayload = getPaginationPayload(localState.itemsPagination);

    const mobileUserTypes = await api.mobileUsers
      .getList({ ...payload, ...paginationPayload })
      .then(res => res.data);

    setMobileUsers(mobileUserTypes);
  },
);

export default onGetMobileUsers;
