import { IUsersStore } from './types';

const initStore: IUsersStore = {
  items: [],
  itemsPagination: {
    page: 0,
    itemsPerPage: 20,
    hasMore: false,
  },
  item: null,
  userAssociatedPrograms: [],
};

export default initStore;
