import DoneAll from '@mui/icons-material/DoneAll';
import { format } from 'date-fns';
import { Checkbox } from '@material-ui/core';
import classNames from 'classnames';
import { INotification } from '~/services/api/notifications/types';
import styles from './NotificationItem.module.scss';
import { getDateFormat } from './utils';

interface IProps {
  item: INotification;
  isSelecting: boolean;
  checked: boolean;
  onClick: (item: INotification) => void;
  onCheckboxChange: (item: INotification) => void;
}

const NotificationItem = ({ item, onClick, checked, isSelecting, onCheckboxChange }: IProps) => {
  const handleClick = () => {
    onClick(item);
  };

  return (
    <div className={classNames(styles.Item, { [styles.IsSelecting]: isSelecting })}>
      {isSelecting && (
        <Checkbox checked={checked} color="primary" onChange={() => onCheckboxChange(item)} />
      )}
      <div role="presentation" onClick={handleClick} className={styles.Notification}>
        <div className={`${styles.Header} ${item.isRead ? `${styles.IsRead}` : ''}`}>
          <div className={styles.HeaderTitle}>
            {item.title}
            {!item.isRead && <div className={styles.IsReadCircle} />}
          </div>
          <span>{format(new Date(item.createdAt), getDateFormat(item.createdAt))}</span>
        </div>
        <div className={classNames(styles.Body, { [styles.IsRead]: item.isRead })}>
          <span>{item.message}</span>
        </div>
        <div className={classNames(styles.Bottom, { [styles.IsRead]: item.isRead })}>
          <span className={styles.BottomRead}>Read</span>
          <span className={styles.BottomReadIcon}>
            <DoneAll fontSize="inherit" />
          </span>
        </div>
        <div className={styles.Divider} />
      </div>
    </div>
  );
};

export default NotificationItem;
