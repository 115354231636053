import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import TableContainer from '@material-ui/core/TableContainer';
import MUITable from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import InfiniteScroll from 'react-infinite-scroll-component';

import { isoToFormat } from '~/utils/date';
import { IPagination } from '~/utils/pagination';
import Loader from '~/ui/components/common/Loader';
import NoDataFound from '~/ui/components/reusable/NoDataFound';

import { PATIENT_DASHBOARD } from '~/ui/constants/paths';
import { IStatusPatient } from '~/services/api/patients/types';
import { IPagePermissions } from '../../../../types';
import { IPopupType } from '../types';
import styles from './Table.module.scss';

interface IProps {
  type?: IPopupType;
  patients: IStatusPatient[];
  permissions: IPagePermissions;
  onLoadMore: () => void;
  pagination: IPagination;
}

const Table = ({ type, patients, permissions, onLoadMore, pagination }: IProps): ReactElement => {
  const isDischargedPopup = type === 'Discharged';

  return (
    <TableContainer className={styles.tableContainer} id="scrollableDiv">
      <InfiniteScroll
        next={onLoadMore}
        hasMore={pagination.hasMore}
        loader={<Loader />}
        dataLength={patients.length}
        scrollableTarget="scrollableDiv"
      >
        <MUITable>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell className={styles.column}>Patient ID</TableCell>
              <TableCell align="center" className={styles.column}>
                Enrollment Date
              </TableCell>
              {isDischargedPopup && (
                <TableCell align="center" className={styles.column}>
                  Discharge Date
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {patients.length ? (
              patients.map(patient => {
                const patientName = patient.fullName.toUpperCase();

                return (
                  <TableRow key={patient.id}>
                    <TableCell className={styles.cell}>
                      {permissions.canManagePatients ? (
                        <Link
                          to={PATIENT_DASHBOARD.replace(':id', String(patient.id))}
                          className={styles.link}
                        >
                          {patientName}
                        </Link>
                      ) : (
                        patientName
                      )}
                    </TableCell>
                    <TableCell>{patient.subjectId}</TableCell>
                    <TableCell align="center">
                      {isoToFormat(patient.createdAt, 'dd MMM y')}
                    </TableCell>
                    {isDischargedPopup && (
                      <TableCell align="center">
                        {isoToFormat(patient.dischargedAt, 'dd MMM y')}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={4}>
                  <NoDataFound />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </MUITable>
      </InfiniteScroll>
    </TableContainer>
  );
};

export default Table;
