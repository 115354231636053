import { ReactElement } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import TableContainer from '@material-ui/core/TableContainer';
import MuiTable from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

import { useStoreState, useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import Loader from '~/ui/components/common/Loader';
import NoDataFound from '~/ui/components/reusable/NoDataFound';

import styles from './Table.module.scss';

interface IProps {
  fullName: string;
  userId: number;
  assignPatient: (str: number) => void;
  assignedPatients: number[];
  onSaveAssignedPatients: () => void;
}

const Table = ({
  fullName,
  userId,
  assignPatient,
  assignedPatients,
  onSaveAssignedPatients,
}: IProps): ReactElement => {
  const patients = useStoreState(state => state.patients.items);
  const pagination = useStoreState(state => state.patients.pagination);
  const showError = useStoreActions(actions => actions.snackbar.showError);

  const onGetMorePatients = useStoreActions(actions => actions.patients.onGetMorePatients);

  const onLoadMore = async () => {
    try {
      await onGetMorePatients({ fullName, notAssignedToUserId: userId });
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const tableCells = ['Patient', 'Patient Id', ''];

  return (
    <div className={styles.container}>
      <TableContainer className={styles.tableContainer} id="scrollableDiv">
        <InfiniteScroll
          dataLength={patients.length}
          next={onLoadMore}
          hasMore={pagination.hasMore}
          loader={<Loader />}
          scrollableTarget="scrollableDiv"
        >
          <MuiTable>
            <TableHead>
              <TableRow>
                {tableCells.map((cellName, id) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <TableCell key={id}>{cellName}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {patients.length ? (
                patients.map(patient => {
                  const checked = assignedPatients.includes(patient.id);
                  return (
                    <TableRow key={patient.id}>
                      <TableCell>{patient.fullName}</TableCell>
                      <TableCell>{patient.subjectId}</TableCell>
                      <TableCell>
                        <Checkbox
                          color="primary"
                          checked={checked}
                          onChange={() => assignPatient(patient.id)}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={3}>
                    <NoDataFound />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </MuiTable>
        </InfiniteScroll>
      </TableContainer>
      <div className={styles.buttonContainer}>
        <Button
          color="primary"
          variant="contained"
          disabled={!assignedPatients.length}
          onClick={onSaveAssignedPatients}
        >
          Assign
        </Button>
      </div>
    </div>
  );
};

export default Table;
