import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkCreatePatientNotificationRule } from '../types';

const onCreatePatientNotificationRule: IThunkCreatePatientNotificationRule = thunk(
  // @ts-ignore
  async ({ addPatientNotificationRule }, payload) => {
    const { patientId, data } = payload;
    const patientNotificationRule = await api.patientNotificationRules
      .create(patientId, data)
      .then(res => res.data);
    addPatientNotificationRule(patientNotificationRule);
  },
);

export default onCreatePatientNotificationRule;
