import { IMobileUserTypesStore } from './types';

const initStore: IMobileUserTypesStore = {
  items: [],
  itemsPagination: {
    page: 0,
    itemsPerPage: 20,
    hasMore: false,
  },
};

export default initStore;
