import { action } from 'easy-peasy';
import { IActionUpdateCarePlanHospitalization } from '../types';

const updateCarePlanHospitalization: IActionUpdateCarePlanHospitalization = action(
  (state, payload) => {
    state.items = state.items
      .map(item => {
        if (item.id !== payload.id) return item;
        return {
          ...item,
          ...payload,
        };
      })
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  },
);

export default updateCarePlanHospitalization;
