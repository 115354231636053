import { ReactElement } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import styles from './Analysis.module.scss';
import { IAverageData } from '../types';

interface IProps {
  averageData: IAverageData;
}

const Analysis = ({ averageData }: IProps): ReactElement => (
  <TableContainer>
    <Table className={styles.table}>
      <TableHead>
        <TableRow>
          <TableCell>Slot</TableCell>
          <TableCell>Monday</TableCell>
          <TableCell>Tuesday</TableCell>
          <TableCell>Wednesday</TableCell>
          <TableCell>Thursday</TableCell>
          <TableCell>Friday</TableCell>
          <TableCell>Saturday</TableCell>
          <TableCell>Sunday</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {averageData.dayParts.map(dayPart => (
          <TableRow key={dayPart.dayPart}>
            <TableCell>{dayPart.dayPart}</TableCell>
            {dayPart.days.map(day => (
              <TableCell key={day.day}>{day.value}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default Analysis;
