import { IPatientsListFilters } from '~/services/api/patients/types';
import { PatientStatus } from '~/services/api/enums';
import { getCurrentMonthFirstDayDate, setEndOfDay } from '~/utils/date';

const getInitialValues = (): IPatientsListFilters => ({
  startDate: getCurrentMonthFirstDayDate(),
  endDate: setEndOfDay(new Date()),
  programId: '',
  fullName: '',
  healthCareManagerIds: [],
  status: PatientStatus.Active,
  isHomeHealthCare: '',
});

export default getInitialValues;
