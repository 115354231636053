import { DeviceType } from '~/services/api/enums';

export enum DayColor {
  Monday = '#FF2A68',
  Tuesday = '#5857D6',
  Wednesday = '#0AD319',
  Thursday = '#FF1301',
  Friday = '#2B2B2B',
  Saturday = '#8E8E93',
  Sunday = '#5857D6',
}

export enum ChartColor {
  Blue = '#1E89C2',
  Green = '#33AD90',
  Red = '#F45760',
  Yellow = '#FFCE70',
}

export const labels = {
  systolic: 'Systolic',
  diastolic: 'Diastolic',
  heartRate: 'Heart Rate',
  weight: 'Weight',
  previousGain: 'Previous Gain',
  previousLoss: 'Previous Loss',
  dailyGain: 'Daily Gain',
  dailyLoss: 'Daily Loss',
  weeklyGain: 'Weekly Gain',
  weeklyLoss: 'Weekly Loss',
  spo2: 'SPO2',
  bloodGlucose: 'Blood Glucose',
};

export const categories = {
  [DeviceType.BloodPressure]: [labels.systolic, labels.diastolic, labels.heartRate],
  [DeviceType.WeightScale]: [
    labels.weight,
    labels.previousGain,
    labels.previousLoss,
    labels.dailyGain,
    labels.dailyLoss,
    labels.weeklyGain,
    labels.weeklyLoss,
  ],
  [DeviceType.PulseOximeter]: [labels.spo2, labels.heartRate],
  [DeviceType.BloodGlucose]: [labels.bloodGlucose],
};

export const analysisCategories = {
  [DeviceType.BloodPressure]: [labels.systolic, labels.diastolic, labels.heartRate],
  [DeviceType.WeightScale]: [labels.weight],
  [DeviceType.PulseOximeter]: [labels.spo2, labels.heartRate],
  [DeviceType.BloodGlucose]: [labels.bloodGlucose],
};

export default {};
