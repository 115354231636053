import { ReactElement } from 'react';

import Loader from '~/ui/components/common/Loader';
import NoDataFound from '~/ui/components/reusable/NoDataFound';
import Item from './Item';

import { IMedication } from '~/services/api/medications/types';

interface IProps {
  isLoading: boolean;
  medications: IMedication[];
}

const List = ({ isLoading, medications }: IProps): ReactElement => {
  if (isLoading) return <Loader />;

  return (
    <>
      {medications.length ? (
        medications.map(item => <Item key={item.id} medication={item} />)
      ) : (
        <NoDataFound />
      )}
    </>
  );
};

export default List;
