import { FormProvider, useForm } from 'react-hook-form';

import { getInitialValues, validate } from './form';
import AutoSubmit from '~/ui/components/inputs/AutoSubmit';
import Textarea from '~/ui/components/inputs/Textarea';

import { IFormValues } from './types';
import styles from './EditForm.module.scss';

interface IProps {
  quickNotes: string;
  onSubmit: (args: IFormValues) => void;
}

const EditForm = ({ quickNotes, onSubmit }: IProps) => {
  const initialValues = getInitialValues(quickNotes);

  const formMethods = useForm<IFormValues>({
    resolver: validate,
    defaultValues: initialValues,
  });

  const {
    register,
    formState: { errors },
  } = formMethods;

  return (
    <FormProvider {...formMethods}>
      <form className={styles.form}>
        <Textarea
          name="quickNotes"
          register={register}
          errors={errors}
          label="Quick Notes"
          className={styles.textarea}
        />
        <AutoSubmit debounce={1000} initialValues={initialValues} onSubmit={onSubmit} />
      </form>
    </FormProvider>
  );
};

export default EditForm;
