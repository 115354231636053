import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { getPaginationPayload } from '~/utils/pagination';
import { IThunkGetDevices, IDevicesStore } from '../types';

const onGetDevices: IThunkGetDevices = thunk(
  // @ts-ignore
  async ({ setDevices, reset }, payload, { getState }) => {
    reset();
    const localState = getState() as IDevicesStore;
    const paginationPayload = getPaginationPayload(localState.pagination);

    const devices = await api.devices
      .getDevices({ ...payload, ...paginationPayload })
      .then(res => res.data);

    setDevices(devices);
  },
);

export default onGetDevices;
