import initStore from './initStore';
import actions from './actions';
import thunks from './thunks';

import { IStatusPatientsModel } from './types';

const statusPatients: IStatusPatientsModel = {
  ...initStore,
  ...actions,
  ...thunks,
};

export default statusPatients;
