import { useState } from 'react';

import { useStoreState } from '~/store/hooks';
import EditForm from './EditForm';
import Popup from './Popup';

import { IFormValues } from './EditForm/types';
import styles from './ContentPopup.module.scss';

interface IProps {
  onSave: (values: IFormValues) => void;
  onClose: () => void;
}

const ContentPopup = ({ onSave, onClose }: IProps) => {
  const [isEdit, setIsEdit] = useState(false);

  const { quickNotes } = useStoreState(state => state.patient.info);

  return (
    <Popup onClose={onClose}>
      {isEdit ? (
        <EditForm quickNotes={quickNotes} onSubmit={onSave} />
      ) : (
        <div className={styles.preview} role="presentation" onClick={() => setIsEdit(true)}>
          <div className={styles.previewInner}>{quickNotes}</div>
        </div>
      )}
    </Popup>
  );
};

export default ContentPopup;
