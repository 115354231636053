import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { getPaginationPayload } from '~/utils/pagination';
import { IThunkGetActivityLogsByPatientId, IActivityLogsStore } from '../types';

const onGetActivityLogsByPatientId: IThunkGetActivityLogsByPatientId = thunk(
  // @ts-ignore
  async ({ setActivityLogsByPatientId, resetByPatientId }, { patientId, params }, { getState }) => {
    resetByPatientId();

    const localState = getState() as IActivityLogsStore;

    const paginationPayload = getPaginationPayload(localState.paginationByPatientId);

    const { data } = await api.activityLogs.getByPatientId(patientId, {
      ...params,
      ...paginationPayload,
    });

    setActivityLogsByPatientId(data);
  },
);

export default onGetActivityLogsByPatientId;
