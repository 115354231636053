import setPrograms from './setPrograms';
import setMorePrograms from './setMorePrograms';
import setProgram from './setProgram';
import updateProgram from './updateProgram';
import removeProgram from './removeProgram';
import reset from './reset';

export default {
  setPrograms,
  setMorePrograms,
  setProgram,
  updateProgram,
  removeProgram,
  reset,
};
