import initStore from './initStore';
import actions from './actions';

import { ISnackbarModel } from './types';

const snackbar: ISnackbarModel = {
  ...initStore,
  ...actions,
};

export default snackbar;
