import { ReactElement } from 'react';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';

import Rule from './Rule';

import styles from './NotificationRules.module.scss';
import { INotificationRule } from '~/services/api/notificationRules/types';

interface IProps {
  showBorder?: boolean;
  rules: INotificationRule[];
  canManage?: boolean;
  showDescriptionHint?: boolean;
  onRemove: (index: number) => void;
}

const NotificationRules = ({
  showBorder = true,
  rules,
  canManage = true,
  showDescriptionHint = false,
  onRemove,
}: IProps): ReactElement => (
  <div className={showBorder ? styles.notificationRules : ''}>
    <div className={styles.title}>
      <span>Notification Rules</span>
      {showDescriptionHint && (
        <span className={styles.hint}>
          (Patient notification rules supersede the program notification rules)
        </span>
      )}
    </div>
    {rules.length > 0 && (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Reading Type</TableCell>
              <TableCell>Condition</TableCell>
              <TableCell>Level 1</TableCell>
              <TableCell>Level 2</TableCell>
              <TableCell>Level 3</TableCell>
              {canManage && (
                <TableCell align="center" width={80}>
                  Remove
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rules.map(item => (
              <Rule key={item.id} rule={item} canManage={canManage} onRemove={onRemove} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )}
  </div>
);

export default NotificationRules;
