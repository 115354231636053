import onGetUsers from './onGetUsers';
import onGetMoreUsers from './onGetMoreUsers';
import onGetUser from './onGetUser';
import onUpdateUser from './onUpdateUser';
import onRemoveUser from './onRemoveUser';
import onGetUserAssociatedPrograms from './onGetUserAssociatedPrograms';
import onRemovePrograms from './onRemovePrograms';
import onAddPrograms from './onAddPrograms';
import onAddPatients from './onAddPatients';
import onRemovePatients from './onRemovePatients';

export default {
  onGetUsers,
  onGetMoreUsers,
  onGetUser,
  onUpdateUser,
  onRemoveUser,
  onGetUserAssociatedPrograms,
  onRemovePrograms,
  onAddPrograms,
  onAddPatients,
  onRemovePatients,
};
