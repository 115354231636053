import { useState } from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';

import applyCodeCreateValues from './form/applyCodeCreateValues';
import Button from '~/ui/components/common/Button';
import NoDataFound from '~/ui/components/reusable/NoDataFound';
import Icd10CodeModal from '../popups/Icd10CodeModal';
import Item from './Item';

import { ICarePlanIcd10Code } from '~/services/api/carePlanIcd10Codes/types';
import { IPagePermissions } from '../../types';
import styles from './Icd10Codes.module.scss';

interface IProps {
  patientId: number;
  items: ICarePlanIcd10Code[];
  permissions: IPagePermissions;
}

const Icd10Codes = ({ patientId, items, permissions }: IProps) => {
  const [showCreate, setShowCreate] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.headerWrapper}>
        <div className={styles.title}>ICD 10 Codes</div>
        {permissions.canManage && (
          <Button variant="contained" color="primary" onClick={() => setShowCreate(true)}>
            + Add New
          </Button>
        )}
      </div>
      <div className={styles.tableContainer}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Code</TableCell>
                <TableCell>Description</TableCell>
                {permissions.canManage && <TableCell />}
              </TableRow>
            </TableHead>
            <TableBody>
              {items.length ? (
                items.map(item => (
                  <Item key={item.id} patientId={patientId} item={item} permissions={permissions} />
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4}>
                    <NoDataFound />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {showCreate && (
        <Icd10CodeModal
          patientId={patientId}
          applyCodeInitialValues={applyCodeCreateValues}
          onClose={() => setShowCreate(false)}
        />
      )}
    </div>
  );
};

export default Icd10Codes;
