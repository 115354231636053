import { useEffect, useState, useMemo, ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';
import Grid from '@material-ui/core/Grid';

import api from '~/services/api';
import { useStoreState, useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import { getUserTypeOptions } from './helper';
import validate from './form/validate';
import Button from '~/ui/components/common/Button';
import Input from '~/ui/components/inputs/Input';
import Select from '~/ui/components/inputs/Select';
import SelectVirtualized from '~/ui/components/inputs/SelectVirtualized';
import CheckboxGroup from '~/ui/components/inputs/CheckboxGroup';
import Checkbox from '~/ui/components/inputs/Checkbox';

import { userPermissionOptions } from '~/ui/constants/user';
import { UserRole } from '~/types';
import { IUserFormValues } from './types';
import styles from './UserForm.module.scss';
import { IIdName } from '~/services/api/types';
import { IOption } from '../../inputs/Select/types';
import PhoneFlagInput from '../../inputs/PhoneFlag';

interface IProps {
  initialValues: IUserFormValues;
  mode?: 'create' | 'edit';
  isProcessing: boolean;
  onSubmit: (data: IUserFormValues) => void;
}

const UserForm = ({
  initialValues,
  mode = 'create',
  isProcessing,
  onSubmit,
}: IProps): ReactElement => {
  const [programs, setPrograms] = useState<IIdName[]>([]);

  const userRole = useStoreState(state => state.auth.currentUser?.roleId);
  const showError = useStoreActions(actions => actions.snackbar.showError);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<IUserFormValues>({
    resolver: validate,
    defaultValues: initialValues,
  });

  const [shouldEditPassword, formUserRoleId, isMobileEnabled] = watch([
    'shouldEditPassword',
    'roleId',
    'isMobileEnabled',
  ]);

  const permissions = {
    // Permissions block is visible if:
    // 1) creating new user with userRole === HealthManager
    // 2) creator userRole !== HealthManager
    showPermissionsBlock:
      formUserRoleId === UserRole.HealthManager && userRole !== UserRole.HealthManager,
    showPasswordBlock: mode === 'create' || userRole !== UserRole.HealthManager,
    showMfaBlock: formUserRoleId !== UserRole.SystemAdmin && userRole === UserRole.SystemAdmin,
    showMobileBlock: formUserRoleId === UserRole.HealthManager,
    showReceiveSystemNotificationsBlock:
      [UserRole.ProgramAdmin, UserRole.HealthManager].includes(formUserRoleId) &&
      [UserRole.SystemAdmin, UserRole.SuperAdmin].includes(userRole),
  };

  const programOptions = useMemo(
    () =>
      programs.map(item => ({
        value: item.id,
        label: item.name,
      })),
    [programs.length],
  );

  const userTypeOptions = getUserTypeOptions(userRole);

  const userStatusOptions: IOption[] = [
    { value: true, label: 'Active' },
    { value: false, label: 'Inactive' },
  ];

  const onMount = async () => {
    try {
      const selectors = await api.users.getSelectorsById(0).then(res => res.data);
      setPrograms(selectors.programs);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  return (
    <div className={styles.addUser}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classnames(styles.title, styles.generalInfoTitle)}>GENERAL INFO</div>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Select
              name="roleId"
              control={control}
              errors={errors}
              options={userTypeOptions}
              label="User Type *"
            />
          </Grid>
          <Grid item xs={6}>
            {formUserRoleId !== UserRole.SuperAdmin && (
              <SelectVirtualized
                name="programIds"
                control={control}
                errors={errors}
                options={programOptions}
                isMulti
                label="Program *"
              />
            )}
          </Grid>
          <Grid item xs={6}>
            <Input name="firstName" register={register} errors={errors} label="First Name *" />
          </Grid>
          <Grid item xs={6}>
            <Input name="lastName" register={register} errors={errors} label="Last Name *" />
          </Grid>
          <Grid item xs={6}>
            <Input name="email" register={register} errors={errors} label="Login Email *" />
          </Grid>
          <Grid item xs={6}>
            {formUserRoleId !== UserRole.SuperAdmin && (
              <Select
                name="isActive"
                control={control}
                errors={errors}
                options={userStatusOptions}
                label="Status *"
              />
            )}
          </Grid>
          {permissions.showPasswordBlock && (
            <>
              {mode === 'edit' && (
                <Grid item xs={12}>
                  <Checkbox
                    name="shouldEditPassword"
                    control={control}
                    errors={errors}
                    label="Update password"
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                <Input
                  type="password"
                  name="password"
                  register={register}
                  errors={errors}
                  disabled={!shouldEditPassword}
                  label="Password *"
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  type="password"
                  name="passwordConfirm"
                  register={register}
                  errors={errors}
                  disabled={!shouldEditPassword}
                  label="Confirm Password *"
                />
              </Grid>
            </>
          )}
          {(permissions.showMfaBlock || permissions.showMobileBlock) && (
            <>
              <Grid item xs={6}>
                {permissions.showMfaBlock && (
                  <Checkbox
                    name="isMfaEnabled"
                    control={control}
                    errors={errors}
                    label="Multi-factor authentication"
                  />
                )}
                {permissions.showMobileBlock && (
                  <Checkbox
                    name="isMobileEnabled"
                    control={control}
                    errors={errors}
                    label="Mobile app clinician mode"
                  />
                )}
              </Grid>
              {permissions.showMobileBlock && isMobileEnabled && (
                <Grid container item xs={6} spacing={1}>
                  <Grid item xs>
                    <PhoneFlagInput
                      name="phone"
                      control={control}
                      errors={errors}
                      label="Phone *"
                    />
                  </Grid>
                  <Grid item>
                    <Checkbox
                      name="hasAgreed"
                      control={control}
                      errors={errors}
                      label="T&C/HIPAA"
                      disabled
                    />
                  </Grid>
                </Grid>
              )}
            </>
          )}
          {permissions.showReceiveSystemNotificationsBlock && (
            <Grid item xs={12}>
              <div className={classnames(styles.divider, styles.notificationsDivider)} />
              <div className={styles.title}>SYSTEM NOTIFICATIONS</div>
              <Checkbox
                name="receiveAbnormalReadingNotifications"
                control={control}
                errors={errors}
                label="Abnormal readings"
              />
              <Checkbox
                name="receiveMissedReadingNotifications"
                control={control}
                errors={errors}
                label="Missed readings"
              />
            </Grid>
          )}
        </Grid>
        {permissions.showPermissionsBlock && (
          <>
            <div className={styles.divider} />
            <div className={styles.title}>PERMISSIONS</div>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <CheckboxGroup
                  name="permissions"
                  control={control}
                  errors={errors}
                  checkboxOptions={userPermissionOptions}
                />
              </Grid>
            </Grid>
          </>
        )}
        <div className={styles.saveBtnWrapper}>
          <Button
            type="submit"
            size="large"
            variant="contained"
            color="primary"
            isLoading={isProcessing}
            disabled={isProcessing}
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

export default UserForm;
