import { ICarePlanHospitalization } from '~/services/api/carePlanHospitalizations/types';
import { IFormValues } from '../../popups/HospitalizationModal/form/types';

const getEditValues = (hospitalization: ICarePlanHospitalization): IFormValues => {
  const date = new Date(hospitalization.date);

  return {
    reason: hospitalization.reason,
    date: new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()).toISOString(),
  };
};

export default getEditValues;
