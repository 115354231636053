import { ReactElement } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import MUITable from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { isoToFormat } from '~/utils/date';
import DeviceStatus from '~/ui/components/reusable/DeviceStatus';
import NoDataFound from '~/ui/components/reusable/NoDataFound';
import { IPatientInfo } from '~/services/api/patients/types';
import styles from './Table.module.scss';
import { IPatientDevice } from '~/services/api/devices/types';

interface IProps {
  patient: IPatientInfo;
  devices: IPatientDevice[];
}

const Table = ({ patient, devices }: IProps): ReactElement => (
  <div className={styles.container}>
    <h4 className={styles.tableTopHeader}>Devices</h4>
    <div className={styles.tableContainer}>
      <TableContainer>
        <MUITable>
          <TableHead>
            <TableRow>
              <TableCell>Device</TableCell>
              <TableCell>Model</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>IMEI</TableCell>
              <TableCell align="center">Configuration</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {devices.length ? (
              devices.map(device => (
                <TableRow key={device.id}>
                  <TableCell>{device.typeName}</TableCell>
                  <TableCell>{device.model}</TableCell>
                  <TableCell>{device.statusName}</TableCell>
                  <TableCell>{`${isoToFormat(device.registeredAt, 'dd MMM y H:mm')} ${
                    patient.timezone
                  }`}</TableCell>
                  <TableCell>{device.imei}</TableCell>
                  <TableCell align="center">
                    <DeviceStatus device={device} timezone={patient.timezone} />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  <NoDataFound />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </MUITable>
      </TableContainer>
    </div>
  </div>
);

export default Table;
