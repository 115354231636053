import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { getPaginationPayload } from '~/utils/pagination';
import { IThunkGetMedications, IMedicationsStore } from '../types';

const onGetMedications: IThunkGetMedications = thunk(
  // @ts-ignore
  async ({ setMedications, reset }, filters, { getState }) => {
    reset();

    const localState = getState() as IMedicationsStore;

    const paginationPayload = getPaginationPayload(localState.pagination);

    const { data } = await api.medications.getList({
      ...filters,
      ...paginationPayload,
    });

    setMedications(data);
  },
);

export default onGetMedications;
