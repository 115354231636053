import { action } from 'easy-peasy';
import { IActionSetTableCustomizations } from '../types';

const setTableCustomizations: IActionSetTableCustomizations = action(
  (state, { tableTypeId, items }) => {
    state.items[tableTypeId] = items;
  },
);

export default setTableCustomizations;
