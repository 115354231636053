import { useEffect, useState, ReactElement, ReactNode } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import { useStoreState, useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import NotFound from '~/ui/components/layouts/NotFoundPage';
import GeneralLayout from './GeneralLayout';
import AccessDeniedPage from '~/ui/components/layouts/AccessDeniedPage';
import Loader from '~/ui/components/common/Loader';

interface IProps {
  children: ReactNode;
}

interface IParams {
  id: string;
}

const PatientLayout = ({ children }: IProps): ReactElement => {
  const { id } = useParams<IParams>();
  const history = useHistory();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [patientNotFound, setPatientNotFound] = useState(false);
  const [accessDenied, setAccessDenied] = useState(false);

  const patientInfo = useStoreState(state => state.patient.info);

  const showError = useStoreActions(actions => actions.snackbar.showError);
  const onGetPatientInfo = useStoreActions(actions => actions.patient.onGetInfo);

  const onMount = async () => {
    try {
      const data = await onGetPatientInfo(Number(id));

      if (!data) {
        setPatientNotFound(true);
      }
    } catch (e: any) {
      if (e?.status === 403) {
        setAccessDenied(true);
        return;
      }

      showError(extractErrorMessage(e));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onMount();

    // Need to disable ability to go away from patient dashboard
    // Because need to log time
    // https://stackoverflow.com/a/57941367
    return () => {
      if (history.action === 'POP') {
        history.replace(location.pathname);
      }
    };
  }, []);

  if (isLoading) return <Loader />;
  if (patientNotFound) return <NotFound title="Patient Not Found" />;
  if (accessDenied) return <AccessDeniedPage />;
  if (!patientInfo) return null;

  return <GeneralLayout patientId={id}>{children}</GeneralLayout>;
};

export default PatientLayout;
