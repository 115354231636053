import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { getPaginationPayload } from '~/utils/pagination';
import { IActivityLogsStore, IThunkGetMoreActivityLogs } from '../types';

const onGetMoreActivityLogs: IThunkGetMoreActivityLogs = thunk(
  // @ts-ignore
  async ({ setMoreActivityLogs }, filters, { getState }) => {
    const localState = getState() as IActivityLogsStore;

    const paginationPayload = getPaginationPayload(localState.pagination);

    const { data } = await api.activityLogs.getList({
      ...filters,
      ...paginationPayload,
    });

    setMoreActivityLogs(data);
  },
);

export default onGetMoreActivityLogs;
