import { DeviceType } from '~/services/api/enums';
import {
  IBloodGlucoseReading,
  IBloodPressureReading,
  IPulseOximeterReading,
  IReading,
  IWeightScaleReading,
} from '~/services/api/readings/types';

const getBloodPressureData = (readings: IBloodPressureReading[]): number[][] => [
  readings.map(x => x.systolic),
  readings.map(x => x.diastolic),
  readings.map(x => x.pulse),
];

const getWeightScaleData = (readings: IWeightScaleReading[]): number[][] => [
  readings.map(x => x.weightInLbs),
  readings.map(x => x.previousGainInLbs),
  readings.map(x => x.previousLossInLbs),
  readings.map(x => x.dailyGainInLbs),
  readings.map(x => x.dailyLossInLbs),
  readings.map(x => x.weeklyGainInLbs),
  readings.map(x => x.weeklyLossInLbs),
];

const getWeightScaleMorningEveningData = (readings: IWeightScaleReading[]): number[][] => [
  readings.map(x => x.weightInLbs),
];

const getPulseOximeterData = (readings: IPulseOximeterReading[]): number[][] => [
  readings.map(x => x.spo2),
  readings.map(x => x.pulseBpm),
];

const getBloodGlucoseData = (readings: IBloodGlucoseReading[]): number[][] => [
  readings.map(x => x.bloodGlucoseMgdl),
];

export const getRawData = (readings: IReading[], deviceTypeId: DeviceType): number[][] => {
  switch (deviceTypeId) {
    case DeviceType.BloodPressure:
      return getBloodPressureData(readings as IBloodPressureReading[]);
    case DeviceType.WeightScale:
      return getWeightScaleData(readings as IWeightScaleReading[]);
    case DeviceType.PulseOximeter:
      return getPulseOximeterData(readings as IPulseOximeterReading[]);
    case DeviceType.BloodGlucose:
      return getBloodGlucoseData(readings as IBloodGlucoseReading[]);
    default:
      throw new Error();
  }
};

export const getMorningEveningRawData = (
  readings: IReading[],
  deviceTypeId: DeviceType,
): number[][] => {
  switch (deviceTypeId) {
    case DeviceType.BloodPressure:
      return getBloodPressureData(readings as IBloodPressureReading[]);
    case DeviceType.WeightScale:
      return getWeightScaleMorningEveningData(readings as IWeightScaleReading[]);
    case DeviceType.PulseOximeter:
      return getPulseOximeterData(readings as IPulseOximeterReading[]);
    case DeviceType.BloodGlucose:
      return getBloodGlucoseData(readings as IBloodGlucoseReading[]);
    default:
      throw new Error();
  }
};

export const minByModule = (values: number[]): number =>
  values.every(x => x >= 0) ? Math.min(...values) : Math.max(...values);

export const maxByModule = (values: number[]): number =>
  values.every(x => x >= 0) ? Math.max(...values) : Math.min(...values);

export const sum = (values: number[]): number => values.reduce((s, x) => s + x, 0);

export const average = (values: number[]): number => sum(values) / Math.max(values.length, 1);

export default {};
