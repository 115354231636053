import initStore from './initStore';
import actions from './actions';
import thunks from './thunks';
import { IUsersModel } from './types';

const users: IUsersModel = {
  ...initStore,
  ...actions,
  ...thunks,
};

export default users;
