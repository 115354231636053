import { ReactElement, useState, useEffect } from 'react';
import Select from 'react-select';

import api from '~/services/api';
import { useStoreActions } from '~/store/hooks';
import { getPaginationPayload, IPagination } from '~/utils/pagination';
import { extractErrorMessage } from '~/utils/error';
import Table from './Table';
import Modal from '~/ui/components/common/Modal';

import { patientStatusOptions } from '~/ui/constants/patient';
import { IUserPatient } from '~/services/api/users/types';
import styles from './AssignedPatientsModal.module.scss';

interface IProps {
  open: boolean;
  onClose: () => void;
  userId: number;
}

const initialPagination = {
  page: 0,
  itemsPerPage: 10,
  hasMore: false,
};

const AssignedPatientsModal = ({ open, onClose, userId }: IProps): ReactElement => {
  const [status, setStatus] = useState('All Statuses');
  const [assignedPatients, setAssignedPatients] = useState<IUserPatient[]>([]);
  const [pagination, setPagination] = useState<IPagination>(initialPagination);

  const onChange = (obj: { value: string; label: string }) => setStatus(obj.value);
  const showError = useStoreActions(actions => actions.snackbar.showError);

  const getPatients = async (currentPagination: IPagination): Promise<IUserPatient[]> => {
    const paginationPayload = getPaginationPayload(currentPagination);
    const patientsStatus = status === 'All Statuses' ? null : status;
    const params = { status: patientsStatus, ...paginationPayload };

    try {
      const patientList = await api.users.getPatients(userId, params).then(res => res.data);
      setPagination(prev => ({
        ...prev,
        page: prev.page + 1,
        hasMore: patientList.length === prev.itemsPerPage,
      }));

      return patientList;
    } catch (e) {
      showError(extractErrorMessage(e));
      return [];
    }
  };

  const onMount = async () => {
    const patientList = await getPatients(initialPagination);
    setAssignedPatients(patientList);
  };

  const onLoadMore = async () => {
    const patientList = await getPatients(pagination);
    setAssignedPatients(prev => [...prev, ...patientList]);
  };

  useEffect(() => {
    setPagination(initialPagination);
    onMount();
  }, [status]);

  return (
    <Modal
      open={open}
      title="Assigned Patients"
      onClose={onClose}
      maxWidth="md"
      className={styles.modal}
    >
      <>
        <div className={styles.inputContainer}>
          <Select
            options={patientStatusOptions}
            defaultValue={patientStatusOptions[0]}
            isSearchable={false}
            onChange={onChange}
          />
        </div>
        <Table
          userId={userId}
          onLoadMore={onLoadMore}
          pagination={pagination}
          assignedPatients={assignedPatients}
          setAssignedPatients={setAssignedPatients}
        />
      </>
    </Modal>
  );
};

export default AssignedPatientsModal;
