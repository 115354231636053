import { getDay, getDayPart } from '~/utils/deviceReading';
import { IDayPartReading } from './types';
import {
  IBloodGlucoseReading,
  IBloodPressureReading,
  IPulseOximeterReading,
  IReading,
  IWeightScaleReading,
} from '~/services/api/readings/types';
import { DeviceType } from '~/services/api/enums';

const getBloodPressureValues = (reading: IBloodPressureReading): number[] => [
  reading.systolic,
  reading.diastolic,
  reading.pulse,
];

const getWeightScaleValues = (reading: IWeightScaleReading): number[] => [reading.weightInLbs];

const getPulseOximeterValues = (reading: IPulseOximeterReading): number[] => [
  reading.spo2,
  reading.pulseBpm,
];

const getBloodGlucoseValues = (reading: IBloodGlucoseReading): number[] => [
  reading.bloodGlucoseMgdl,
];

const getBloodPressureAdditionalValues = (reading: IBloodPressureReading): number[] => [
  reading.irregularHeartbeat ? 1 : 0,
];

const getWeightScaleAdditionalValues = (): number[] => [];

const getPulseOximeterAdditionalValues = (reading: IPulseOximeterReading): number[] => [
  reading.beforeMeal ? 1 : 0,
];

const getBloodGlucoseAdditionalValues = (reading: IBloodGlucoseReading): number[] => [
  reading.beforeMeal ? 1 : 0,
];

export const getDayPartReadings = (
  readings: IReading[],
  deviceTypeId: DeviceType,
): IDayPartReading[] => {
  let getValues: (reading: IReading) => number[];
  let getAdditionalValues: (reading: IReading) => number[];

  switch (deviceTypeId) {
    case DeviceType.BloodPressure:
      getValues = x => getBloodPressureValues(x as IBloodPressureReading);
      getAdditionalValues = x => getBloodPressureAdditionalValues(x as IBloodPressureReading);
      break;
    case DeviceType.WeightScale:
      getValues = x => getWeightScaleValues(x as IWeightScaleReading);
      getAdditionalValues = () => getWeightScaleAdditionalValues();
      break;
    case DeviceType.PulseOximeter:
      getValues = x => getPulseOximeterValues(x as IPulseOximeterReading);
      getAdditionalValues = x => getPulseOximeterAdditionalValues(x as IPulseOximeterReading);
      break;
    case DeviceType.BloodGlucose:
      getValues = x => getBloodGlucoseValues(x as IBloodGlucoseReading);
      getAdditionalValues = x => getBloodGlucoseAdditionalValues(x as IBloodGlucoseReading);
      break;
    default:
      throw new Error();
  }

  const data = readings.map(x => ({
    day: getDay(x.measuredAt),
    dayPart: getDayPart(x.measuredAt),
    batteryLevel: x.batteryLevel,
    signalLevel: x.signalLevel,
    values: getValues(x),
    additionalValues: getAdditionalValues(x),
  }));

  return data;
};

export default {};
