import { RuleType, RuleCondition } from '~/services/api/enums';
import { INotificationRule } from '~/services/api/notificationRules/types';

interface IParams {
  rules: INotificationRule[];
  typeId: RuleType;
  conditionId: RuleCondition;
}

export const isDuplicatedRuleCombination = ({ rules, typeId, conditionId }: IParams): boolean =>
  !!rules.find(item => item.typeId === typeId && item.conditionId === conditionId);

export default {};
