import { useEffect, useState, ReactElement } from 'react';
import Select, { createFilter, ValueType } from 'react-select';
import { Controller, Control, FieldErrors } from 'react-hook-form';
import get from 'lodash/get';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import MenuList from './MenuList';
import Option from './Option';
import MultiValue from './MultiValue';

import { allOption } from './constants';
import { IOption } from './types';
import customStyles from './customStyles';
import styles from './SelectAll.module.scss';

type IValue = string | number;

interface IProps {
  control: Control<any>;
  errors: FieldErrors;
  name: string;
  options: IOption[];
  label?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  isSearchable?: boolean;
  maxMenuHeight?: number;
  placeholder?: string;
}

const SelectComponent = ({
  control,
  errors,
  name,
  options,
  label,
  isDisabled = false,
  isLoading = false,
  isSearchable = true,
  maxMenuHeight = 180,
  placeholder = '',
}: IProps): ReactElement => {
  const [isSelectAll, setIsSelectAll] = useState(false);

  const error = get(errors, `${name}.message`, '');

  const formatValue = (option: ValueType<IOption | IOption[], true>) =>
    (option as IOption[]).map((item: IOption) => item.value);

  const getValue = (value: IValue[]): IOption | IOption[] => {
    if (!options || !Array.isArray(value)) return [];

    return [
      ...(isSelectAll ? [allOption] : []),
      ...options.filter(option => value.indexOf(option.value) >= 0),
    ];
  };

  useEffect(() => {
    setIsSelectAll(false);
  }, [options.length]);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <FormControl component="fieldset" className={styles.selectControl}>
          <FormLabel component="legend" className={styles.label}>
            {label}
          </FormLabel>
          <Select
            filterOption={createFilter({ ignoreAccents: false })}
            instanceId={name}
            styles={customStyles}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isSearchable={isSearchable}
            maxMenuHeight={maxMenuHeight}
            name={name}
            options={[...(options.length ? [allOption] : []), ...options]}
            placeholder={placeholder}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            value={getValue(value)}
            onChange={(selected, event) => {
              if (selected === null || selected.length === 0) {
                setIsSelectAll(false);
                return onChange(formatValue(selected));
              }

              // select `Select all`
              if (selected[selected.length - 1].value === allOption.value) {
                setIsSelectAll(true);
                return onChange(formatValue(options));
              }

              if (selected.length !== options.length) {
                setIsSelectAll(false);
                return onChange(formatValue(selected));
              }

              let result: IOption[] = [];

              if (event.action === 'select-option') {
                setIsSelectAll(true);
                result = options;
              } else if (selected.includes(allOption)) {
                setIsSelectAll(false);
                result = selected.filter(option => option.value !== allOption.value);
              } else {
                setIsSelectAll(false);
              }

              return onChange(formatValue(result));
            }}
            components={{ MenuList, Option, MultiValue }}
          />
          {error && <FormHelperText error>{error}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};

export default SelectComponent;
