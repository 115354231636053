export enum UserPermission {
  None = 'None',
  ManagePatients = 'ManagePatients',
  MonitorPatients = 'MonitorPatients',
  PatientsEnrollments = 'PatientsEnrollments',
  ManageReports = 'ManageReports',
  ManageNotifications = 'ManageNotifications',
  ManageUsers = 'ManageUsers',
  ManageDevices = 'ManageDevices',
  ManageMobileUsers = 'ManageMobileUsers',
}

export enum NotificationType {
  Reading = 'Reading',
  Missed = 'Missed',
  RequestDeletion = 'RequestDeletion',
  NewMobileUser = 'NewMobileUser',
  ReminderToFollowUp = 'ReminderToFollowUp',
}

export enum MobileUserRole {
  Caretaker = 'Caretaker',
  HomeHealthCare = 'HomeHealthCare',
}

export enum UserRole {
  SystemAdmin = 'SystemAdmin',
  SuperAdmin = 'SuperAdmin',
  ProgramAdmin = 'ProgramAdmin',
  HealthManager = 'HealthManager',
}

export enum Gender {
  Male = 'Male',
  Female = 'Female',
}

export enum Order {
  Asc = 'asc',
  Desc = 'desc',
}

export enum Day {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}

export enum DayPart {
  Morning = 'Morning',
  Noon = 'Noon',
  Evening = 'Evening',
  Night = 'Night',
}

export enum Timezone {
  ADT = 'ADT',
  EST = 'EST',
  CST = 'CST',
  MST = 'MST',
  PST = 'PST',
  ALA = 'ALA',
  HAW = 'HAW',
  IST = 'IST',
  GMT = 'GMT',
  CET = 'CET',
  MSD = 'MSD',
  EEST = 'EEST',
  AET = 'AET',
  PHT = 'PHT',
  AST = 'AST',
}

export enum TimezoneCode {
  ADT = 'America/Paramaribo',
  EST = 'EST5EDT',
  CST = 'CST6CDT',
  MST = 'MST7MDT',
  PST = 'PST8PDT',
  ALA = 'America/Anchorage',
  HAW = 'Pacific/Honolulu',
  IST = 'Asia/Kolkata',
  GMT = 'UTC',
  CET = 'CET',
  MSD = 'Europe/Moscow',
  EEST = 'EET',
  AET = 'Australia/Sydney',
  PHT = 'Asia/Manila',
  AST = 'America/Puerto_Rico',
}
