import onGetActivityLogs from './onGetActivityLogs';
import onGetMoreActivityLogs from './onGetMoreActivityLogs';
import onGetActivityLogsByPatientId from './onGetActivityLogsByPatientId';
import onGetMoreActivityLogsByPatientId from './onGetMoreActivityLogsByPatientId';
import onCreateActivityLog from './onCreateActivityLog';
import onUpdateActivityLog from './onUpdateActivityLog';
import onDeleteActivityLog from './onDeleteActivityLog';

export default {
  onGetActivityLogs,
  onGetMoreActivityLogs,
  onGetActivityLogsByPatientId,
  onGetMoreActivityLogsByPatientId,
  onCreateActivityLog,
  onUpdateActivityLog,
  onDeleteActivityLog,
};
