import { useState, ReactElement } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import ConfirmModal from '~/ui/components/common/ConfirmModal';

import styles from './Rule.module.scss';
import { INotificationRule } from '~/services/api/notificationRules/types';

interface IProps {
  rule: INotificationRule;
  canManage?: boolean;
  onRemove: (index: number) => void;
}

const Rule = ({ rule, canManage, onRemove }: IProps): ReactElement => {
  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell>{rule.typeName}</TableCell>
        <TableCell>{rule.conditionName}</TableCell>
        <TableCell>{rule.level1}</TableCell>
        <TableCell>{rule.level2}</TableCell>
        <TableCell>{rule.level3}</TableCell>
        {canManage && (
          <TableCell align="center">
            <IconButton className={styles.icon} onClick={() => setShowRemoveConfirm(true)}>
              <DeleteIcon />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
      {showRemoveConfirm && (
        <ConfirmModal
          title="Remove"
          description="Are you sure you want to remove this rule?"
          onClose={() => setShowRemoveConfirm(false)}
          onConfirm={() => onRemove(rule.id)}
        />
      )}
    </>
  );
};

export default Rule;
