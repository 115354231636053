import { ISort } from '~/utils/sort/types';

interface ICell {
  id: string;
  label: string;
  useSort: boolean;
  width?: number;
  align?: any;
}

export const headCells: ICell[] = [
  {
    id: 'createdAt',
    label: 'Date and Time',
    useSort: false,
  },
  {
    id: 'userFullName',
    label: 'User',
    useSort: false,
  },
  {
    id: 'message',
    label: 'Activity',
    useSort: false,
  },
  {
    id: 'areaId',
    label: 'Area',
    useSort: false,
  },
  {
    id: 'programName',
    label: 'Program',
    useSort: false,
  },
  {
    id: 'eventId',
    label: 'Event',
    useSort: false,
  },
];

export const initialSort: ISort = {
  orderBy: 'createdAt',
  isDescending: true,
};

export default {};
