import { ReactElement } from 'react';

import Button from '~/ui/components/common/Button';
import Modal from '~/ui/components/common/Modal';

import styles from './AlertModal.module.scss';

interface IProps {
  title: string;
  description?: string;
  btnText?: string;
  onClose?: () => void;
}

const AlertModal = ({ title, description, btnText = 'OK', onClose }: IProps): ReactElement => (
  <Modal open title={title} className={styles.modal} onClose={onClose}>
    <div className={styles.inner}>
      <div className={styles.description}>{description}</div>
      <div className={styles.buttonsWrapper}>
        <Button color="primary" variant="contained" onClick={onClose}>
          {btnText}
        </Button>
      </div>
    </div>
  </Modal>
);

export default AlertModal;
