import { action } from 'easy-peasy';
import { IActionUpdateMobileUser } from '../types';

const updateMobileUser: IActionUpdateMobileUser = action((state, payload) => {
  const { id } = payload;

  state.items = state.items.map(item => {
    if (item.id === id) {
      return {
        ...item,
        phone: payload.phone,
        isActive: payload.isActive,
      };
    }

    return item;
  });
});

export default updateMobileUser;
