import { Dispatch, ReactElement, SetStateAction } from 'react';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import InfiniteScroll from 'react-infinite-scroll-component';

import TableHead from './TableHead';
import TableBody from './TableBody';

import { IProgramList } from '~/services/api/programs/types';
import { ISort } from '~/utils/sort/types';
import { IPagination } from '~/utils/pagination';
import Loader from '~/ui/components/common/Loader';

interface IProps {
  programs: IProgramList[];
  sort: ISort;
  setSort: Dispatch<SetStateAction<ISort>>;
  onLoadMore: () => void;
  pagination: IPagination;
}

const EnhancedTable = ({
  programs,
  sort,
  setSort,
  onLoadMore,
  pagination,
}: IProps): ReactElement => (
  <TableContainer>
    <InfiniteScroll
      dataLength={programs.length}
      next={onLoadMore}
      hasMore={pagination.hasMore}
      loader={<Loader />}
      scrollableTarget="scrollableDiv"
    >
      <Table>
        <TableHead sort={sort} onRequestSort={setSort} />
        <TableBody programs={programs} />
      </Table>
    </InfiniteScroll>
  </TableContainer>
);

export default EnhancedTable;
