import { ReactElement } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { isoToFormat } from '~/utils/date';
import { IPatientDetails } from '~/services/api/patients/types';
import styles from './PatientDetails.module.scss';
import { Timezone } from '~/types';

interface IProps {
  patient: IPatientDetails;
}

const PatientDetails = ({ patient }: IProps): ReactElement => {
  const { programName, dateOfBirth, createdAt, activatedAt, dischargedAt } = patient;

  return (
    <div className={styles.container}>
      <div className={styles.title}>Patient Details</div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Program</TableCell>
              <TableCell align="center">Date of birth</TableCell>
              <TableCell align="center">Enrollment date</TableCell>
              <TableCell align="center">Date activated</TableCell>
              <TableCell align="center">Discharge date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={styles.cell}>{programName}</TableCell>
              <TableCell align="center">
                {dateOfBirth ? isoToFormat(dateOfBirth, 'dd MMM y', Timezone.GMT, false) : '-'}
              </TableCell>
              <TableCell align="center">
                {createdAt ? isoToFormat(createdAt, 'dd MMM y') : '-'}
              </TableCell>
              <TableCell align="center">
                {activatedAt ? isoToFormat(activatedAt, 'dd MMM y') : '-'}
              </TableCell>
              <TableCell align="center">
                {dischargedAt ? isoToFormat(dischargedAt, 'dd MMM y') : '-'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PatientDetails;
