import { useEffect, useRef } from 'react';
import { useStoreActions, useStoreState } from '~/store/hooks';

export const useTrackUserActivity = () => {
  const { parsedAccessToken } = useStoreState(store => store.auth);

  const { onRefresh } = useStoreActions(actions => actions.auth);

  const parsedAccessTokenRef = useRef(parsedAccessToken);

  parsedAccessTokenRef.current = parsedAccessToken;

  const onGlobalMouseMove = async () => {
    window.removeEventListener('mousemove', onGlobalMouseMove);

    if (parsedAccessTokenRef.current?.exp - new Date().getTime() / 1000 < 60) {
      await onRefresh();
    }

    setTimeout(() => {
      window.addEventListener('mousemove', onGlobalMouseMove);
    }, 5000);
  };

  useEffect(() => {
    window.addEventListener('mousemove', onGlobalMouseMove);

    return () => {
      window.removeEventListener('mousemove', onGlobalMouseMove);
    };
  }, []);
};

export default {};
