import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { getClientId, setTokens } from '~/services/auth';
import { IThunkLogin } from '../types';

const onLogin: IThunkLogin = thunk(
  // @ts-ignore
  async ({ setAuthorized, setIsMfaRequired }, payload) => {
    const clientId = getClientId();

    const data = await api.auth
      .logIn({
        ...payload,
        clientId,
      })
      .then(response => response.data);

    setIsMfaRequired(data.isMfaRequired);
    setTokens(data);
    setAuthorized();
    localStorage.setItem('seconds', '0');
  },
);

export default onLogin;
