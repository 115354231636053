import { IInsurancesStore } from './types';

const initStore: IInsurancesStore = {
  items: [],
  pagination: {
    page: null,
    itemsPerPage: null,
    hasMore: false,
  },
};

export default initStore;
