import { Grid } from '@material-ui/core';
import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import RotateRightIcon from '@mui/icons-material/RotateRight';

import { formatToIsoWithTimezone, setEndOfDay } from '~/utils/date';
import { initialValues, validate } from './form';
import Button from '~/ui/components/common/Button';
import DatePicker from '~/ui/components/inputs/DatePicker';

import { IPatient } from '~/services/api/patients/types';
import styles from './Header.module.scss';
import { IMedicationsListFilters } from '~/services/api/medications/types';

interface IProps {
  patient: IPatient;
  onShowCreatePopup: () => void;
  onSubmit: (values: IMedicationsListFilters) => Promise<void>;
}

const Header = ({ patient, onShowCreatePopup, onSubmit }: IProps): ReactElement => {
  const formMethods = useForm({ resolver: validate, defaultValues: initialValues });

  const {
    getValues,
    reset,
    control,
    formState: { errors },
    handleSubmit,
  } = formMethods;
  const values = getValues();
  const disabled =
    values.startDate === initialValues.startDate && values.endDate === initialValues.endDate;

  const onSubmitForm = (formValues: IMedicationsListFilters) => {
    onSubmit({
      ...formValues,
      patientId: patient.id,
      startDate: formatToIsoWithTimezone(formValues.startDate, patient.timezone),
      endDate: formatToIsoWithTimezone(setEndOfDay(formValues.endDate), patient.timezone),
    });
  };

  return (
    <>
      <div className={styles.headerWrapper}>
        <div className={styles.title}>Medication</div>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutlineIcon />}
          onClick={onShowCreatePopup}
        >
          ADD MEDICATION
        </Button>
      </div>
      <form className={styles.form} onSubmit={handleSubmit(onSubmitForm)}>
        <Grid container spacing={1}>
          <Grid item xs>
            <DatePicker
              placeholder=".. /.. /.."
              label="Start Date"
              name="startDate"
              maxDate={new Date()}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item xs>
            <DatePicker
              placeholder=".. /.. /.."
              label="End Date"
              name="endDate"
              maxDate={new Date()}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item>
            <Button variant="outlined" type="submit" color="primary" startIcon={<SearchIcon />}>
              Search
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={disabled}
              variant="outlined"
              type="button"
              startIcon={<RotateRightIcon />}
              onClick={() => {
                reset(initialValues);
                onSubmit({});
              }}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default Header;
