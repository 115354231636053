import { ReactElement, useState, useEffect } from 'react';

import { useStoreActions } from '~/store/hooks';
import Modal from '~/ui/components/common/Modal';
import Loader from '~/ui/components/common/Loader';
import Header from './Header';
import Table from './Table';
import styles from './DevicesPopup.module.scss';
import { ISort } from '~/utils/sort/types';
import { IDevicesListFilters } from '~/services/api/devices/types';

interface IProps {
  onClose: () => void;
}

const DevicesPopup = ({ onClose }: IProps): ReactElement => {
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<IDevicesListFilters>(null);
  const [sort, setSort] = useState({} as ISort);

  const { onGetDevices, onGetMoreDevices } = useStoreActions(actions => actions.devices);

  const payload = { ...filters, ...sort };

  const getDevices = async (currentFilters: IDevicesListFilters) => {
    setLoading(true);
    await onGetDevices(currentFilters);
    setLoading(false);
  };

  const next = () => {
    onGetMoreDevices(payload);
  };

  useEffect(() => {
    getDevices(payload);
  }, [filters, sort]);

  return (
    <Modal open onClose={onClose} title="Devices" maxWidth="lg" className={styles.modal}>
      <>
        <Header onSubmit={setFilters} />
        {loading ? <Loader /> : <Table sort={sort} setSort={setSort} next={next} />}
      </>
    </Modal>
  );
};

export default DevicesPopup;
