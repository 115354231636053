import initStore from './initStore';
import actions from './actions';
import thunks from './thunks';

import { ITableCustomizationsModel } from './types';

const tableCustomizations: ITableCustomizationsModel = {
  ...initStore,
  ...actions,
  ...thunks,
};

export default tableCustomizations;
