import { action } from 'easy-peasy';
import { deleteTokens, getAccessToken, getRefreshToken, parseToken } from '~/services/auth';
import { UserPermission } from '~/types';
import { IActionSetAuthorized } from '../types';

const setAuthorized: IActionSetAuthorized = action(state => {
  try {
    const accessToken = getAccessToken();
    const refreshToken = getRefreshToken();

    state.parsedAccessToken = accessToken ? parseToken(accessToken) : null;
    state.parsedRefreshToken = refreshToken ? parseToken(refreshToken) : null;

    const authorized = state.parsedRefreshToken?.exp - new Date().getTime() / 1000 >= 60;

    state.currentUser = authorized
      ? {
          id: state.parsedAccessToken.id,
          firstName: state.parsedAccessToken.firstName,
          lastName: state.parsedAccessToken.lastName,
          fullName: state.parsedAccessToken.fullName,
          email: state.parsedAccessToken.email,
          roleId: state.parsedAccessToken.roleId,
          permissions: state.parsedAccessToken.permissions.split(',') as UserPermission[],
        }
      : null;

    state.authorized = authorized;
    state.authChecked = true;
  } catch {
    deleteTokens();
    window.location.reload();
  }
});

export default setAuthorized;
