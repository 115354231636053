import { action } from 'easy-peasy';
import icd10CodeTypeOrderings from '~/constants/icd10CodeTypeOrderings';
import { IActionAddCarePlanIcd10Code } from '../types';

const addCarePlanIcd10Code: IActionAddCarePlanIcd10Code = action((state, payload) => {
  state.items = [payload, ...state.items].sort(
    (a, b) =>
      icd10CodeTypeOrderings.indexOf(a.typeId) - icd10CodeTypeOrderings.indexOf(b.typeId) ||
      a.icd10CodeName.localeCompare(b.icd10CodeName),
  );
});

export default addCarePlanIcd10Code;
