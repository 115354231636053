import { object, string, number, array } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { isDateBiggerOrEqual } from '~/utils/date';

const validationSchema = object().shape({
  programIds: array().of(number()).min(1, 'Field is required'),
  startDate: string().required('Field is required'),
  endDate: string()
    .required('Field is required')
    .test({
      name: 'dateCompare',
      message: '`End date` should be equal or bigger then `Start date`',
      test: (value, context) => {
        // eslint-disable-next-line
      const { startDate } = context.parent;
        if (!startDate || !value) return true;
        return isDateBiggerOrEqual(startDate, value);
      },
    }),
});

export default yupResolver(validationSchema);
