import ReactDOM from 'react-dom';
import { StoreProvider } from 'easy-peasy';

import store from './store';
import App from './ui/App';

ReactDOM.render(
  <StoreProvider store={store}>
    <App />
  </StoreProvider>,
  document.getElementById('root'),
);
