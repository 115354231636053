import { ReactElement } from 'react';

import styles from './NoDataFound.module.scss';

interface IProps {
  title?: string;
}

const NoDataFound = ({ title = 'No data found' }: IProps): ReactElement => (
  <div className={styles.noDataFound}>{title}</div>
);

export default NoDataFound;
