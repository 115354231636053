import { useEffect, useState, ReactElement } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { useStoreState, useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import Loader from '~/ui/components/common/Loader';
import NoDataFound from '~/ui/components/reusable/NoDataFound';
import InfoPanel from './InfoPanel';
import Item from './Item';

import { IPatientInfo } from '~/services/api/patients/types';
import { ISectionPermissions } from '../types';
import styles from './ActivityLogsList.module.scss';
import { ActivityLogMode } from '~/services/api/enums';
import { IActivityLog } from '~/services/api/activityLogs/types';
import { formatToIsoWithTimezone, getInitialDateRange } from '~/utils/date';

interface IProps {
  patientInfo: IPatientInfo;
  userId: number;
  permissions: ISectionPermissions;
  onEdit: (activityLog: IActivityLog) => void;
}

const ActivityLogsList = ({ patientInfo, userId, permissions, onEdit }: IProps): ReactElement => {
  const [isLoading, setIsLoading] = useState(true);

  const activityLogs = useStoreState(state => state.activityLogs.itemsByPatientId);

  const showError = useStoreActions(actions => actions.snackbar.showError);
  const onGetActivityLogsByPatientId = useStoreActions(
    actions => actions.activityLogs.onGetActivityLogsByPatientId,
  );

  const onMount = async () => {
    try {
      await onGetActivityLogsByPatientId({
        patientId: patientInfo.id,
        params: {
          startDate: formatToIsoWithTimezone(getInitialDateRange().startDate, patientInfo.timezone),
          endDate: formatToIsoWithTimezone(getInitialDateRange().endDate, patientInfo.timezone),
          mode: ActivityLogMode.Offline,
        },
      });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  if (isLoading) return <Loader />;

  return (
    <>
      <InfoPanel
        startDate={getInitialDateRange().startDate}
        endDate={getInitialDateRange().endDate}
        label="Activity Logs"
      />
      <TableContainer className={styles.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Activity Minutes</TableCell>
              <TableCell>Date/Time</TableCell>
              <TableCell>Note</TableCell>
              <TableCell>Author</TableCell>
              <TableCell>Edit</TableCell>
              {permissions.canManage && <TableCell>Remove</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {activityLogs.length ? (
              activityLogs.map(activityLog => (
                <Item
                  key={activityLog.id}
                  activityLog={activityLog}
                  userId={userId}
                  permissions={permissions}
                  onEdit={onEdit}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  <NoDataFound />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ActivityLogsList;
