import { ModalType } from './types';

export const TABS = [
  {
    value: ModalType.ApplyCode,
    label: 'Existing',
  },
  {
    value: ModalType.CreateCode,
    label: 'New',
  },
];

export default {};
