import { ReactElement } from 'react';

import PatientsPopup from '../reusable/PatientsPopup';

import { Order } from '~/types';
import { IPagePermissions } from '../../types';
import { PatientStatus } from '~/services/api/enums';

interface IProps {
  permissions: IPagePermissions;
  onClose: () => void;
}

const DischargedPatients = ({ permissions, onClose }: IProps): ReactElement => (
  <PatientsPopup
    type="Discharged"
    permissions={permissions}
    status={PatientStatus.Discharged}
    sort="dischargedAt"
    order={Order.Desc}
    title="Discharged"
    subTitle="Discharged Patients"
    onClose={onClose}
  />
);

export default DischargedPatients;
