import { RuleCondition, RuleType } from '~/services/api/enums';
import { INotificationRule } from '~/services/api/notificationRules/types';
import { getRuleLevelWarning, getWeightRuleLevelWarning } from '~/utils/deviceReading';
import styles from './Reading.module.scss';

export const transformMeasurementValue = (value: number, levelWarning: number) =>
  levelWarning === 0 ? value : `${value}*`;

interface IMeasurementDataParams {
  typeId: RuleType;
  condition?: RuleCondition;
  value: number;
  rules: INotificationRule[];
}

const getMeasurementClassName = (levelWarning: number) =>
  levelWarning === 0 ? '' : styles[`level_${levelWarning}`];

export const getMeasurementData = (params: IMeasurementDataParams) => {
  const { typeId, condition, value, rules } = params;

  const levelWarning =
    typeId === RuleType.Weight
      ? getWeightRuleLevelWarning(rules, condition, value)
      : getRuleLevelWarning(rules, typeId, value);

  return {
    className: getMeasurementClassName(levelWarning),
    value: transformMeasurementValue(value, levelWarning),
  };
};

export default {};
