import { ReactElement } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { getData } from './helper';
import Reading from './Reading';

import styles from './MaxMinReading.module.scss';
import { IDayPartReading } from '../types';
import { DeviceType } from '~/services/api/enums';

interface IProps {
  dayPartReadings: IDayPartReading[];
  deviceTypeId: DeviceType;
}

const MaxMinReading = ({ dayPartReadings, deviceTypeId }: IProps): ReactElement => {
  const data = getData(dayPartReadings, deviceTypeId);

  return (
    <TableContainer>
      <Table className={styles.table}>
        <TableHead>
          <TableRow>
            <TableCell>Reading Type</TableCell>
            <TableCell>Max</TableCell>
            <TableCell>Min</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.indicators.map(indicator => (
            <TableRow key={indicator.label}>
              <TableCell>{indicator.label}</TableCell>
              <TableCell>
                <Reading item={indicator.max} />
              </TableCell>
              <TableCell>
                <Reading item={indicator.min} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MaxMinReading;
