import { action } from 'easy-peasy';
import initStore from '../initStore';
import { IActionReset } from '../types';

const resetByPatientId: IActionReset = action(state => {
  const { itemsByPatientId, paginationByPatientId } = initStore;

  state.itemsByPatientId = itemsByPatientId;
  state.paginationByPatientId = paginationByPatientId;
});

export default resetByPatientId;
