import classnames from 'classnames';
import { useState, ReactElement, createRef, useEffect } from 'react';

import styles from './ReadMoreLess.module.scss';

interface IProps {
  content: string;
}

const ReadMoreLess = ({ content }: IProps): ReactElement => {
  const contentRef = createRef<HTMLDivElement>();

  const [showFull, setShowFull] = useState(false);
  const [isContentOverflowed, setIsContentOverflowed] = useState(false);

  useEffect(() => {
    setIsContentOverflowed(contentRef.current?.scrollHeight > contentRef.current?.offsetHeight);
  }, [contentRef]);

  return (
    <div
      ref={contentRef}
      className={classnames(styles.contentContainer, { [styles.full]: showFull })}
    >
      {content}
      {(isContentOverflowed || showFull) && (
        <div className={classnames(styles.readMoreLessContainer, { [styles.full]: showFull })}>
          <span>{showFull ? '' : '...'}</span>
          <span
            role="presentation"
            className={styles.readMoreLessToggle}
            onClick={() => setShowFull(prev => !prev)}
          >
            {showFull ? 'Read Less' : 'Read More'}
          </span>
        </div>
      )}
    </div>
  );
};

export default ReadMoreLess;
