import KeyboardReturnOutlinedIcon from '@mui/icons-material/KeyboardReturnOutlined';
import PhonelinkEraseOutlinedIcon from '@mui/icons-material/PhonelinkEraseOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import { DeviceAction, IActions } from '../../types';

export const getActions = (actions: IActions) => [
  {
    icon: <LoginIcon />,
    title: 'Assign to program',
    devicesIds: actions[DeviceAction.Assign],
    action: DeviceAction.Assign,
  },
  {
    icon: <LogoutIcon />,
    title: 'Unassign from program',
    devicesIds: actions[DeviceAction.Unassign],
    action: DeviceAction.Unassign,
  },
  {
    icon: <PersonRemoveIcon />,
    title: 'Unregister from patient',
    devicesIds: actions[DeviceAction.Unregister],
    action: DeviceAction.Unregister,
  },
  {
    icon: <KeyboardReturnOutlinedIcon />,
    title: 'Mark as Returned',
    devicesIds: actions[DeviceAction.MarkAsReturned],
    action: DeviceAction.MarkAsReturned,
  },
  {
    icon: <PhonelinkEraseOutlinedIcon />,
    title: 'Mark as Lost/Written off',
    devicesIds: actions[DeviceAction.MarkAsLost],
    action: DeviceAction.MarkAsLost,
  },
  {
    icon: <DeleteIcon />,
    title: 'Delete',
    devicesIds: actions[DeviceAction.Delete],
    action: DeviceAction.Delete,
  },
];

export default {};
