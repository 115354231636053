import { DeviceStatus, DeviceSupplier, DeviceType } from '~/services/api/enums';
import { IDevicesListFilters } from '~/services/api/devices/types';

const initialValues: IDevicesListFilters = {
  programId: '',
  typeId: '' as DeviceType,
  patientFullName: '',
  imei: '',
  status: '' as DeviceStatus,
  supplier: '' as DeviceSupplier,
};

export default initialValues;
