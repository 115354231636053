import onGetCarePlanIcd10Codes from './onGetCarePlanIcd10Codes';
import onCreateCarePlanIcd10Code from './onCreateCarePlanIcd10Code';
import onUpdateCarePlanIcd10Code from './onUpdateCarePlanIcd10Code';
import onRemoveCarePlanIcd10Code from './onRemoveCarePlanIcd10Code';

export default {
  onGetCarePlanIcd10Codes,
  onCreateCarePlanIcd10Code,
  onUpdateCarePlanIcd10Code,
  onRemoveCarePlanIcd10Code,
};
