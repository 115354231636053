import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetProgram } from '../types';

// @ts-ignore
const onGetProgram: IThunkGetProgram = thunk(async ({ setProgram }, id) => {
  const program = await api.programs.get(id).then(res => res.data);
  setProgram(program);
});

export default onGetProgram;
