import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { getPaginationPayload } from '~/utils/pagination';
import { IThunkGetPatients, IPatientsStore } from '../types';

const onGetPatients: IThunkGetPatients = thunk(
  // @ts-ignore
  async ({ setPatients, reset }, filters, { getState }) => {
    reset();

    const localState = getState() as IPatientsStore;

    const paginationPayload = getPaginationPayload(localState.pagination);

    const { data } = await api.patients.getList({
      ...filters,
      ...paginationPayload,
    });

    setPatients(data);
  },
);

export default onGetPatients;
