export const isFile = (value: any): boolean => Boolean(value?.name);

export const validateFileType = (file: File, acceptTypes: string): boolean => {
  if (!acceptTypes) return true;

  const type = file?.type;
  if (!type) return false;

  return acceptTypes.split(',').includes(type);
};

export default {};
