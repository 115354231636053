import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetNote } from '../types';

const onGetNote: IThunkGetNote = thunk(
  // @ts-ignore
  async (_, id) => {
    const { data } = await api.notes.get(id);

    return data;
  },
);

export default onGetNote;
