import { action } from 'easy-peasy';
import { PatientStatus } from '~/services/api/enums';
import { IActionActivatePatient } from '../types';

const activatePatient: IActionActivatePatient = action(state => {
  state.personalData = {
    ...state.personalData,
    status: PatientStatus.Approved,
  };
});

export default activatePatient;
