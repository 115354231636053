export const HOME = '/';
export const SIGN_IN = '/signin';
export const DASHBOARD = '/dashboard';
export const PROGRAMS = '/programs';
export const INVENTORY = '/inventory';
export const INVENTORY_PROGRAM = `${INVENTORY}/program`;
export const INVENTORY_AVAILABLE = `${INVENTORY}/available`;
export const INVENTORY_UNREGISTER = `${INVENTORY}/unregister`;
export const PATIENTS = '/patients';
export const USERS = '/users';
export const USERS_HEALTH_MANAGER = `${USERS}/health-manager`;
export const USERS_PROGRAM_ADMIN = `${USERS}/program-admin`;
export const USERS_SUPER_ADMIN = `${USERS}/super-admin`;
export const MOBILE_USERS = `${USERS}/mobile`;
export const REPORTS = '/reports';
export const AUDIT_LOGS = '/audit-logs';
export const ACTIVITY_LOGS = '/activity-logs';
export const ACTIVITY_LOGS_PATIENT = '/activity-logs/patient/:patientId';
export const PROFILE = '/profile';
export const PATIENT_DASHBOARD = '/patient/:id/dashboard';
export const PATIENT_DASHBOARD_OVERVIEW = `${PATIENT_DASHBOARD}/overview`;
export const PATIENT_DASHBOARD_PERSONAL_DATA = `${PATIENT_DASHBOARD}/personal-data`;
export const PATIENT_DEVICES = '/patient/:id/devices';
export const PATIENT_NOTIFICATION_RULES = '/patient/:id/notification-rules';
export const PATIENT_NOTES = '/patient/:id/notes';
export const PATIENT_PLAN_OF_CARE = '/patient/:id/plan-of-care';
export const PATIENT_REPORTS = '/patient/:id/reports';
