import { ReactElement, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@mui/icons-material/Search';
import RotateRightIcon from '@mui/icons-material/RotateRight';

import { setEndOfDay } from '~/utils/date';
import { getInitialValues, validate } from './form';
import Input from '~/ui/components/inputs/Input';
import Select from '~/ui/components/inputs/Select';
import SelectVirtualized from '~/ui/components/inputs/SelectVirtualized';
import Button from '~/ui/components/common/Button';
import DatePicker from '~/ui/components/inputs/DatePicker';

import { patientStatusOptions } from '~/ui/constants/patient';
import { IIdName } from '~/services/api/types';
import { IActivityLogsListFilters } from '~/services/api/activityLogs/types';

interface IProps {
  programs: IIdName[];
  onSubmit: (filters: IActivityLogsListFilters) => void;
}

const Header = ({ programs, onSubmit }: IProps): ReactElement => {
  const initialValues = getInitialValues();

  const formMethods = useForm<IActivityLogsListFilters>({
    resolver: validate,
    defaultValues: initialValues,
  });

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = formMethods;

  const programOptions = useMemo(() => {
    const options = programs.map(item => ({
      value: item.id,
      label: item.name,
    }));

    return [
      {
        value: '',
        label: 'All Programs',
      },
      ...options,
    ];
  }, [programs.length]);

  const onSubmitForm = (values: IActivityLogsListFilters) => {
    const payload = { ...values };

    onSubmit({
      ...payload,
      endDate: setEndOfDay(payload.endDate),
    });
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Grid container spacing={2}>
          <Grid item xs>
            <DatePicker
              placeholder=".. /.. /.."
              label="Start Date"
              name="startDate"
              maxDate={new Date()}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item xs>
            <DatePicker
              placeholder=".. /.. /.."
              label="End Date"
              name="endDate"
              maxDate={new Date()}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item xs>
            <Input name="fullName" register={register} errors={errors} label="Patient Name" />
          </Grid>
          <Grid item xs>
            <Input name="subjectId" register={register} errors={errors} label="Patient ID" />
          </Grid>
          <Grid item xs>
            <Select
              name="status"
              control={control}
              errors={errors}
              options={patientStatusOptions}
              label="Status"
            />
          </Grid>
          <Grid item xs>
            <SelectVirtualized
              name="programId"
              control={control}
              errors={errors}
              options={programOptions}
              label="Program Name"
            />
          </Grid>
          <Grid item xs={1} />
          <Grid item>
            <Button variant="outlined" type="submit" color="primary" startIcon={<SearchIcon />}>
              Search
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              type="button"
              startIcon={<RotateRightIcon />}
              onClick={() => {
                reset(initialValues);
                onSubmit(initialValues);
              }}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default Header;
