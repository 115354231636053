import { string } from 'yup';

export const phoneRegExp = /^(\+)?[0-9]*$/;

// Min 12 Characters, One Uppercase, One Lowercase, One Number, One special Character
const passwordRegExp =
  // eslint-disable-next-line
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-~!@#$%^&*?()+=_{}|'"`:;<>\/\\.,\[\]])[-~!@#$%^&*?()+=_{}|'"`:;<>\/\\.,\[\]A-Za-z\d]{12,}$/;

export const errors = {
  password:
    'Must Contain 12 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
  phone: 'Only numbers and `+` symbol are allowed',
};

export const password = string()
  .required('Field is required')
  .matches(passwordRegExp, errors.password)
  .max(100, 'Max - 100 characters');

export const phoneNumber = string()
  .nullable()
  .max(20, 'Max length - 20 symbols')
  .matches(phoneRegExp, 'Only numbers and `+` symbol are allowed');
