import * as Highcharts from 'highcharts';

import { getDayPart } from '~/utils/deviceReading';
import { DayPart } from '~/types';
import { IReading } from '~/services/api/readings/types';
import { ChartColor } from '../types';

interface IData {
  morning: number;
  noon: number;
  evening: number;
  night: number;
}

const getData = (readings: IReading[]): IData => {
  const dayParts = readings.map(x => getDayPart(x.measuredAt));

  const data: IData = {
    morning: dayParts.filter(x => x === DayPart.Morning).length,
    noon: dayParts.filter(x => x === DayPart.Noon).length,
    evening: dayParts.filter(x => x === DayPart.Evening).length,
    night: dayParts.filter(x => x === DayPart.Night).length,
  };

  return data;
};

export const getChartOptions = (readings: IReading[]): Highcharts.Options => {
  const data = getData(readings);

  return {
    chart: {
      type: 'pie',
    },
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        name: 'Reading Count',
        type: 'pie',
        data: [
          {
            name: 'Morning',
            y: data.morning,
            color: ChartColor.Blue,
          },
          {
            name: 'Noon',
            y: data.noon,
            color: ChartColor.Yellow,
          },
          {
            name: 'Evening',
            y: data.evening,
            color: ChartColor.Red,
          },
          {
            name: 'Night',
            y: data.night,
            color: ChartColor.Green,
          },
        ],
      },
    ],
  };
};

export default {};
