import axios from 'axios';
import { IResponse } from '~/services/api/types';
import { IEmailLogin, IRefresh, IResendCode, ITokens, IVerifyCode } from './types';

const apiUrl = 'auth';

export const logIn = (data: IEmailLogin): IResponse<ITokens> =>
  axios({
    method: 'post',
    url: `${apiUrl}/log-in/by-email`,
    data,
  });

export const resendCode = (data: IResendCode): IResponse<void> =>
  axios({
    method: 'post',
    url: `${apiUrl}/resend-code`,
    data,
  });

export const verifyCode = (data: IVerifyCode): IResponse<ITokens> =>
  axios({
    method: 'post',
    url: `${apiUrl}/verify-code`,
    data,
  });

export const refresh = (data: IRefresh): IResponse<ITokens> =>
  axios({
    method: 'post',
    url: `${apiUrl}/refresh`,
    data,
  });

export const logOut = (data: IRefresh): IResponse<void> =>
  axios({
    method: 'post',
    url: `${apiUrl}/log-out`,
    data,
  });

export default {};
