import { Grid, IconButton } from '@material-ui/core';
import { ReactElement, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import api from '~/services/api';
import { INotification } from '~/services/api/notifications/types';
import { useStoreActions, useStoreState } from '~/store/hooks';
import Button from '~/ui/components/common/Button';
import Loader from '~/ui/components/common/Loader';
import NoDataFound from '~/ui/components/reusable/NoDataFound';

import { ActionType } from '~/ui/layouts/types';
import { extractErrorMessage } from '~/utils/error';
import NotificationItem from './NotificationItem';
import styles from './Notifications.module.scss';
import { NotificationType } from '~/types';

interface IProps {
  onAction: (actionType: ActionType, notification: INotification) => void;
  onClose: () => void;
}

const Notifications = ({ onAction, onClose }: IProps): ReactElement => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSelecting, setSelect] = useState(false);

  const [deleteNotificationList, setDeleteNotificationList] = useState<INotification[]>([]);

  const showError = useStoreActions(actions => actions.snackbar.showError);
  const { items, pagination } = useStoreState(state => state.notifications);
  const { onGetNotifications, onGetMoreNotifications, onDeleteNotifications, onGetNotifyCount } =
    useStoreActions(actions => actions.notifications);

  if (isSelecting && items.length === 0) {
    setSelect(false);
  }

  const getData = async () => {
    setIsLoading(true);

    try {
      await onGetNotifications();

      setIsLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const getMore = async () => {
    try {
      await onGetMoreNotifications();
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const removeNotifications = async () => {
    const ids = deleteNotificationList.map(x => x.id);
    try {
      setDeleteNotificationList([]);

      await onDeleteNotifications(ids);
      getData();
      onGetNotifyCount();
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const onCheckboxChange = (item: INotification) => {
    if (deleteNotificationList.some(x => x.id === item.id)) {
      setDeleteNotificationList([...deleteNotificationList.filter(x => x.id !== item.id)]);
    } else {
      setDeleteNotificationList([...deleteNotificationList, item]);
    }
  };

  const onClick = async (item: INotification) => {
    if (isSelecting) {
      onCheckboxChange(item);
      return;
    }

    try {
      await api.notifications.markAsRead([item.id]).then(() => {
        onGetNotifyCount();
      });
    } catch (e) {
      showError(extractErrorMessage(e));
    }

    let action = null;

    switch (item.typeId) {
      case NotificationType.Missed:
      case NotificationType.Reading:
        action = ActionType.Patient;
        break;
      case NotificationType.RequestDeletion:
      case NotificationType.NewMobileUser:
        action = ActionType.MobileUsers;
        break;
      case NotificationType.ReminderToFollowUp:
        action = ActionType.Notes;
        break;
      default:
        throw new Error(`Invalid value for NotificationType: ${item.typeId}.`);
    }

    onAction(action, item);
  };

  const onSetAll = () => {
    if (items.length === deleteNotificationList.length) {
      setDeleteNotificationList([]);
      return;
    }

    setDeleteNotificationList([...items]);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={styles.popupWrapper}>
      <div role="presentation" className={styles.overlay} onClick={onClose} />
      <div className={styles.popup}>
        <div>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            className={styles.popupHeader}
          >
            <Grid item xs={3}>
              {isSelecting && (
                <Button
                  className={styles.selectAll}
                  color="primary"
                  variant="text"
                  onClick={() => onSetAll()}
                >
                  {deleteNotificationList.length === items.length ? 'Unselect all' : 'Select all'}
                </Button>
              )}
            </Grid>

            <Grid item xs={6}>
              <span className={styles.HeaderTitle}>Notifications</span>
            </Grid>

            <Grid item xs={3}>
              {items.length !== 0 && (
                <Button
                  color="primary"
                  variant="text"
                  className={styles.selectBtn}
                  onClick={() => setSelect(value => !value)}
                >
                  Select
                </Button>
              )}
            </Grid>
          </Grid>
        </div>

        <Grid id="scrollableDiv" container className={styles.popupBody}>
          <InfiniteScroll
            dataLength={items.length}
            next={getMore}
            hasMore={pagination.hasMore}
            loader={<Loader />}
            scrollableTarget="scrollableDiv"
          >
            {isLoading ? (
              <Grid item>
                <Loader />
              </Grid>
            ) : (
              <>
                {items.length !== 0 ? (
                  <>
                    {items.map(item => (
                      <Grid key={item.id} item xs={12}>
                        <NotificationItem
                          item={item}
                          onClick={value => onClick(value)}
                          isSelecting={isSelecting}
                          onCheckboxChange={value => onCheckboxChange(value)}
                          checked={deleteNotificationList.some(x => x.id === item.id)}
                        />
                        <div className={styles.Divider} />
                      </Grid>
                    ))}
                  </>
                ) : (
                  <Grid item xs={12}>
                    <NoDataFound title="There are no notifications" />
                  </Grid>
                )}
              </>
            )}
          </InfiniteScroll>
        </Grid>

        {isSelecting && (
          <Grid
            className={styles.popupBottom}
            container
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={2} />

            <Grid item xs={8}>
              <span className={styles.Title}>Selected {deleteNotificationList.length}</span>
            </Grid>

            <Grid item xs={2}>
              <IconButton
                disabled={deleteNotificationList.length === 0}
                aria-label="close"
                onClick={removeNotifications}
              >
                <DeleteForeverIcon color="secondary" cursor="pointer" />
              </IconButton>
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
};

export default Notifications;
