import { ReactElement, useState, useEffect, useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import debounce from 'lodash/debounce';

import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import { IStatusPatientsFilters } from '~/services/api/patients/types';
import Modal from '~/ui/components/common/Modal';
import Table from './Table';

import { IPagePermissions } from '../../types';
import styles from './ActivePatients.module.scss';
import { PatientStatus } from '~/services/api/enums';
import Loader from '~/ui/components/common/Loader';

interface IProps {
  permissions: IPagePermissions;
  onClose: () => void;
}

const ActivePatients = ({ permissions, onClose }: IProps): ReactElement => {
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(true);

  const showError = useStoreActions(actions => actions.snackbar.showError);
  const onGetStatusPatients = useStoreActions(
    actions => actions.statusPatients.onGetStatusPatients,
  );
  const onGetMoreStatusPatients = useStoreActions(
    actions => actions.statusPatients.onGetMoreStatusPatients,
  );
  const patients = useStoreState(state => state.statusPatients.items);

  const onMount = async (payload: IStatusPatientsFilters) => {
    setLoading(true);
    try {
      await onGetStatusPatients(payload);
      setLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const onLoadMore = async () => {
    try {
      await onGetMoreStatusPatients({
        status: PatientStatus.Active,
        fullName: value,
      } as IStatusPatientsFilters);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const onMountDebounced = useCallback(
    debounce(params => onMount(params), 650),
    [],
  );

  useEffect(() => {
    onMountDebounced({ status: 'Active', fullName: value });
  }, [value]);

  return (
    <Modal
      open
      onClose={onClose}
      title="Active"
      maxWidth="lg"
      className={styles.modal}
      contentIdAttribute="scrollableDiv"
    >
      <div className={styles.container}>
        <div className={styles.inputContainer}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            value={value}
            label="Search"
            placeholder="Name"
            onChange={e => setValue(e.target.value)}
          />
        </div>
        <h3 className={styles.subTitle}>Active Patients</h3>
        {loading ? (
          <Loader />
        ) : (
          <Table patients={patients} permissions={permissions} onLoadMore={onLoadMore} />
        )}
      </div>
    </Modal>
  );
};

export default ActivePatients;
