import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkUpdateCarePlanHospitalization } from '../types';

const onUpdateCarePlanHospitalization: IThunkUpdateCarePlanHospitalization = thunk(
  // @ts-ignore
  async ({ updateCarePlanHospitalization }, payload) => {
    const { data } = await api.carePlanHospitalizations.update(payload);
    updateCarePlanHospitalization(data);
  },
);

export default onUpdateCarePlanHospitalization;
