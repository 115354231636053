import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkUpdateQuickNotes } from '../types';

const onUpdateQuickNotes: IThunkUpdateQuickNotes = thunk(
  // @ts-ignore
  async ({ setQuickNotes }, payload) => {
    const { patientId, data } = payload;
    const { quickNotes } = await api.patients
      .updateQuickNotes(patientId, data)
      .then(res => res.data);

    setQuickNotes(quickNotes);
  },
);

export default onUpdateQuickNotes;
