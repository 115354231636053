import { thunk } from 'easy-peasy';
import api from '~/services/api/';
import { IThunkGetUserAssociatedPrograms } from '../types';

const onGetUserAssociatedPrograms: IThunkGetUserAssociatedPrograms = thunk(
  // @ts-ignore
  async ({ setUserAssociatedPrograms, updateUser }, userId) => {
    const programs = await api.users.getAssociatedPrograms(userId).then(res => res.data);
    setUserAssociatedPrograms(programs);
    updateUser({
      id: userId,
      programs,
    });
  },
);

export default onGetUserAssociatedPrograms;
