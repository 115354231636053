import { useState, ReactElement } from 'react';
import { Controller, Control, FieldErrors } from 'react-hook-form';
import get from 'lodash/get';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';

import { isFile } from '~/utils/file';
import FileUpload from '~/ui/components/common/FileUpload';

import styles from './ImageUpload.module.scss';

interface IProps {
  control: Control<any>;
  errors: FieldErrors;
  name: string;
  label?: string;
  dropzoneText?: string;
}

const ImageUpload = ({
  control,
  errors,
  name,
  label = '',
  dropzoneText = '+ Attach image',
}: IProps): ReactElement => {
  const [uploadError, setUploadError] = useState('');
  const error = get(errors, `${name}.message`, '');

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
          <FormControl component="fieldset" className={styles.uploadControl}>
            <FormLabel component="legend" className={styles.label}>
              {label}
            </FormLabel>
            <div className={styles.uploadContainer}>
              {/* Preview */}
              {value ? (
                <div className={styles.previewWrapper}>
                  <img
                    src={isFile(value) ? URL.createObjectURL(value) : value}
                    className={styles.image}
                    alt="Preview"
                  />
                </div>
              ) : (
                <FileUpload
                  dropzoneText={dropzoneText}
                  onUpload={file => {
                    onChange(file);
                    setUploadError('');
                  }}
                  onError={err => setUploadError(err)}
                />
              )}
            </div>
            {value && (
              <div className={styles.removeWrapper}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  className={styles.remove}
                  onClick={() => onChange('')}
                >
                  Remove
                </Button>
              </div>
            )}
            {uploadError && <FormHelperText error>{uploadError}</FormHelperText>}
            {error && <FormHelperText error>{error}</FormHelperText>}
          </FormControl>
        )}
      />
    </>
  );
};

export default ImageUpload;
