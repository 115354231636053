import { useEffect, useState, ReactElement } from 'react';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CheckIcon from '@mui/icons-material/Check';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { useStoreState, useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import Loader from '~/ui/components/common/Loader';
import SmallCard from './cards/SmallCard';
import Card from './cards/Card';
import DevicesCard from './cards/DevicesCard';
import AddProgram from './popups/AddProgram';
import AddUser from './popups/AddUser';
import AddPatient from './popups/AddPatient';
import ActivePrograms from './popups/ActivePrograms';
import ActivePatients from './popups/ActivePatients';
import ApprovedPatients from './popups/ApprovedPatients';
import DischargedPatients from './popups/DischargedPatients';
import DevicesPopup from './popups/DevicesPopup';

import { UserRole, UserPermission } from '~/types';
import styles from './Dashboard.module.scss';

const Dashboard = (): ReactElement => {
  const [isLoading, setIsLoading] = useState(true);
  const [showAddProgram, setShowAddProgram] = useState(false);
  const [showAddUser, setShowAddUser] = useState(false);
  const [showAddPatient, setShowAddPatient] = useState(false);
  const [showActivePrograms, setShowActivePrograms] = useState(false);
  const [showActivePatients, setShowActivePatients] = useState(false);
  const [showApprovedPatients, setShowApprovedPatients] = useState(false);
  const [showDischargedPatients, setShowDischargedPatients] = useState(false);
  const [showDevicesPopup, setShowDevicesPopup] = useState(false);

  const userRole = useStoreState(state => state.auth.currentUser?.roleId);
  const userPermissions = useStoreState(state => state.auth.currentUser?.permissions || []);
  const counters = useStoreState(state => state.dashboard.counters);
  const onGetDashboardCounters = useStoreActions(
    actions => actions.dashboard.onGetDashboardCounters,
  );
  const showError = useStoreActions(actions => actions.snackbar.showError);

  const permissions = {
    canAddUsers:
      userRole !== UserRole.HealthManager || userPermissions.includes(UserPermission.ManageUsers),
    canAddProgram: [UserRole.SystemAdmin, UserRole.SuperAdmin].includes(userRole),
    canAddPatient:
      userRole !== UserRole.HealthManager ||
      [UserPermission.ManagePatients, UserPermission.PatientsEnrollments].some(permission =>
        userPermissions.includes(permission),
      ),
    showPatientsBlock:
      userRole !== UserRole.HealthManager ||
      [UserPermission.ManagePatients, UserPermission.MonitorPatients].some(permission =>
        userPermissions.includes(permission),
      ),
    showDevicesBlock:
      userRole !== UserRole.HealthManager || userPermissions.includes(UserPermission.ManageDevices),
    showDevicesExtraBlock:
      userRole !== UserRole.ProgramAdmin &&
      (userRole !== UserRole.HealthManager ||
        userPermissions.includes(UserPermission.ManageDevices)),
    canManagePatients:
      userRole !== UserRole.HealthManager ||
      userPermissions.includes(UserPermission.ManagePatients),
  };

  const onMount = async () => {
    try {
      await onGetDashboardCounters();
      setIsLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  if (isLoading) return <Loader />;

  return (
    <div className={styles.dashboard}>
      {(permissions.canAddUsers || permissions.canAddProgram || permissions.canAddPatient) && (
        <div className={styles.row}>
          <div className={styles.title}>Quick Actions</div>
          <div className={styles.content}>
            {permissions.canAddUsers && (
              <div className={styles.contentItem}>
                <SmallCard
                  colorType="blue"
                  iconComponent={GroupAddIcon}
                  title="Add User"
                  onClick={() => setShowAddUser(true)}
                />
              </div>
            )}
            {permissions.canAddProgram && (
              <div className={styles.contentItem}>
                <SmallCard
                  colorType="yellow"
                  iconComponent={AddCircleOutlineIcon}
                  title="Add Program"
                  onClick={() => setShowAddProgram(true)}
                />
              </div>
            )}
            {permissions.canAddPatient && (
              <div className={styles.contentItem}>
                <SmallCard
                  colorType="green"
                  iconComponent={GroupAddIcon}
                  title="Patient Enrollment"
                  onClick={() => setShowAddPatient(true)}
                />
              </div>
            )}
          </div>
        </div>
      )}
      <div className={styles.row}>
        <div className={styles.title}>Programs</div>
        <div className={styles.content}>
          <div className={styles.contentItem}>
            <Card
              colorType="blue"
              iconComponent={AccountTreeIcon}
              amount={counters.activeProgramsCount}
              title="Active"
              onClick={() => setShowActivePrograms(true)}
            />
          </div>
        </div>
      </div>
      {permissions.showPatientsBlock && (
        <div className={styles.row}>
          <div className={styles.title}>Patients</div>
          <div className={styles.content}>
            <div className={styles.contentItem}>
              <Card
                colorType="blue"
                iconComponent={CheckIcon}
                amount={counters.activePatientsCount}
                title="Active"
                onClick={() => setShowActivePatients(true)}
              />
            </div>
            <div className={styles.contentItem}>
              <Card
                colorType="green"
                iconComponent={DoneAllIcon}
                amount={counters.approvedPatientsCount}
                title="Approved"
                onClick={() => setShowApprovedPatients(true)}
              />
            </div>
            <div className={styles.contentItem}>
              <Card
                colorType="red"
                iconComponent={HighlightOffIcon}
                amount={counters.dischargedPatientsCount}
                title="Discharged"
                onClick={() => setShowDischargedPatients(true)}
              />
            </div>
          </div>
        </div>
      )}
      {permissions.showDevicesBlock && (
        <div className={styles.row}>
          <div className={styles.title}>Devices</div>
          <div className={styles.content}>
            <div className={styles.contentItem}>
              <DevicesCard
                counters={counters}
                permissions={permissions}
                onClick={() => setShowDevicesPopup(true)}
              />
            </div>
          </div>
        </div>
      )}
      {showAddProgram && <AddProgram onClose={() => setShowAddProgram(false)} />}
      {showAddUser && <AddUser onClose={() => setShowAddUser(false)} />}
      {showAddPatient && <AddPatient onClose={() => setShowAddPatient(false)} />}
      {showActivePrograms && <ActivePrograms onClose={() => setShowActivePrograms(false)} />}
      {showActivePatients && (
        <ActivePatients permissions={permissions} onClose={() => setShowActivePatients(false)} />
      )}
      {showApprovedPatients && (
        <ApprovedPatients
          permissions={permissions}
          onClose={() => setShowApprovedPatients(false)}
        />
      )}
      {showDischargedPatients && (
        <DischargedPatients
          permissions={permissions}
          onClose={() => setShowDischargedPatients(false)}
        />
      )}
      {showDevicesPopup && <DevicesPopup onClose={() => setShowDevicesPopup(false)} />}
    </div>
  );
};

export default Dashboard;
