import { ReactElement } from 'react';
import classnames from 'classnames';

import Logo from './Logo';
import Menu from './Menu';

import styles from './Sidebar.module.scss';

interface IProps {
  isExpanded: boolean;
  patientId: string;
}

const Sidebar = ({ isExpanded, patientId }: IProps): ReactElement => (
  <div className={classnames(styles.sidebar, { [styles.expanded]: isExpanded })}>
    <div className={styles.inner}>
      <Logo isExpanded={isExpanded} />
      <Menu isExpanded={isExpanded} patientId={patientId} />
    </div>
  </div>
);

export default Sidebar;
