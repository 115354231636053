import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkOnAddPatients } from '../types';

const onAddPatients: IThunkOnAddPatients = thunk(async (_actions, payload) => {
  const { id, patients } = payload;
  api.users.addPatients(id, patients);
});

export default onAddPatients;
