import { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useStoreActions, useStoreState } from '~/store/hooks';
import Table from './components/Table';
import Form from './components/Form';
import Loader from '~/ui/components/common/Loader';
import { IFormValues } from './components/Form/Form';
import { IDeviceSelector, IPatientDevice } from '~/services/api/devices/types';
import api from '~/services/api';
import { extractErrorMessage } from '~/utils/error';

type PatientParams = {
  id: string;
};

const PatientDevices = (): ReactElement => {
  const routerParams = useParams<PatientParams>();
  const id = Number(routerParams.id);

  const [loading, setLoading] = useState(true);
  const [patientDevices, setPatientDevices] = useState<IPatientDevice[]>([]);
  const [devices, setDevices] = useState<IDeviceSelector[]>([]);

  const patientInfo = useStoreState(state => state.patient.info);
  const onRegisterDevice = useStoreActions(actions => actions.devices.onRegisterDevice);
  const onGetPatientInfo = useStoreActions(actions => actions.patient.onGetInfo);
  const showError = useStoreActions(actions => actions.snackbar.showError);

  const onMount = async () => {
    try {
      const patientData = await api.patients.getDetails(id).then(res => res.data);

      const selectors = await api.patients.getSelectorsById(id).then(res => res.data);

      setPatientDevices(patientData.devices);
      setDevices(selectors.devices);

      setLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const onSubmit = async (values: IFormValues) => {
    const deviceList = [
      values.bloodPressureDeviceId,
      values.weightScaleDeviceId,
      values.pulseOximeterDeviceId,
      values.bloodGlucoseDeviceId,
    ].filter(deviceId => !!deviceId);

    setLoading(true);
    await onRegisterDevice({ patientId: id, deviceList });
    await onGetPatientInfo(id);
    onMount();
    setLoading(false);
  };

  const disabledForm = patientDevices.length >= 4;

  useEffect(() => {
    onMount();
  }, []);

  return (
    <div>
      {!loading && <Table patient={patientInfo} devices={patientDevices} />}
      {loading ? (
        <Loader />
      ) : (
        <Form
          devices={devices}
          patientDevices={patientDevices}
          disabledForm={disabledForm}
          onSubmit={onSubmit}
        />
      )}
    </div>
  );
};

export default PatientDevices;
