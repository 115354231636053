import { object, string, boolean } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const validationSchema = object().shape({
  content: string().max(1000, 'Max length - 1000 symbols').required('Field is required'),
  isTelephoneConversation: boolean(),
  showReminder: boolean(),
  reminderDate: string()
    .nullable()
    .when('showReminder', {
      is: true,
      then: string().nullable().required('Field is required'),
    }),
});

export default yupResolver(validationSchema);
