import setInsurances from './setInsurances';
import setMoreInsurances from './setMoreInsurances';
import reset from './reset';
import addInsurance from './addInsurance';
import updateInsurance from './updateInsurance';
import deleteInsurance from './deleteInsurance';

export default {
  setInsurances,
  setMoreInsurances,
  reset,
  addInsurance,
  updateInsurance,
  deleteInsurance,
};
