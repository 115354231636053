import setPersonalData from './setPersonalData';
import dischargePatient from './dischargePatient';
import activatePatient from './activatePatient';
import reset from './reset';
import setInfo from './setInfo';
import setOverviewFilters from './setOverviewFilters';
import setQuickNotes from './setQuickNotes';

export default {
  setPersonalData,
  setOverviewFilters,
  setInfo,
  dischargePatient,
  activatePatient,
  reset,
  setQuickNotes,
};
