import { ReactElement } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useStoreState } from '~/store/hooks';
import GeneralLayout from '~/ui/layouts/GeneralLayout';
import PatientLayout from '~/ui/layouts/PatientLayout';
import { SIGN_IN } from '../constants/paths';

/**
 * Render private route
 * Only authenticated users can access to private route
 * Otherwise - redirect user to another allowed page route
 */
const PrivateRoute = ({ component: Component, isPatientLayout, ...rest }: any): ReactElement => {
  const { authorized, authChecked } = useStoreState(state => state.auth);

  if (!authChecked) return null;

  // switch layout type
  const Layout = isPatientLayout ? PatientLayout : GeneralLayout;

  return authorized ? (
    <Route
      {...rest}
      render={routeProps => (
        <Layout>
          <Component {...routeProps} />
        </Layout>
      )}
    />
  ) : (
    <Redirect to={SIGN_IN} />
  );
};

export default PrivateRoute;
