import { useEffect, useState, ReactElement } from 'react';

import { extractErrorMessage } from '~/utils/error';
import { useStoreActions } from '~/store/hooks';
import Header from './Header';
import Table from './Table';
import Modal from '~/ui/components/common/Modal';

import { IFilters } from './Header/types';
import styles from './AssignPatientsModal.module.scss';

interface IProps {
  open: boolean;
  userId: number;
  onClose: () => void;
}

const AssignPatientsModal = ({ userId, onClose, open }: IProps): ReactElement => {
  const [searchName, setSearchName] = useState('');
  const [assignedPatients, setAssignedPatients] = useState([]);

  const { showNotify, showError } = useStoreActions(actions => actions.snackbar);
  const onGetPatients = useStoreActions(actions => actions.patients.onGetPatients);
  const onAddPatients = useStoreActions(actions => actions.users.onAddPatients);

  const assignPatient = (currentPatientId: number) =>
    setAssignedPatients(prev =>
      prev.includes(currentPatientId)
        ? prev.filter(patientId => patientId !== currentPatientId)
        : [...prev, currentPatientId],
    );

  const onSaveAssignedPatients = async () => {
    try {
      const payload = { id: userId, patients: assignedPatients };
      await onAddPatients(payload);
      showNotify('Patients successfully asigned.');
      onClose();
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const onSearch = ({ name }: IFilters) => {
    setSearchName(name);
  };

  useEffect(() => {
    onGetPatients({
      fullName: searchName,
      notAssignedToUserId: userId,
    });
  }, [searchName]);

  return (
    <Modal
      open={open}
      title="Select Patients"
      onClose={onClose}
      className={styles.modal}
      maxWidth="md"
    >
      <>
        <Header onSubmit={onSearch} />
        <Table
          fullName={searchName}
          assignPatient={assignPatient}
          assignedPatients={assignedPatients}
          onSaveAssignedPatients={onSaveAssignedPatients}
          userId={userId}
        />
      </>
    </Modal>
  );
};

export default AssignPatientsModal;
