import { useState, ReactElement } from 'react';
import Modal from '~/ui/components/common/Modal';

import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import initialValues from './form/initialValues';
import InsuranceForm from '~/ui/components/reusable/InsuranceForm';

import { IFormValues } from '~/ui/components/reusable/InsuranceForm/types';
import styles from './AddInsurance.module.scss';
import { IInsurance, IInsurancesSelectors } from '~/services/api/insurances/types';
import { isoToFormat } from '~/utils/date';

interface IProps {
  patientId: number;
  selectors: IInsurancesSelectors;
  onClose: () => void;
}

const AddInsurance = ({ patientId, selectors, onClose }: IProps): ReactElement => {
  const [isAdding, setIsAdding] = useState(false);

  const onCreateInsurance = useStoreActions(actions => actions.insurances.onCreateInsurance);
  const { showNotify, showError } = useStoreActions(actions => actions.snackbar);

  const addInsurance = async (data: IFormValues) => {
    try {
      setIsAdding(true);

      const payload = {
        ...data,
        expiresAt: isoToFormat(data.expiresAt, 'y-MM-dd'),
        authorizationStartDate: isoToFormat(data.authorizationStartDate, 'y-MM-dd'),
        authorizationEndDate: isoToFormat(data.authorizationEndDate, 'y-MM-dd'),
      };

      await onCreateInsurance({ patientId, data: payload as IInsurance });
      showNotify('Insurance successfully added');
      onClose();
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsAdding(false);
    }
  };

  const handleClose = () => {
    // prevent closing popup when insurance is adding
    if (isAdding) return;
    onClose();
  };

  return (
    <Modal open title="Add Insurance" className={styles.modal} onClose={handleClose}>
      <InsuranceForm
        selectors={selectors}
        initialValues={initialValues}
        isProcessing={isAdding}
        onSubmit={addInsurance}
      />
    </Modal>
  );
};

export default AddInsurance;
