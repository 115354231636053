import { useState, ReactElement } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import Loader from '~/ui/components/common/Loader';
import Header from './Header';
import Reading from './Reading';
import Charts from './Charts';

import { getInitialFilters } from './constants';
import { deviceTypeDefinitions } from '~/ui/pages/Dashboard/popups/DevicesPopup/constants';
import { formatToIsoWithTimezone } from '~/utils/date';
import {
  IPatientInfo,
  IPatientOverview,
  IPatientOverviewFilters,
} from '~/services/api/patients/types';
import NoDataFound from '~/ui/components/reusable/NoDataFound';
import { DeviceType } from '~/services/api/enums';

interface IProps {
  patientInfo: IPatientInfo;
}

const Overview = ({ patientInfo }: IProps): ReactElement => {
  const location = useLocation();
  const history = useHistory();

  const queryParams = new URLSearchParams(location.search);

  const [isLoading, setIsLoading] = useState(true);
  const [patientOverview, setPatientOverview] = useState(null as IPatientOverview);
  const [deviceTypeId, setDeviceTypeId] = useState(null as DeviceType);

  const onGetPatientOverview = useStoreActions(actions => actions.patient.onGetOverview);
  const showError = useStoreActions(actions => actions.snackbar.showError);

  const deviceTypeOptions = Object.values(deviceTypeDefinitions).filter(x =>
    patientInfo.deviceTypeIds.includes(x.value),
  );

  if (deviceTypeOptions.length === 0) {
    return <NoDataFound />;
  }

  const queryDeviceTypeId = queryParams.get('deviceTypeId') as DeviceType;

  const defaultInitialFilters = {
    ...getInitialFilters(),
    deviceTypeId: deviceTypeOptions.some(x => x.value === queryDeviceTypeId)
      ? queryDeviceTypeId
      : deviceTypeOptions[0].value,
    startDate: (queryParams.get('startDate') as DeviceType) ?? getInitialFilters().startDate,
    endDate: (queryParams.get('endDate') as DeviceType) ?? getInitialFilters().endDate,
  };

  const getData = async (filters: IPatientOverviewFilters) => {
    try {
      setIsLoading(true);

      history.push({
        pathname: location.pathname,
        search: new URLSearchParams({
          deviceTypeId: filters.deviceTypeId,
          startDate: filters.startDate,
          endDate: filters.endDate,
        }).toString(),
      });

      const payload = {
        patientId: patientInfo.id,
        filters,
        params: {
          ...filters,
          startDate: formatToIsoWithTimezone(filters.startDate, patientInfo.timezone),
          endDate: formatToIsoWithTimezone(filters.endDate, patientInfo.timezone),
        },
      };

      const data = await onGetPatientOverview(payload);

      setPatientOverview(data);
      setDeviceTypeId(filters.deviceTypeId);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Header
        onSubmit={getData}
        deviceTypeOptions={deviceTypeOptions}
        defaultValues={defaultInitialFilters}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Charts patientOverview={patientOverview} deviceTypeId={deviceTypeId} />
          <Reading patientOverview={patientOverview} deviceTypeId={deviceTypeId} />
        </>
      )}
    </>
  );
};

export default Overview;
