import initStore from './initStore';
import actions from './actions';
import thunks from './thunks';

import { IPatientModel } from './types';

const patient: IPatientModel = {
  ...initStore,
  ...actions,
  ...thunks,
};

export default patient;
