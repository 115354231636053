import { ReactNode } from 'react';

import styles from './Popup.module.scss';

interface IProps {
  children: ReactNode;
  onClose: () => void;
}

const Popup = ({ children, onClose }: IProps) => (
  <div className={styles.popup}>
    <div className={styles.container}>{children}</div>
    <div role="presentation" className={styles.overlay} onClick={onClose} />
  </div>
);

export default Popup;
