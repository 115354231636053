import { useState, ReactElement } from 'react';
import sf from 'seconds-formater';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';

import { isoToFormat } from '~/utils/date';
import EditActivityLog from '../../../popups/EditActivityLog';

import { IPagePermissions } from '../../../types';
import styles from './Item.module.scss';
import { IActivityLog } from '~/services/api/activityLogs/types';
import ReadMoreLess from '~/ui/components/common/ReadMoreLess';

interface IProps {
  activityLog: IActivityLog;
  permissions: IPagePermissions;
  userId: number;
  onRemove: (activityLogId: number) => void;
}

const Item = ({ activityLog, permissions, userId, onRemove }: IProps): ReactElement => {
  const [showEditPopup, setShowEditPopup] = useState(false);

  return (
    <TableRow>
      <TableCell>{activityLog.id}</TableCell>
      <TableCell>{`${isoToFormat(activityLog.dateTime, 'dd MMM y H:mm')} ${
        activityLog.patientTimezone
      }`}</TableCell>
      <TableCell className={styles.cell}>{activityLog.userFullName}</TableCell>
      <TableCell>{activityLog.mode}</TableCell>
      <TableCell align="center">{sf.convert(activityLog.secondsSpent).format('MM:SS')}</TableCell>
      <TableCell>
        <ReadMoreLess content={activityLog.note} />
      </TableCell>
      {activityLog.userId === userId || permissions.canManage ? (
        <TableCell width={80}>
          <IconButton className={styles.icon} title="Edit" onClick={() => setShowEditPopup(true)}>
            <CreateIcon />
          </IconButton>
        </TableCell>
      ) : (
        <TableCell />
      )}
      {permissions.canManage && (
        <TableCell align="center" width={80}>
          <IconButton
            className={styles.icon}
            title="Remove"
            onClick={() => onRemove(activityLog.id)}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      )}
      {showEditPopup && (
        <EditActivityLog activityLog={activityLog} onClose={() => setShowEditPopup(false)} />
      )}
    </TableRow>
  );
};

export default Item;
