import { IActivityLogsListFilters } from '~/services/api/activityLogs/types';
import { ActivityLogMode, PatientStatus } from '~/services/api/enums';
import { getInitialDateRange } from '~/utils/date';

const getInitialValues = (): IActivityLogsListFilters => ({
  programId: '',
  fullName: '',
  subjectId: '',
  mode: '' as ActivityLogMode,
  status: '' as PatientStatus,
  ...getInitialDateRange(),
});

export default getInitialValues;
