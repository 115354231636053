export const dayPartTimeInterval = {
  night: {
    start: 20, // 20:00:00
    end: 6, // 5:59:59
  },
  morning: {
    start: 6, // 6:00:00
    end: 11, // 10:59:59
  },
  noon: {
    start: 11, // 11:00:00
    end: 15, // 14:59:59
  },
  evening: {
    start: 15, // 15:00:00
    end: 20, // 19:59:59
  },
};

export default {};
