import { ReactElement, useState } from 'react';
import Loader from '~/ui/components/common/Loader';
import Modal from '~/ui/components/common/Modal';
import MobileUserForm from '~/ui/components/reusable/MobileUserForm';
import { IMobileUser } from '~/services/api/mobileUsers/types';
import getInitialValues from './form/getInitialValues';
import styles from './EditMobileUser.module.scss';
import { IMobileUserFormValues } from '~/ui/components/reusable/MobileUserForm/types';
import { extractErrorMessage } from '~/utils/error';
import { useStoreActions } from '~/store/hooks';

interface IProps {
  mobileUser: IMobileUser;
  onClose: () => void;
}

const EditMobileUser = ({ mobileUser, onClose }: IProps): ReactElement => {
  const [isLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const { onUpdateMobileUser } = useStoreActions(actions => actions.mobileUsers);
  const { showNotify, showError } = useStoreActions(actions => actions.snackbar);

  const updateMobileUserType = async (data: IMobileUserFormValues) => {
    setIsUpdating(true);

    const { isActive, phone } = data;

    const payload: IMobileUser = {
      ...mobileUser,
      isActive,
      phone,
    };

    try {
      await onUpdateMobileUser(payload);

      showNotify('Mobile user successfully updated');
      onClose();
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsUpdating(false);
    }

    onClose();
  };

  const handleClose = () => {
    if (isUpdating) return;
    onClose();
  };

  return (
    <Modal open title="Update Mobile User" className={styles.modal} onClose={handleClose}>
      {isLoading ? (
        <Loader />
      ) : (
        <MobileUserForm
          initialValues={getInitialValues(mobileUser)}
          isProcessing={isUpdating}
          onSubmit={updateMobileUserType}
        />
      )}
    </Modal>
  );
};

export default EditMobileUser;
