import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetCarePlanHospitalizations } from '../types';

const onGetCarePlanHospitalizations: IThunkGetCarePlanHospitalizations = thunk(
  // @ts-ignore
  async ({ setCarePlanHospitalizations }, patientId) => {
    const { data } = await api.carePlanHospitalizations.getList(patientId);
    setCarePlanHospitalizations(data);
  },
);

export default onGetCarePlanHospitalizations;
