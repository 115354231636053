import { useState, ReactElement } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';

import { useStoreState, useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import { isoToFormat } from '~/utils/date';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import EditInsurance from '../../../popups/EditInsurance';

import { ISectionPermissions } from '../../types';
import styles from './Item.module.scss';
import { IInsurance, IInsurancesSelectors } from '~/services/api/insurances/types';
import { Timezone } from '~/types';

interface IProps {
  insurance: IInsurance;
  selectors: IInsurancesSelectors;
  permissions: ISectionPermissions;
}

const Item = ({ insurance, selectors, permissions }: IProps): ReactElement => {
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);

  const userId = useStoreState(state => state.auth.currentUser?.id);

  const onDeleteInsurance = useStoreActions(actions => actions.insurances.onDeleteInsurance);
  const { showNotify, showError } = useStoreActions(actions => actions.snackbar);

  const deleteInsurance = async () => {
    try {
      setIsDeleting(true);
      await onDeleteInsurance(insurance.id);
      showNotify('Insurance removed');
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsDeleting(false);
    }
  };

  return (
    <>
      <TableRow>
        <TableCell>{insurance.groupId}</TableCell>
        <TableCell>{insurance.name}</TableCell>
        <TableCell>{insurance.policyNumber}</TableCell>
        <TableCell>{insurance.type}</TableCell>
        <TableCell>{insurance.plan}</TableCell>
        <TableCell>{insurance.planType}</TableCell>
        <TableCell>{isoToFormat(insurance.expiresAt, 'dd MMM y', Timezone.GMT, false)}</TableCell>
        <TableCell>
          {insurance.isAuthorizationRequired
            ? isoToFormat(insurance.authorizationEndDate, 'dd MMM y', Timezone.GMT, false)
            : ''}
        </TableCell>

        {(userId === insurance.userId || permissions.canManage) && (
          <>
            <TableCell align="center">
              <IconButton
                className={styles.icon}
                title="Edit"
                onClick={() => setShowEditPopup(true)}
              >
                <CreateIcon />
              </IconButton>
            </TableCell>
            <TableCell align="center">
              <IconButton
                className={styles.icon}
                title="Remove"
                onClick={() => setShowDeleteConfirm(true)}
              >
                <DeleteIcon />
              </IconButton>
            </TableCell>
          </>
        )}
      </TableRow>
      {showEditPopup && (
        <EditInsurance
          insurance={insurance}
          selectors={selectors}
          onClose={() => setShowEditPopup(false)}
        />
      )}
      {showDeleteConfirm && (
        <ConfirmModal
          title="Remove"
          description="Are you sure you want to remove current insurance?"
          isLoading={isDeleting}
          onClose={() => setShowDeleteConfirm(false)}
          onConfirm={deleteInsurance}
        />
      )}
    </>
  );
};

export default Item;
