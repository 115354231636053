import * as Highcharts from 'highcharts';
import { DeviceType } from '~/services/api/enums';
import { IReading } from '~/services/api/readings/types';
import { roundNumber } from '~/utils/format';
import { average, getRawData, maxByModule, minByModule } from '../helper';
import { categories, ChartColor } from '../types';

const getYAxis = (deviceTypeId: DeviceType): Highcharts.YAxisOptions[] => {
  const defaultAxis = [
    {
      title: null,
    },
  ] as Highcharts.YAxisOptions[];

  const weightScaleAxis = [
    {
      title: null,
    },
    {
      title: null,
      opposite: true,
    },
  ] as Highcharts.YAxisOptions[];

  return deviceTypeId === DeviceType.WeightScale ? weightScaleAxis : defaultAxis;
};

const getSeries = (
  deviceTypeId: DeviceType,
  data: number[][],
): Highcharts.SeriesColumnOptions[] => {
  data.forEach((array, i) => {
    data[i] = array.filter(x => x != null);
  });

  const defaultSeries = [
    {
      name: 'Minimum',
      type: 'column',
      data: data.map(array => Math.min(...array)),
      color: ChartColor.Blue,
    },
    {
      name: 'Average',
      type: 'column',
      data: data.map(array => roundNumber(average(array))),
      color: ChartColor.Green,
    },
    {
      name: 'Maximum',
      type: 'column',
      data: data.map(array => Math.max(...array)),
      color: ChartColor.Red,
    },
  ] as Highcharts.SeriesColumnOptions[];

  const weightScaleSeries = [
    {
      id: 'minimum',
      name: 'Minimum',
      type: 'column',
      data: data.slice(0, 1).map(array => Math.min(...array)),
      color: ChartColor.Blue,
      centerInCategory: true,
      groupPadding: 0,
    },
    {
      id: 'average',
      name: 'Average',
      type: 'column',
      data: data.slice(0, 1).map(array => roundNumber(average(array))),
      color: ChartColor.Green,
      centerInCategory: true,
      groupPadding: 0,
    },
    {
      id: 'maximum',
      name: 'Maximum',
      type: 'column',
      data: data.slice(0, 1).map(array => Math.max(...array)),
      color: ChartColor.Red,
      centerInCategory: true,
      groupPadding: 0,
    },
    {
      linkedTo: 'minimum',
      name: 'Minimum',
      type: 'column',
      data: [null, ...data.slice(1).map(array => (array.length > 0 ? minByModule(array) : 0))],
      color: ChartColor.Blue,
      yAxis: 1,
      centerInCategory: true,
      groupPadding: 0,
    },
    {
      linkedTo: 'average',
      name: 'Average',
      type: 'column',
      data: [null, ...data.slice(1).map(array => roundNumber(average(array)))],
      color: ChartColor.Green,
      yAxis: 1,
      centerInCategory: true,
      groupPadding: 0,
    },
    {
      linkedTo: 'maximum',
      name: 'Maximum',
      type: 'column',
      data: [null, ...data.slice(1).map(array => (array.length > 0 ? maxByModule(array) : 0))],
      color: ChartColor.Red,
      yAxis: 1,
      centerInCategory: true,
      groupPadding: 0,
    },
  ] as Highcharts.SeriesColumnOptions[];

  return deviceTypeId === DeviceType.WeightScale ? weightScaleSeries : defaultSeries;
};

export const getChartOptions = (
  readings: IReading[],
  deviceTypeId: DeviceType,
): Highcharts.Options => {
  const data = getRawData(readings, deviceTypeId);

  return {
    chart: {
      type: 'column',
      marginTop: 25,
    },
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: categories[deviceTypeId],
    },
    yAxis: getYAxis(deviceTypeId),
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
        },
      },
    },
    series: getSeries(deviceTypeId, data),
  };
};

export default {};
