import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkAddPrograms } from '../types';

const onAddPrograms: IThunkAddPrograms = thunk(async (_actions, payload) => {
  const { id, programs } = payload;
  await api.users.addPrograms(id, programs);
});

export default onAddPrograms;
