import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { getPaginationPayload } from '~/utils/pagination';
import { IThunkGetStatusPatients, IStatusPatientsStore } from '../types';

const onGetStatusPatients: IThunkGetStatusPatients = thunk(
  // @ts-ignore
  async ({ setStatusPatients, reset }, payload, { getState }) => {
    reset();
    const localState = getState() as IStatusPatientsStore;
    const paginationPayload = getPaginationPayload(localState.pagination);

    const { data } = await api.patients.getByStatus({
      ...payload,
      ...paginationPayload,
    });

    setStatusPatients(data);
  },
);

export default onGetStatusPatients;
