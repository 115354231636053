import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkRemoveCarePlanHospitalization } from '../types';

const onRemoveCarePlanHospitalization: IThunkRemoveCarePlanHospitalization = thunk(
  // @ts-ignore
  async ({ removeCarePlanHospitalization }, id) => {
    await api.carePlanHospitalizations.remove(id);
    removeCarePlanHospitalization(id);
  },
);

export default onRemoveCarePlanHospitalization;
