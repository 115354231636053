import { action } from 'easy-peasy';
import initStore from '../initStore';
import { IActionReset } from '../types';

// reset users store
const reset: IActionReset = action(state => {
  const { items, itemsPagination, item, userAssociatedPrograms } = initStore;

  state.items = items;
  state.itemsPagination = itemsPagination;
  state.item = item;
  state.userAssociatedPrograms = userAssociatedPrograms;
});

export default reset;
