export interface IActions {
  // key - Action, value - devicesIds
  [key: string]: number[];
}

export enum DeviceAction {
  Assign = 'Assign',
  Unassign = 'Unassign',
  Unregister = 'Unregister',
  MarkAsReturned = 'MarkAsReturned',
  MarkAsLost = 'MarkAsLost',
  Delete = 'MarkAsDelete',
}

export interface IAction {
  type: DeviceAction;
  devicesIds: number[];
}
