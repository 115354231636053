import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkDeleteNotifications } from '../types';

const onDeleteNotifications: IThunkDeleteNotifications = thunk(
  // @ts-ignore
  async ({ deleteNotifications }, ids) => {
    await api.notifications.deleteNotification(ids).then(() => {
      deleteNotifications(ids);
    });
  },
);

export default onDeleteNotifications;
