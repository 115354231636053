import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkDeleteActivityLog } from '../types';

// @ts-ignore
const onDeleteActivityLog: IThunkDeleteActivityLog = thunk(async ({ deleteActivityLog }, id) => {
  await api.activityLogs.remove(id).then(res => res.data);
  deleteActivityLog(id);
});

export default onDeleteActivityLog;
