import axios from 'axios';
import { IPagedFilters, IResponse } from '../types';
import { INotification, INotificationCount } from './types';

const apiUrl = 'notifications';

export const getList = (params: IPagedFilters): IResponse<INotification[]> =>
  axios({
    method: 'get',
    url: apiUrl,
    params,
  });

export const getCount = (): IResponse<INotificationCount> =>
  axios({
    method: 'get',
    url: `${apiUrl}/unread-count`,
  });

export const markAsRead = (ids: number[]): IResponse<void> =>
  axios({
    method: 'post',
    url: `${apiUrl}/mark-as-read`,
    data: ids,
  });

export const deleteNotification = (ids: number[]): IResponse<void> =>
  axios({
    method: 'delete',
    url: apiUrl,
    data: ids,
  });
