import { ReactElement, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';

import validate from './form/validate';
import Button from '~/ui/components/common/Button';
import Input from '~/ui/components/inputs/Input';
import Select from '~/ui/components/inputs/Select';
import DatePicker from '~/ui/components/inputs/DatePicker';
import Checkbox from '~/ui/components/inputs/Checkbox';

import { insuranceTypeOptions, insurancePlanOptions, planTypeOptions } from './constants';
import { IFormValues } from './types';
import styles from './InsuranceForm.module.scss';
import { IInsurancesSelectors } from '~/services/api/insurances/types';

interface IProps {
  selectors: IInsurancesSelectors;
  initialValues: IFormValues;
  isProcessing: boolean;
  onSubmit: (data: IFormValues) => void;
}

const InsuranceForm = ({
  selectors,
  initialValues,
  isProcessing,
  onSubmit,
}: IProps): ReactElement => {
  const {
    register,
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormValues>({
    resolver: validate,
    defaultValues: initialValues,
  });

  const isAuthorizationRequired = watch('isAuthorizationRequired');

  const insuranceNameOptions = useMemo(
    () =>
      selectors.names.map(item => ({
        value: item.id,
        label: item.name,
      })),
    [selectors],
  );

  useEffect(() => {
    // Reset "authorizationStartDate" and "authorizationEndDate" fields when uncheck the "isAuthorizationRequired" checkbox
    if (!isAuthorizationRequired) {
      setValue('authorizationStartDate', null);
      setValue('authorizationEndDate', null);
    }
  }, [isAuthorizationRequired]);

  return (
    <form className={styles.formInsurance} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Select
            name="nameId"
            control={control}
            errors={errors}
            options={insuranceNameOptions}
            label="Name *"
          />
        </Grid>
        <Grid item xs={4}>
          <Input name="groupId" register={register} errors={errors} label="Group Id" />
        </Grid>
        <Grid item xs={4}>
          <Input name="policyNumber" register={register} errors={errors} label="Policy Number" />
        </Grid>
        <Grid item xs={4}>
          <Select
            name="type"
            control={control}
            errors={errors}
            options={insuranceTypeOptions}
            label="Insurance Type *"
          />
        </Grid>
        <Grid item xs={4}>
          <Select
            name="plan"
            control={control}
            errors={errors}
            options={insurancePlanOptions}
            label="Insurance Plan"
          />
        </Grid>
        <Grid item xs={4}>
          <Select
            name="planType"
            control={control}
            errors={errors}
            options={planTypeOptions}
            label="Plan Type"
          />
        </Grid>
        <Grid item xs={4}>
          <DatePicker name="expiresAt" control={control} errors={errors} label="Expiry Date *" />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Checkbox
            name="isAuthorizationRequired"
            control={control}
            errors={errors}
            label="Authorization Required?"
          />
        </Grid>
        {isAuthorizationRequired && (
          <>
            <Grid item xs>
              <DatePicker
                placeholder=".. /.. /.."
                label="Authorization From *"
                name="authorizationStartDate"
                control={control}
                errors={errors}
              />
            </Grid>
            <Grid item xs>
              <DatePicker
                placeholder=".. /.. /.."
                label="Authorization Valid Through *"
                name="authorizationEndDate"
                control={control}
                errors={errors}
              />
            </Grid>
          </>
        )}
      </Grid>
      <div className={styles.btnWrapper}>
        <Button
          type="submit"
          size="large"
          variant="contained"
          color="primary"
          isLoading={isProcessing}
          disabled={isProcessing}
        >
          Save
        </Button>
      </div>
    </form>
  );
};

export default InsuranceForm;
