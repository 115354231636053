const DURATION_STORAGE_NAME = 'seconds';

export const getCounterDurationSec = (): number => {
  const duration = localStorage.getItem(DURATION_STORAGE_NAME);
  if (!duration) return 0;
  return Number(duration);
};

export const setCounterDurationSec = (sec: number): void => {
  localStorage.setItem(DURATION_STORAGE_NAME, String(sec));
};

export const resetCounterDuration = (): void => {
  setCounterDurationSec(0);
};
