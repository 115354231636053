import { ReactElement } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import Loader from '~/ui/components/common/Loader';
import NoDataFound from '~/ui/components/reusable/NoDataFound';
import Item from './Item';

import { IPagePermissions } from '../../types';
import { INote } from '~/services/api/notes/types';

interface IProps {
  notes: INote[];
  userId: number;
  isLoading: boolean;
  hasMore: boolean;
  permissions: IPagePermissions;
  onLoadMore: () => void;
  onUpdateNote: (note: INote) => void;
  onDeleteNote: (noteId: number) => void;
}

const List = ({
  notes,
  userId,
  isLoading,
  hasMore,
  permissions,
  onUpdateNote,
  onDeleteNote,
  onLoadMore,
}: IProps): ReactElement =>
  isLoading ? (
    <Loader />
  ) : (
    <InfiniteScroll
      loader={<Loader />}
      dataLength={notes.length}
      hasMore={hasMore}
      next={onLoadMore}
    >
      {notes.length ? (
        notes.map(note => (
          <Item
            key={note.id}
            note={note}
            userId={userId}
            permissions={permissions}
            onUpdateNote={onUpdateNote}
            onDeleteNote={onDeleteNote}
          />
        ))
      ) : (
        <NoDataFound />
      )}
    </InfiniteScroll>
  );

export default List;
