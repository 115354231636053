export interface ICell {
  id: string;
  label: string;
  width?: number;
  align?: any;
}

export const headCells: ICell[] = [
  { id: 'name', label: 'Name' },
  { id: 'activePatientsCount', label: 'Active Patients', width: 180, align: 'center' },
];

export default {};
