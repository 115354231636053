import { IAuditLogsListFilters } from '~/services/api/auditLogs/types';
import { getEndOfTodayDate, getYesterdayDate } from '~/utils/date';

const getInitialFilters = (): IAuditLogsListFilters => ({
  startDate: getYesterdayDate(),
  endDate: getEndOfTodayDate(),
  userId: '',
  programId: '',
  areaId: '',
  eventId: '',
});

export default getInitialFilters;
