import { createTheme } from '@material-ui/core/styles';

const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#1e89c2',
    },
    secondary: {
      main: '#c54326',
    },
  },
  overrides: {
    MuiPaper: {
      elevation24: {
        boxShadow: '0px 7px 29px 0px rgba(100, 100, 111, 0.2)',
      },
    },
    MuiTableContainer: {
      root: {
        border: '1px solid #c6d0df',
        borderRadius: '4px 4px 0 0',
      },
    },
    MuiTableRow: {
      root: {
        '&:nth-of-type(even)': {
          backgroundColor: '#f7f9fa',
        },
      },
      head: {
        backgroundColor: '#90a2b4',
      },
    },
    MuiTableCell: {
      root: {
        padding: '8px 12px',
      },
      head: {
        color: '#fff',
      },
    },
    MuiTableSortLabel: {
      root: {
        color: '#fff!important',
      },
      icon: {
        color: '#fff!important',
      },
    },
    MuiInputBase: {
      root: {
        '&$disabled': {
          backgroundColor: '#f5f5f5!important',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&.Mui-error': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
          },
        },
      },
    },
    MuiFormHelperText: {
      root: {
        marginLeft: '0px!important',
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: 'transparent',
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        backgroundColor: '#fff',
        '&$selected': {
          color: '#1e89c2',
          borderRadius: '5px 5px 0 0',
          boxShadow: 'rgb(100 100 111 / 20%) 0px 7px 29px 0px',
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: 15,
      },
    },
    MuiSwitch: {
      switchBase: {
        position: 'absolute',
      },
    },
  },
});

export default muiTheme;
