import { useState, useEffect, ReactElement } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { useStoreState, useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import Modal from '~/ui/components/common/Modal';
import Loader from '~/ui/components/common/Loader';
import AssociatedProgramsTable from './componets/AssociatedProgramsTable';
import AvailableProgramsTable from './componets/AvailableProgramsTable';
import styles from './ProgramModal.module.scss';

interface IProps {
  open: boolean;
  onClose: () => void;
  userId: number;
}

const ProgramModal = ({ open, onClose, userId }: IProps): ReactElement => {
  const [loading, setLoading] = useState(true);

  const { items: availablePrograms, pagination } = useStoreState(state => state.programs);
  const associatedPrograms = useStoreState(state => state.users.userAssociatedPrograms);

  const showError = useStoreActions(actions => actions.snackbar.showError);
  const { onGetPrograms, onGetMorePrograms } = useStoreActions(actions => actions.programs);
  const onGetUserAssociatedPrograms = useStoreActions(
    actions => actions.users.onGetUserAssociatedPrograms,
  );

  const availableProgramsPayload = { notAssignedToUserId: userId };

  const getMoreAvailablePrograms = async () => {
    try {
      await onGetMorePrograms(availableProgramsPayload);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const onMount = async () => {
    try {
      await Promise.all([
        onGetPrograms(availableProgramsPayload),
        onGetUserAssociatedPrograms(userId),
      ]);
      setLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  return (
    <Modal
      contentIdAttribute="scrollableDiv"
      open={open}
      title="Programs"
      onClose={onClose}
      maxWidth="md"
      className={styles.modal}
    >
      {loading ? (
        <Loader />
      ) : (
        <div>
          <h3>Associated Programs</h3>
          <AssociatedProgramsTable
            currentUserId={userId}
            associatedPrograms={associatedPrograms}
            onRefresh={onMount}
          />
          <h3>Available Programs</h3>
          <InfiniteScroll
            dataLength={availablePrograms.length}
            next={getMoreAvailablePrograms}
            hasMore={pagination.hasMore}
            loader={<Loader />}
            scrollableTarget="scrollableDiv"
          >
            <AvailableProgramsTable
              currentUserId={userId}
              availablePrograms={availablePrograms}
              onRefresh={onMount}
            />
          </InfiniteScroll>
        </div>
      )}
    </Modal>
  );
};

export default ProgramModal;
