import * as users from './users';
import * as programs from './programs';
import * as files from './files';
import * as patients from './patients';
import * as devices from './devices';
import * as activityLogs from './activityLogs';
import * as reports from './reports';
import * as dashboard from './dashboard';
import * as notes from './notes';
import * as insurances from './insurances';
import * as medications from './medications';
import * as auditLogs from './auditLogs';
import * as auth from './auth';
import * as patientNotificationRules from './patientNotificationRules';
import * as mobileUsers from './mobileUsers';
import * as notifications from './notifications';
import * as icd10Codes from './icd10Codes';
import * as carePlanIcd10Codes from './carePlanIcd10Codes';
import * as carePlanMedications from './carePlanMedications';
import * as carePlanHospitalizations from './carePlanHospitalizations';
import * as tableCustomizations from './tableCustomizations';

export default {
  users,
  programs,
  files,
  patients,
  devices,
  activityLogs,
  reports,
  dashboard,
  notes,
  insurances,
  medications,
  auditLogs,
  auth,
  patientNotificationRules,
  mobileUsers,
  notifications,
  icd10Codes,
  carePlanIcd10Codes,
  carePlanMedications,
  carePlanHospitalizations,
  tableCustomizations,
};
