import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkCreateCarePlanHospitalization } from '../types';

const onCreateCarePlanHospitalization: IThunkCreateCarePlanHospitalization = thunk(
  // @ts-ignore
  async ({ addCarePlanHospitalization }, payload) => {
    const { patientId, data } = payload;
    const Hospitalization = await api.carePlanHospitalizations
      .create(patientId, data)
      .then(res => res.data);
    addCarePlanHospitalization(Hospitalization);
  },
);

export default onCreateCarePlanHospitalization;
