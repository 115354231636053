export enum ActionType {
  Home = 'Home',
  Profile = 'Profile',
  LogOut = 'LogOut',
  Patient = 'Patient',
  MobileUsers = 'MobileUsers',
  Notes = 'Notes',
}

export default {};
