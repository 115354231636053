import { ITab } from '~/ui/components/reusable/VerticalTabs/types';
import { Tab } from './types';
import { UserRole } from '~/types';

export const tabs: ITab[] = [
  {
    value: Tab.ReadingLogs,
    label: 'Reading Logs',
    roles: [],
  },
  {
    value: Tab.PatientSummary,
    label: 'Patient Summary',
    roles: [],
  },
  {
    value: Tab.BillingReport,
    label: 'Billing Report',
    roles: [],
  },
  {
    value: Tab.PayrollReport,
    label: 'Payroll Report',
    roles: [UserRole.SystemAdmin],
  },
];

export default {};
