import { ReactElement } from 'react';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Loader from '~/ui/components/common/Loader';
import NoDataFound from '~/ui/components/reusable/NoDataFound';
import { IMobileUser } from '~/services/api/mobileUsers/types';
import Item from './Item/Item';

interface IProps {
  mobileUsers: IMobileUser[];
  isLoading: boolean;
}

const TableComponent = ({ mobileUsers, isLoading }: IProps): ReactElement => {
  if (isLoading) return <Loader />;

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Mobile Number</TableCell>
            <TableCell>User Type</TableCell>
            <TableCell>Status</TableCell>
            <TableCell align="center">T&C/HIPAA</TableCell>
            <TableCell />
            <TableCell>Edit</TableCell>
            <TableCell align="center">Remove</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mobileUsers.length ? (
            mobileUsers.map(item => <Item key={item.id} mobileUser={item} />)
          ) : (
            <TableRow>
              <TableCell colSpan={7}>
                <NoDataFound />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableComponent;
