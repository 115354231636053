import { useState, ReactElement } from 'react';
import Modal from '~/ui/components/common/Modal';

import api from '~/services/api';
import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import {
  getCounterDurationSec,
  resetCounterDuration,
} from '~/ui/components/reusable/ActivityCounter/helper';
import ActivityLogOnlineForm from '~/ui/components/reusable/ActivityLogOnlineForm';

import { ActivityLogMode } from '~/services/api/enums';
import { IFormValues } from '~/ui/components/reusable/ActivityLogOnlineForm/types';
import styles from './AddActivityLogOnline.module.scss';
import { IActivityLog } from '~/services/api/activityLogs/types';

interface IProps {
  patientId: number;
  onClose: () => void;
  onFinish: () => void;
}

const AddActivityLogOnline = ({ patientId, onClose, onFinish }: IProps): ReactElement => {
  const [isLogAdding, setIsLogAdding] = useState(false);

  const showError = useStoreActions(actions => actions.snackbar.showError);

  const sendActivityLog = async ({ note }: IFormValues) => {
    const secondsSpent = getCounterDurationSec();

    const data = {
      mode: ActivityLogMode.Online,
      dateTime: new Date().toISOString(),
      secondsSpent,
      note,
    };

    try {
      setIsLogAdding(true);
      await api.activityLogs.create(patientId, data as IActivityLog);
      resetCounterDuration();
      onFinish();
    } catch (e) {
      showError(extractErrorMessage(e));
      setIsLogAdding(false);
    }
  };

  return (
    <Modal
      open
      closeOnlyByCross
      title="Patient Interaction"
      className={styles.modal}
      onClose={onClose}
    >
      <ActivityLogOnlineForm
        initialValues={{ note: '' }}
        isProcessing={isLogAdding}
        onSubmit={sendActivityLog}
      />
    </Modal>
  );
};

export default AddActivityLogOnline;
