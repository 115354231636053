import initStore from './initStore';
import actions from './actions';
import thunks from './thunks';

import { IActivityLogsModel } from './types';

const activityLogs: IActivityLogsModel = {
  ...initStore,
  ...actions,
  ...thunks,
};

export default activityLogs;
