import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { getPaginationPayload } from '~/utils/pagination';
import { IPatientsStore, IThunkGetMorePatients } from '../types';

const onGetMorePatients: IThunkGetMorePatients = thunk(
  // @ts-ignore
  async ({ setMorePatients }, filters, { getState }) => {
    const localState = getState() as IPatientsStore;

    const paginationPayload = getPaginationPayload(localState.pagination);

    const { data } = await api.patients.getList({
      ...filters,
      ...paginationPayload,
    });

    setMorePatients(data);
  },
);

export default onGetMorePatients;
