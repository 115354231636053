import { Day } from '~/types';

interface IDaysMap {
  [key: number]: Day;
}

export const daysMap: IDaysMap = {
  0: Day.Sunday,
  1: Day.Monday,
  2: Day.Tuesday,
  3: Day.Wednesday,
  4: Day.Thursday,
  5: Day.Friday,
  6: Day.Saturday,
};

export default {};
