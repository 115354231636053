export const patientStatusOptions = [
  {
    value: '',
    label: 'All Statuses',
  },
  {
    value: 'Approved',
    label: 'Approved',
  },
  {
    value: 'Active',
    label: 'Active',
  },
  {
    value: 'Discharged',
    label: 'Discharged',
  },
];

export default {};
