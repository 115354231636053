import setPatientNotificationRules from './setPatientNotificationRules';
import reset from './reset';
import addPatientNotificationRule from './addPatientNotificationRule';
import deletePatientNotificationRule from './deletePatientNotificationRule';

export default {
  setPatientNotificationRules,
  reset,
  addPatientNotificationRule,
  deletePatientNotificationRule,
};
