import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkGetNotifyCount } from '../types';

const onGetNotifyCount: IThunkGetNotifyCount = thunk(
  // @ts-ignore
  async ({ setNotifyCount }) => {
    api.notifications.getCount().then(response => {
      setNotifyCount(response.data);
    });
  },
);

export default onGetNotifyCount;
