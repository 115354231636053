import deleteNotifications from './deleteNotifications';
import reset from './reset';
import setMoreNotifications from './setMoreNotifications';
import setNotifications from './setNotifications';
import setNotifyCount from './setNotifyCount';

export default {
  setNotifications,
  reset,
  deleteNotifications,
  setNotifyCount,
  setMoreNotifications,
};
