import setCarePlanMedications from './setCarePlanMedications';
import addCarePlanMedication from './addCarePlanMedication';
import updateCarePlanMedication from './updateCarePlanMedication';
import removeCarePlanMedication from './removeCarePlanMedication';

export default {
  setCarePlanMedications,
  addCarePlanMedication,
  updateCarePlanMedication,
  removeCarePlanMedication,
};
