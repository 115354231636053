import { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';

import { useStoreState } from '~/store/hooks';
import { getGeneralMenuItems, getPatientMenuItems } from '~/ui/constants/sidebarMenuItems';
import styles from './Menu.module.scss';

interface IProps {
  isExpanded: boolean;
  patientId: string;
}

const Menu = ({ isExpanded, patientId }: IProps): ReactElement => {
  const isPatientLayout = Boolean(patientId);

  const userRole = useStoreState(state => state.auth.currentUser?.roleId);
  const userPermissions = useStoreState(state => state.auth.currentUser?.permissions || []);

  const items = isPatientLayout
    ? getPatientMenuItems(patientId)
    : getGeneralMenuItems({ userRole, userPermissions });

  return (
    <ul className={classnames(styles.menu, { [styles.patientMenu]: isPatientLayout })}>
      {items.map(({ url, title, icon: Icon }) => (
        <li key={url}>
          <NavLink to={url} className={styles.link} activeClassName={styles.active} title={title}>
            {isExpanded && <div className={styles.spacer} />}
            <div className={classnames(styles.iconWrapper, { [styles.expanded]: isExpanded })}>
              <Icon className={styles.icon} />
            </div>
            {isExpanded && <span className={styles.title}>{title}</span>}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};

export default Menu;
