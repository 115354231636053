import { ReactElement } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@mui/icons-material/Search';
import RotateRightIcon from '@mui/icons-material/RotateRight';

import { getInitialValues, validate } from './form';
import Button from '~/ui/components/common/Button';
import DatePicker from '~/ui/components/inputs/DatePicker';

import styles from './Header.module.scss';
import { IPatientInfo } from '~/services/api/patients/types';
import { IActivityLogsListFilters } from '~/services/api/activityLogs/types';

interface IProps {
  patientInfo: IPatientInfo;
  onSubmit: (filters: IActivityLogsListFilters, patientInfo: IPatientInfo) => void;
}

const Header = ({ patientInfo, onSubmit }: IProps): ReactElement => {
  const formMethods = useForm<IActivityLogsListFilters>({
    resolver: validate,
    defaultValues: getInitialValues(),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = formMethods;

  const onSubmitForm = (formValues: IActivityLogsListFilters) => {
    onSubmit(formValues, patientInfo);
  };

  return (
    <FormProvider {...formMethods}>
      <form className={styles.header} onSubmit={handleSubmit(onSubmitForm)}>
        <Grid container spacing={2}>
          <Grid item xs>
            <DatePicker
              placeholder=".. /.. /.."
              label="Start Date"
              name="startDate"
              maxDate={new Date()}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item xs>
            <DatePicker
              placeholder=".. /.. /.."
              label="End Date"
              name="endDate"
              maxDate={new Date()}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item>
            <Button variant="outlined" type="submit" color="primary" startIcon={<SearchIcon />}>
              Search
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              type="button"
              startIcon={<RotateRightIcon />}
              onClick={() => {
                reset(getInitialValues());
                onSubmitForm(getInitialValues());
              }}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default Header;
