import { Control, Controller, FieldErrors, get } from 'react-hook-form';
import { FormControl, FormHelperText } from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import styles from './PhoneFlagInput.module.scss';

interface IProps {
  control: Control<any>;
  name: string;
  errors: FieldErrors;
  disabled?: boolean;
  label?: string;
  onInputBlur?: (data: string) => void;
}

const PhoneFlagInput = ({
  control,
  errors,
  name,
  onInputBlur = () => {},
  label,
  disabled = false,
}: IProps) => {
  const error = get(errors, `${name}.message`, '');

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: true }}
      render={({ field: { ref, ...field } }) => (
        <FormControl component="fieldset" className={styles.phoneControl}>
          <PhoneInput
            country="us"
            regions={['north-america', 'europe']}
            {...field}
            disabled={disabled}
            specialLabel={label}
            onBlur={() => onInputBlur?.(field.value)}
          />
          {error && <FormHelperText error>{error}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};

export default PhoneFlagInput;
