import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';

import { DASHBOARD } from '~/ui/constants/paths';
import notFoundImage from '~/ui/assets/images/404-not-found.jpg';
import styles from './NotFoundPage.module.scss';

interface IProps {
  title?: string;
}

const NotFoundPage = ({ title = 'Page Not Found' }: IProps): ReactElement => (
  <div className={styles.accessDenied}>
    <img src={notFoundImage} className={styles.image} alt="Not Found" />
    <div className={styles.title}>{title}</div>
    <Link to={DASHBOARD} className={styles.link}>
      <Button color="primary" variant="contained" size="large">
        Home
      </Button>
    </Link>
  </div>
);

export default NotFoundPage;
