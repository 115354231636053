const ethnicityOptions = [
  {
    value: null,
    label: '--None--',
  },
  {
    value: 'AfricanAmerican/Black',
    label: 'African American/Black',
  },
  {
    value: 'Asian',
    label: 'Asian',
  },
  {
    value: 'Caucasian/White',
    label: 'Caucasian/White',
  },
  {
    value: 'Hispanic/Latino',
    label: 'Hispanic/Latino',
  },
  {
    value: 'Others',
    label: 'Others',
  },
];

export default ethnicityOptions;
