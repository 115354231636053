import setUsers from './setUsers';
import setMoreUsers from './setMoreUsers';
import setUser from './setUser';
import updateUser from './updateUser';
import removeUser from './removeUser';
import setUserAssociatedPrograms from './setUserAssociatedPrograms';
import reset from './reset';

export default {
  setUsers,
  setMoreUsers,
  setUser,
  updateUser,
  removeUser,
  setUserAssociatedPrograms,
  reset,
};
