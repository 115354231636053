import { useState, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@mui/icons-material/Info';

import api from '~/services/api';
import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import InfoMenu from './InfoMenu';
import BloodPressure from '~/ui/components/reusable/Reading/BloodPressure';
import BloodGlucose from '~/ui/components/reusable/Reading/BloodGlucose';
import PulseOximeter from '~/ui/components/reusable/Reading/PulseOximeter';
import WeightScale from '~/ui/components/reusable/Reading/WeightScale';
import ActivatePatient from '~/ui/components/reusable/ActivatePatient';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import InfoModal from '../../popups/InfoModal';
import NotesModal from '../../popups/NotesModal';

import { PATIENT_DASHBOARD } from '~/ui/constants/paths';
import { IPatientList } from '~/services/api/patients/types';
import { IPagePermissions } from '../../types';

import styles from './Item.module.scss';
import { ITableCustomization } from '~/services/api/tableCustomizations/types';

interface IProps {
  patient: IPatientList;
  tableCustomizations: ITableCustomization[];
  permissions: IPagePermissions;
  openLogActivitiesSetPatient: (patientId: number) => void;
  onRemove: (id: number) => void;
}

const Item = ({
  patient,
  tableCustomizations,
  permissions,
  openLogActivitiesSetPatient,
  onRemove,
}: IProps): ReactElement => {
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [showNotesPopup, setShowNotesPopup] = useState(false);
  const [showActivatePopup, setShowActivatePopup] = useState(false);
  const [isActivating, setIsActivating] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const onActivatePatient = useStoreActions(actions => actions.patients.onActivatePatient);
  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);

  const handleActivate = async (deviceId: number) => {
    try {
      setIsActivating(true);
      await onActivatePatient(patient.id);
      await api.devices.registerDevices(patient.id, [deviceId]);
      showNotify('Patient activated');
      setShowActivatePopup(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsActivating(false);
    }
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <div className={styles.infoBlock}>
            <IconButton
              className={classnames(styles.icon, styles.infoIcon)}
              title="Info"
              onClick={() => setShowInfoPopup(true)}
            >
              <InfoIcon />
            </IconButton>
            <div className={styles.alertsCount}>{patient.notificationsCount}</div>
            <InfoMenu
              permissions={permissions}
              patient={patient}
              onShowNotesPopup={() => setShowNotesPopup(true)}
              onShowActivatePopup={() => setShowActivatePopup(true)}
              openLogActivitiesSetPatient={() => openLogActivitiesSetPatient(patient.id)}
              onShowDeletePopup={() => setShowDeleteModal(true)}
            />
          </div>
        </TableCell>
        {tableCustomizations
          .filter(x => x.isVisible)
          .map(tableCustomization => {
            switch (tableCustomization.columnKey) {
              case 'fullName':
                return (
                  <TableCell key={tableCustomization.columnKey} className={styles.cell}>
                    {permissions.canManagePatients ? (
                      <Link
                        to={PATIENT_DASHBOARD.replace(':id', String(patient.id))}
                        className={styles.link}
                      >
                        {patient.fullName}
                      </Link>
                    ) : (
                      patient.fullName
                    )}
                  </TableCell>
                );
              case 'subjectId':
                return (
                  <TableCell key={tableCustomization.columnKey} className={styles.cell}>
                    {patient.subjectId}
                  </TableCell>
                );
              case 'programName':
                return (
                  <TableCell key={tableCustomization.columnKey} className={styles.cell}>
                    {patient.programName}
                  </TableCell>
                );
              case 'latestReadingMeasuredAt':
                return (
                  <TableCell key={tableCustomization.columnKey} width={260} align="center">
                    <div className={styles.latestReadingPatientsWidgets}>
                      {patient.latestReadings.bloodPressure && (
                        <div className={styles.bloodPressureWidjet}>
                          <BloodPressure
                            reading={patient.latestReadings.bloodPressure}
                            rules={patient.notificationRules}
                            timezone={patient.timezone}
                          />
                        </div>
                      )}
                      {patient.latestReadings.weightScale && (
                        <div>
                          <WeightScale
                            reading={patient.latestReadings.weightScale}
                            rules={patient.notificationRules}
                            timezone={patient.timezone}
                          />
                        </div>
                      )}
                      {patient.latestReadings.pulseOximeter && (
                        <div className={styles.pulseOximeterWidjet}>
                          <PulseOximeter
                            reading={patient.latestReadings.pulseOximeter}
                            rules={patient.notificationRules}
                            timezone={patient.timezone}
                          />
                        </div>
                      )}
                      {patient.latestReadings.bloodGlucose && (
                        <div className={styles.bloodGlucoseWidjet}>
                          <BloodGlucose
                            reading={patient.latestReadings.bloodGlucose}
                            rules={patient.notificationRules}
                            timezone={patient.timezone}
                          />
                        </div>
                      )}
                    </div>
                  </TableCell>
                );
              case 'activityMinutesCount':
                return (
                  <TableCell key={tableCustomization.columnKey} align="center">
                    {patient.activityMinutesCount}
                  </TableCell>
                );
              case 'uniqueDaysCount':
                return (
                  <TableCell key={tableCustomization.columnKey} align="center">
                    {patient.uniqueDaysCount}
                  </TableCell>
                );
              case 'totalReadingsCount':
                return (
                  <TableCell key={tableCustomization.columnKey} align="center">
                    {patient.totalReadingsCount}
                  </TableCell>
                );
              case 'isHomeHealthCare':
                return (
                  <TableCell key={tableCustomization.columnKey} align="center">
                    {patient.isHomeHealthCare
                      ? `Yes ${
                          patient.hospitalizationRiskScore === null
                            ? ''
                            : `(HRS ${patient.hospitalizationRiskScore}%)`
                        }`
                      : 'No'}
                  </TableCell>
                );
              case 'status':
                return <TableCell key={tableCustomization.columnKey}>{patient.status}</TableCell>;
              default:
                throw new Error(`Invalid columnKey: ${tableCustomization.columnKey}.`);
            }
          })}
      </TableRow>
      {showInfoPopup && (
        <InfoModal patientId={patient.id} onClose={() => setShowInfoPopup(false)} />
      )}
      {showNotesPopup && (
        <NotesModal patientId={patient.id} onClose={() => setShowNotesPopup(false)} />
      )}
      {showActivatePopup && (
        <ActivatePatient
          patientId={patient.id}
          isProcessing={isActivating}
          onClose={() => setShowActivatePopup(false)}
          onSubmit={handleActivate}
        />
      )}
      {showDeleteModal && (
        <ConfirmModal
          title="Delete"
          description={`Are you sure you want to delete ${patient.fullName}? Please note that all related data will be terminated permanently, including records in Audit Logs.`}
          onClose={() => setShowDeleteModal(false)}
          onConfirm={() => onRemove(patient.id)}
        />
      )}
    </>
  );
};

export default Item;
