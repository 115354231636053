import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkActivatePatient } from '../types';

const onActivatePatient: IThunkActivatePatient = thunk(
  // @ts-ignore
  async ({ activatePatient }, patientId) => {
    await api.patients.activate(patientId);
    activatePatient();
  },
);

export default onActivatePatient;
