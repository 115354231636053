import initStore from './initStore';
import actions from './actions';
import thunks from './thunks';

import { IPatientsModel } from './types';

const patients: IPatientsModel = {
  ...initStore,
  ...actions,
  ...thunks,
};

export default patients;
