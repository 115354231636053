import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { getPaginationPayload } from '~/utils/pagination';
import { IThunkGetMoreMobileUsers, IMobileUserTypesStore } from '../types';

const onGetMoreMobileUsers: IThunkGetMoreMobileUsers = thunk(
  // @ts-ignore
  async ({ setMoreMobileUsers }, payload, { getState }) => {
    const localState = getState() as IMobileUserTypesStore;
    const paginationPayload = getPaginationPayload(localState.itemsPagination);

    const users = await api.mobileUsers
      .getList({
        ...payload,
        ...paginationPayload,
      })
      .then(res => res.data);

    setMoreMobileUsers(users);
  },
);

export default onGetMoreMobileUsers;
