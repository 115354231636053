import { DeviceStatus, DeviceSupplier, DeviceType } from '~/services/api/enums';

export const headCells = [
  {
    id: 'deviceType',
    label: 'Device Type',
    useSort: false,
  },
  {
    id: 'model',
    label: 'Model',
    useSort: false,
  },
  {
    id: 'imei',
    label: 'IMEI',
    useSort: false,
  },
  {
    id: 'status',
    label: 'Status',
    useSort: false,
  },
  {
    id: 'supplier',
    label: 'Supplier',
    useSort: false,
  },
  {
    id: 'programName',
    label: 'Program Name',
    useSort: true,
  },
];

export const statusOptions = [
  { value: '', label: 'All Statuses' },
  { value: DeviceStatus.Available, label: 'Available' },
  { value: DeviceStatus.Ready, label: 'Ready' },
  { value: DeviceStatus.Issued, label: 'Issued' },
  { value: DeviceStatus.Returning, label: 'Returning' },
  { value: DeviceStatus.Returned, label: 'Returned' },
  { value: DeviceStatus.Lost, label: 'Lost' },
];

export const deviceTypeDefinitions = {
  [DeviceType.BloodPressure]: {
    value: DeviceType.BloodPressure,
    label: 'Blood Pressure',
  },
  [DeviceType.WeightScale]: {
    value: DeviceType.WeightScale,
    label: 'Weight Scale',
  },
  [DeviceType.PulseOximeter]: {
    value: DeviceType.PulseOximeter,
    label: 'Pulse Oximeter',
  },
  [DeviceType.BloodGlucose]: {
    value: DeviceType.BloodGlucose,
    label: 'Blood Glucose',
  },
};

export const deviceSupplierDefinitions = {
  [DeviceSupplier.Transtek]: {
    value: DeviceSupplier.Transtek,
    label: 'Transtek',
  },
  [DeviceSupplier.BodyTrace]: {
    value: DeviceSupplier.BodyTrace,
    label: 'Body Trace',
  },
  [DeviceSupplier.SmartMeter]: {
    value: DeviceSupplier.SmartMeter,
    label: 'Smart Meter',
  },
  [DeviceSupplier.Transtek2]: {
    value: DeviceSupplier.Transtek2,
    label: 'Transtek 2',
  },
};

export const deviceTypeOptions = [
  { value: '' as DeviceType, label: 'All Types' },
  deviceTypeDefinitions[DeviceType.BloodPressure],
  deviceTypeDefinitions[DeviceType.WeightScale],
  deviceTypeDefinitions[DeviceType.PulseOximeter],
  deviceTypeDefinitions[DeviceType.BloodGlucose],
];

export const deviceSupplierOptions = [
  { value: '' as DeviceSupplier, label: 'All Suppliers' },
  deviceSupplierDefinitions[DeviceSupplier.Transtek],
  deviceSupplierDefinitions[DeviceSupplier.BodyTrace],
  deviceSupplierDefinitions[DeviceSupplier.SmartMeter],
  deviceSupplierDefinitions[DeviceSupplier.Transtek2],
];

export default {};
