import initStore from './initStore';
import actions from './actions';
import thunks from './thunks';

import { ICarePlanMedicationsModel } from './types';

const carePlanMedications: ICarePlanMedicationsModel = {
  ...initStore,
  ...actions,
  ...thunks,
};

export default carePlanMedications;
