import { Level } from '~/utils/deviceReading/types';

export const levels = {
  [Level.Poor]: {
    title: 'Poor',
    color: '#f44336',
  },
  [Level.Fair]: {
    title: 'Fair',
    color: '#e4bf3a',
  },
  [Level.Good]: {
    title: 'Good',
    color: '#33ad90',
  },
};

export default {};
