import axios from 'axios';
import { IIdName, IResponse } from '~/services/api/types';
import {
  IReadingLogsReportRequest,
  IPatientSummaryReportRequest,
  IBillingReportRequest,
  IGetProgramPatientsParams,
  IReportsSelectors,
  IPayrollSelectors,
  IPayrollReportRequest,
} from './types';

const apiUrl = 'reports';

export const getReadingLogsReport = (data: IReadingLogsReportRequest): IResponse<Blob> =>
  axios({
    method: 'post',
    url: `${apiUrl}/reading-logs`,
    responseType: 'blob',
    data,
  });

export const getPatientSummaryReport = (data: IPatientSummaryReportRequest): IResponse<Blob> =>
  axios({
    method: 'post',
    url: `${apiUrl}/patient-summary`,
    responseType: 'blob',
    data,
  });

export const getBillingReport = (data: IBillingReportRequest): IResponse<Blob> =>
  axios({
    method: 'post',
    url: `${apiUrl}/billing`,
    responseType: 'blob',
    data,
  });

export const getPayrollReport = (data: IPayrollReportRequest): IResponse<Blob> =>
  axios({
    method: 'post',
    url: `${apiUrl}/payroll`,
    responseType: 'blob',
    data,
  });

export const getSelectors = (): IResponse<IReportsSelectors> =>
  axios({
    method: 'get',
    url: `${apiUrl}/selectors`,
  });

export const getProgramPatients = (params: IGetProgramPatientsParams): IResponse<IIdName[]> =>
  axios({
    method: 'get',
    url: `${apiUrl}/patients`,
    params,
  });

export const getPayrollSelectors = (): IResponse<IPayrollSelectors> =>
  axios({
    method: 'get',
    url: `${apiUrl}/selectors/payroll`,
  });
