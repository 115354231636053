import { useState, useEffect, ReactElement } from 'react';

import { Paper } from '@material-ui/core';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error';
import Loader from '~/ui/components/common/Loader';
import Modal from '~/ui/components/common/Modal';
import Table from './Table';

import styles from './ActivePrograms.module.scss';
import { ISort } from '~/utils/sort/types';

interface IProps {
  onClose: () => void;
}

const ActivePrograms = ({ onClose }: IProps): ReactElement => {
  const [isLoading, setIsLoading] = useState(true);
  const [sort, setSort] = useState({} as ISort);

  const { items, pagination } = useStoreState(state => state.programs);

  const showError = useStoreActions(actions => actions.snackbar.showError);
  const { onGetPrograms, onGetMorePrograms } = useStoreActions(actions => actions.programs);

  const payload = {
    ...sort,
  };

  const getPrograms = async () => {
    try {
      await onGetPrograms(payload);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setIsLoading(false);
    }
  };

  const getMorePrograms = async () => {
    try {
      await onGetMorePrograms(payload);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  useEffect(() => {
    getPrograms();
  }, [sort]);

  return (
    <Modal
      open
      maxWidth="md"
      title="Active Programs"
      className={styles.modal}
      onClose={onClose}
      contentIdAttribute="scrollableDiv"
    >
      <Paper className={styles.paper}>
        {isLoading ? (
          <Loader />
        ) : (
          <Table
            sort={sort}
            setSort={setSort}
            programs={items}
            onLoadMore={getMorePrograms}
            pagination={pagination}
          />
        )}
      </Paper>
    </Modal>
  );
};

export default ActivePrograms;
